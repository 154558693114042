import type { IconProps } from '../../types/icon'

const CopyStrength = (props: IconProps) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 20 20"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fill="currentColor"
      stroke="none"
      d="M14.6508 3.14463L15.0939 4.76488C15.1065 4.82817 15.1572 4.87881 15.2205 4.89147L16.8407 5.3345C17.0179 5.38514 17.0179 5.6383 16.8407 5.68893L15.2205 6.13197C15.1572 6.14463 15.1065 6.19526 15.0939 6.25855L14.6508 7.87881C14.6002 8.05602 14.347 8.05602 14.2964 7.87881L13.8534 6.25855C13.8407 6.19526 13.7901 6.14463 13.7268 6.13197L12.1065 5.68893C11.9293 5.6383 11.9293 5.38514 12.1065 5.3345L13.7268 4.89147C13.7901 4.87881 13.8407 4.82817 13.8534 4.76488L14.2964 3.14463C14.347 2.96741 14.6002 2.96741 14.6508 3.14463Z"/>
    <path
      fill="currentColor"
      stroke="none"
      d="M13.5801 14.0916L13.8523 15.062C13.8523 15.0975 13.8878 15.133 13.9233 15.133L14.8938 15.4052C15.0003 15.4289 15.0003 15.5827 14.8938 15.6182L13.9233 15.8904C13.8878 15.8904 13.8523 15.9259 13.8523 15.9614L13.5801 16.9318C13.5565 17.0383 13.4026 17.0383 13.3671 16.9318L13.0949 15.9614C13.0949 15.9259 13.0594 15.8904 13.0239 15.8904L12.0535 15.6182C11.947 15.5946 11.947 15.4407 12.0535 15.4052L13.0239 15.133C13.0594 15.133 13.0949 15.0975 13.0949 15.062L13.3671 14.0916C13.3908 13.9851 13.5446 13.9851 13.5801 14.0916Z"/>
    <path
      fill="currentColor"
      stroke="none"
      d="M8.8572 4.30427L9.84538 7.86692C9.88439 7.99694 9.98841 8.11396 10.1184 8.13997L13.6811 9.12815C14.0712 9.23217 14.0712 9.79127 13.6811 9.89529L10.1184 10.8835C9.98841 10.9225 9.87139 11.0265 9.84538 11.1565L8.8572 14.7192C8.75318 15.1092 8.19408 15.1092 8.09006 14.7192L7.10188 11.1565C7.06288 11.0265 6.95886 10.9095 6.82883 10.8835L3.26619 9.89529C2.87612 9.79127 2.87612 9.23217 3.26619 9.12815L6.82883 8.13997C6.95886 8.10096 7.07588 7.99694 7.10188 7.86692L8.09006 4.30427C8.19408 3.9142 8.75318 3.9142 8.8572 4.30427Z"/>
  </svg>
)

export default CopyStrength
