import { type IconProps } from '../../types/icon'

const FileAttach
  = (props: IconProps) => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={21}
      height={21}
      viewBox="0 0 21 21"
      {...props}
    >
      <path
        fill="none"
        stroke="currentColor"
        strokeWidth="1.2"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M10.2498 18H5.24981C4.14524 17.9999 3.24981 17.1045 3.24982 15.9999L3.24989 3.99999C3.2499 2.89542 4.14533 2 5.24989 2H14.2501C15.3547 2 16.2501 2.89543 16.2501 4V8M6.75013 6H12.7501M6.75013 9H12.7501M12.7501 12.9616V15.4153C12.7501 16.6278 14.043 17.7398 15.2554 17.7398C16.4679 17.7398 17.7501 16.6278 17.7501 15.4153V12.3159C17.7501 11.674 17.3811 11.0226 16.5603 11.0226C15.6821 11.0226 15.2554 11.674 15.2554 12.3159V15.2861M6.75013 12H9.75013"
      />
    </svg>
  )

export default FileAttach

