import { type IconProps } from '../../types/icon'

const CopyResult = (props: IconProps) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 20 20"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fill="currentColor"
      stroke="none"
      d="M10 3C6.136 3 3 6.136 3 10C3 13.864 6.136 17 10 17C13.864 17 17 13.864 17 10C17 6.136 13.864 3 10 3ZM8.103 13.003L5.59 10.49C5.317 10.217 5.317 9.776 5.59 9.503C5.863 9.23 6.304 9.23 6.577 9.503L8.6 11.519L13.416 6.703C13.689 6.43 14.13 6.43 14.403 6.703C14.676 6.976 14.676 7.417 14.403 7.69L9.09 13.003C8.824 13.276 8.376 13.276 8.103 13.003Z" />
  </svg>
)

export default CopyResult
