import type { FC } from 'react'
import { NavLink } from 'react-router'
import { type EvaluationType, OrderByFieldEnum } from '@ed/types'
import { Icon, LinkBox, LinkOverlay } from '@ed/ui'
import { getPath, URLS } from '@/utils/url'
import { formatDate } from '@/utils/evaluation'
import { getStatusWordingKey } from '@/utils/status'
import { useTranslation } from 'react-i18next'

const EvaluationCardItem: FC<{ evaluation: EvaluationType & { copyIds: number[], grade: { name: string } }, orderBy: string }> = ({ evaluation, orderBy }) => {
  const { t } = useTranslation('utils')

  return (
    <LinkBox as='li' className="flex border border-[#e4e4e7] rounded-lg w-96 p-4 group flex-col gap-2">
      <div className='flex items-center justify-center w-11 h-11 bg-[#604AFF24] group-hover:bg-[#604aff4a] rounded-lg transition-colors'>
        <Icon.Evaluation className='text-[#604AFF] group-hover:scale-110 transition-transform flex-shrink-0' />
      </div>
      <div className="gap-1">
        <LinkOverlay
          as={NavLink}
          to={getPath(URLS.EVALUATION, { evaluationId: evaluation.id })}
          className="text-base font-semibold group-hover:text-primary-darker-20 transition-colors"
        >
          {evaluation.title}
        </LinkOverlay>
        <div className="text-xs">
          {formatDate(orderBy === OrderByFieldEnum.UPDATED_AT ? evaluation.updatedAt : evaluation.createdAt)}
          {evaluation.grade && <span className="ml-2 text-sm text-[#604AFF]">{evaluation.grade.name}</span>}
        </div>
        <div className="text-xs text-[#6b7280]">
          {evaluation.tags?.map(t => t.name)
            .filter(text => !!text)
            .join(' • ')
          }
        </div>
      </div>
      <div className='text-xs text-[#a3a3a3]'>
        {t(`status.${getStatusWordingKey(evaluation.status)}`)}
      </div>
      <div className='text-xs text-[#0f172a]'>
        {t('copy', { count: evaluation.copyIds?.length ?? 0 })}
      </div>
    </LinkBox>
  )
}

export default EvaluationCardItem
