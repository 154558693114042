
import config from '@/config/config'
import type { AssetType } from '@ed/types'
import { BadRequestError, CookiesEnum, type CopyType, FRAGMENT_COPY, InternalServerError, } from '@ed/types'
import { Cookies } from 'react-cookie'
import { graphql } from './graphql'

type CreateCopyProps = {
  copy: CopyType
}

type UpdateCopyProps = {
  copy: CopyType
}

type AppendFilesToCopyProps = {
  copyId: CopyType['id'],
  formData: FormData;
}

const QUERY_CREATE_COPY = `mutation ($copy: CopyInput!) {
  client {
    createCopy (copy: $copy) {
      ${FRAGMENT_COPY}
    }
  }
}`

export const createCopy = async ({ copy }: CreateCopyProps): Promise<CopyType> => {
  const data = await graphql({
    query: QUERY_CREATE_COPY,
    variables: {
      copy
    }
  })

  return data.client.createCopy
}

const QUERY_UPDATE_COPY = `mutation ($copy: CopyInput!) {
  client {
    updateCopy (copy: $copy) {
      ${FRAGMENT_COPY}
    }
  }
}`

export const updateCopy = async ({ copy }: UpdateCopyProps): Promise<CopyType> => {
  const data = await graphql({
    query: QUERY_UPDATE_COPY,
    variables: {
      copy
    }
  })

  return data.client.updateCopy
}

export const appendFilesToCopy = async ({ copyId, formData }: AppendFilesToCopyProps): Promise<AssetType[]> => {
  if (!copyId) throw new BadRequestError('Missing copyId')
  const token = new Cookies().get(CookiesEnum.AUTH) ?? ''
  if (!token) throw new BadRequestError('Missing token')
  if (!formData || !formData.has('files')) throw new BadRequestError('No files provided')

  const response = await fetch(`${config.api_url}/copies/${copyId}/assets/?access_token=${token}`, {
    method: 'POST',
    body: formData
  })

  const responseJson = await response.json()
  if (!response.ok) {
    throw new InternalServerError(JSON.stringify(responseJson) ?? 'Failed to append file to copy')
  }

  return responseJson as AssetType[]
}
