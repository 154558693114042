
import config from '@/config/config'
import type {
  AssetType } from '@ed/types'
import {
  BadRequestError,
  CookiesEnum,
  type EvaluationType,
  FRAGMENT_EVALUATION,
  InternalServerError,
} from '@ed/types'
import axios, { type AxiosProgressEvent, type AxiosRequestConfig } from 'axios'
import { Cookies } from 'react-cookie'
import { graphql } from './graphql'

type CreateEvaluationProps = {
  evaluation: EvaluationType
}

type UpdateEvaluationProps = {
  evaluation: EvaluationType
}

type DeleteEvaluationProps = {
  evaluation: EvaluationType
}

type AppendFilesToEvaluationProps = {
  evaluationId: EvaluationType['id']
  formData: FormData
  onUploadProgress?: (progressEvent: AxiosProgressEvent) => void
  signal?: AbortSignal
}

type DeleteEvaluationAssetsProps = {
  evaluation: Pick<EvaluationType, 'id' | 'assetIds'>
}

const QUERY_DELETE_EVALUATION = `mutation ($evaluation: EvaluationInput!) {
  client {
    deleteEvaluation (evaluation: $evaluation) {
      id
    }
  }
}`

export const deleteEvaluation = async ({ evaluation }: DeleteEvaluationProps): Promise<EvaluationType> => {
  const data = await graphql({
    query: QUERY_DELETE_EVALUATION,
    variables: {
      evaluation
    }
  })

  return data.client.deleteEvaluation
}

const QUERY_CREATE_EVALUATION = `mutation ($evaluation: EvaluationInput!) {
  client {
    createEvaluation (evaluation: $evaluation) {
      ${FRAGMENT_EVALUATION}
    }
  }
}`

export const createEvaluation = async ({ evaluation }: CreateEvaluationProps): Promise<EvaluationType> => {
  const data = await graphql({
    query: QUERY_CREATE_EVALUATION,
    variables: {
      evaluation
    }
  })

  return data.client.createEvaluation
}

const QUERY_UPDATE_EVALUATION = `mutation ($evaluation: EvaluationInput!) {
  client {
    updateEvaluation (evaluation: $evaluation) {
      ${FRAGMENT_EVALUATION}
    }
  }
}`

export const updateEvaluation = async ({ evaluation }: UpdateEvaluationProps): Promise<EvaluationType> => {
  const data = await graphql({
    query: QUERY_UPDATE_EVALUATION,
    variables: {
      evaluation
    }
  })

  return data.client.updateEvaluation
}

const QUERY_DELETE_EVALUATION_ASSETS = `mutation ($evaluation: EvaluationRemoveAssetsInput!) {
  client {
    deleteEvaluationAssets (evaluation: $evaluation) {
      ${FRAGMENT_EVALUATION}
    }
  }
}`

export const deleteEvaluationAssets = async ({ evaluation }: DeleteEvaluationAssetsProps): Promise<EvaluationType> => {
  const data = await graphql({
    query: QUERY_DELETE_EVALUATION_ASSETS,
    variables: {
      evaluation
    }
  })

  return data.client.deleteEvaluationAssets
}

export const appendFilesToEvaluation = async ({ evaluationId, formData, onUploadProgress, signal }: AppendFilesToEvaluationProps): Promise<AssetType[]> => {
  const token = new Cookies().get(CookiesEnum.AUTH) ?? ''
  if (!evaluationId) throw new BadRequestError('Missing evaluationId')
  if (!formData || !formData.has('files')) throw new BadRequestError('No files provided')

  const response = await axios.post<AssetType[]>(
    `${config.api_url}/evaluations/${evaluationId}/assets/?access_token=${token}`,
    formData,
    {
      onUploadProgress,
      signal,
    } satisfies AxiosRequestConfig
  )

  const responseJson = response.data
  if (response.status > 299) {
    throw new InternalServerError(`Failed to append file to evaluation ${JSON.stringify(responseJson)}`)
  }

  return responseJson
}
