import { type IconProps } from '../../types/icon'

const Image = (props: IconProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={26}
    height={26}
    viewBox="0 0 26 26"
    {...props}
  >
    <path
      fill="currentColor"
      stroke="none"
      d="M23.1111 2.88889V23.1111H2.88889V2.88889H23.1111ZM23.1111 0H2.88889C1.3 0 0 1.3 0 2.88889V23.1111C0 24.7 1.3 26 2.88889 26H23.1111C24.7 26 26 24.7 26 23.1111V2.88889C26 1.3 24.7 0 23.1111 0ZM16.0911 12.7978L11.7578 18.3878L8.66667 14.6467L4.33333 20.2222H21.6667L16.0911 12.7978Z"/>
  </svg>
)

export default Image
