import { useParams } from 'react-router'
import { useEffect } from 'react'
import { NotFoundError } from '@ed/types'

const NotFound = () => {
  const { '*': path } = useParams()

  useEffect(() => {
    console.log(new NotFoundError(`Not found: ${path}`))
  }, [path])
  return (
    <h1>404 - Not Found - {path}</h1>
  )
}

export default NotFound
