import clsx from 'clsx'
import { useEffect, useRef, useState } from 'react'

interface AccordionProps {
  title: string
  subtitle?: string
  open?: boolean
  children: React.ReactNode
  className?: string
  contentClassName?: string
  onExpandClick: () => void
}

const Accordion = ({
  title,
  subtitle,
  children,
  className,
  contentClassName,
  open,
  onExpandClick
}: AccordionProps) => {
  const [height, setHeight] = useState<number | undefined>(open ? undefined : 0)
  const contentRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    if (open && contentRef.current) {
      setHeight(contentRef.current.scrollHeight)
    } else {
      setHeight(0)
    }
  }, [open])

  return (
    <div className={clsx('border rounded-lg p-4', className)}>
      <button
        type="button"
        className="flex w-full items-center justify-between cursor-pointer text-left"
        onClick={onExpandClick}
        aria-expanded={open}
      >
        <div>
          <span className="block font-semibold text-base">{title}</span>
          {!!subtitle && <span className="block text-sm text-gray-500">{subtitle}</span>}
        </div>
        <span className="ml-auto text-xl font-bold transition-transform duration-300" style={{ transform: open ? 'rotate(0deg)' : 'rotate(90deg)' }}>
          {open ? '-' : '+'}
        </span>
      </button>
      <div
        className={clsx(
          'overflow-hidden transition-all duration-300 ease-in-out',
          open ? 'opacity-100' : 'opacity-0',
        )}
        style={{ height: height ? `${height}px` : '0px' }}
      >
        <div ref={contentRef} className={clsx('pt-4', contentClassName)}>
          {children}
        </div>
      </div>
    </div>
  )
}

export default Accordion
