import clsx from 'clsx'
import type { ReactNode } from 'react'
import { useCallback } from 'react'

/**
 * Définition d'une colonne générique.
 * - `id`: clé unique pour la colonne
 * - `header`: contenu de l'en-tête (peut être un texte ou un composant)
 * - `accessor`: fonction qui, à partir d'un élément de données (une ligne),
 *   renvoie le contenu à afficher dans la cellule.
 */
export type ColumnType<T> = {
  /** unique key for column */
  id: string
  /** content for column header (string|ReactNode) */
  header: ReactNode
  /** function that input one line of row data and return cell content as a ReactNode */
  accessor: (row: T) => ReactNode
}

/**
 * Props du tableau générique.
 * - `columns`: liste de définitions de colonnes
 * - `data`: tableau d'éléments représentant les lignes
 * - `getRowKey`: fonction pour générer une clé unique par ligne (ex: id, index...)
 */
export type GenericTableProps<T> = {
  /** className for table */
  className?: string
  /** list of Column */
  columns: ColumnType<T>[]
  /** table of elements representing the data to display */
  data: T[]
  /** function to get a unique key per line */
  getRowKey: (row: T, index: number) => string | number
  /* Is the table using minimal design (without border and first colum highlight) */
  minimal?: boolean
  /* override default column structure : repeat(${columns.length}, 1fr) */
  columnStructure?: string
  /* callback on row click */
  onRowClick?: (row: T) => void
  /* key of selected row */
  selectedRow?: string | number
  rowClassName?: string
  emptyComponent?: ReactNode
}

const GenericTable = <T,>({
  className,
  columns,
  data,
  getRowKey,
  minimal = true,
  columnStructure,
  onRowClick,
  selectedRow,
  rowClassName,
  emptyComponent
}: GenericTableProps<T>) => {
  const gridTemplateColumns = columnStructure || `repeat(${columns.length}, 1fr)`
  // console.log('gridTemplateColumns', gridTemplateColumns)

  const renderRow = useCallback((row: T, rowIndex: number) => {
    const key = getRowKey(row, rowIndex)
    return (
      <div
        key={key}
        className={clsx('grid border-b last:border-b-0 grid-cols-subgrid items-center col-span-full', key === selectedRow ? 'bg-[#F6F8FB]' : '', rowClassName)}
        onClick={onRowClick ? (() => onRowClick(row)) : undefined}
      >
        {columns.map((col) => (
          <div key={col.id} className="p-2">
            {col.accessor(row)}
          </div>
        ))}
      </div>
    )
  }, [columns, getRowKey, onRowClick, rowClassName, selectedRow])

  return (
    <div
      className={clsx(
        'w-full grid',
        className,
        !minimal && 'bg-white shadow-sm rounded-lg overflow-hidden border border-[#E5E0EB]'
      )}
      style={{ gridTemplateColumns }}>
      <div
        className={clsx(
          'grid border-b py-3 grid-cols-subgrid col-span-full',
          !minimal && 'bg-[#F6F8FB]'
        )}>
        {columns.map((col) => (
          <div key={col.id} className="font-semibold p-2">
            {col.header}
          </div>
        ))}
      </div>

      {data.length === 0 && <div className="col-span-full">{emptyComponent}</div>}
      {data.map(renderRow)}
    </div>
  )
}

export default GenericTable
