import AsideHeader from '@/components/AsideHeader'
import useSelection from '@/hooks/useSelection'
import colRowToArrArr from '@/utils/colRowToArrArr'
import { Button, Icon, Table, type ColumnType } from '@ed/ui'
import { useMemo, type FC, useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import type { EvaluationType, CopyType } from '@ed/types'
import { sum, sortBy } from 'remeda'
import clsx from 'clsx'
import { studentName } from '@/utils/copy'
import XLSX from 'xlsx'

export type ViewEvaluationScoreDetailsData = {
  evaluation: EvaluationType
  copies: Array<CopyType>
}

type QuestionType = {
  id: string
  title: string
  isRootLevel: boolean
  maxScore: number
  grades: Record<string, number | undefined>
}

const EvaluationScoreDetailsView: FC<{ data: ViewEvaluationScoreDetailsData }> = ({ data }) => {
  const { t } = useTranslation(['evaluation-score-details-view', 'shared'])
  const { close } = useSelection()

  const autoGrading = data.evaluation.autoGradingOutput

  const questions = useMemo(() => {
    const result: QuestionType[] = []
    for (const exercise of autoGrading?.questions ?? []) {
      const copyExercises = data.copies.map(c => ({ copyId: c.id!,
        ...c.autoGradingOutput!.questions.find(q => q.number === exercise.number)! }))

      if (exercise.children?.length) {
        const children: QuestionType[] = []
        for (const question of exercise.children) {
          children.push({
            id: `${exercise.number} / ${question.number}`,
            title: question.number,
            isRootLevel: false,
            maxScore: question.score?.max ?? 0,
            grades: Object.fromEntries(copyExercises.map(c => [c.copyId, c.children?.find(q => q.number === question.number)?.score?.value]))
          })
        }
        result.push({
          id: `${exercise.number}`,
          title: exercise.number,
          isRootLevel: true,
          maxScore: children.reduce((acc, c) => acc + c.maxScore, 0),
          grades: Object.fromEntries(copyExercises.map(c => [c.copyId, sum(children.map(q => q.grades[c.copyId] ?? 0))]))
        })
        result.push(...children)
      } else {
        result.push({
          id: exercise.number,
          title: exercise.number,
          isRootLevel: true,
          maxScore: exercise.score?.max ?? 0,
          grades: Object.fromEntries(copyExercises.map(c => [c.copyId, c.score?.value]))
        })
      }
    }
    return result
  }, [autoGrading?.questions, data.copies])

  type RowType = {
    id: string
    title: string
    copy?: CopyType
  }
  const rows: RowType[] = useMemo(() => [
    {
      id: 'bareme',
      title: t('rubric')
    },
    {
      id: 'average',
      title: t('average')
    },
    ...sortBy(data.copies.map(c => ({
      id: `copy-${c.id}`,
      copy: c,
      title: studentName(c, t)
    })), c => c.title)
  ], [data.copies, t])

  const columns: ColumnType<RowType>[] = useMemo(() => [
    {
      id: 'student_name',
      header: <p className='text-sm text-nowrap'>{t('student-name')}</p>,
      accessor: (row) => <p className='font-semibold'>{row.title}</p>
    },
    {
      id: 'total',
      header: <p className='text-sm text-nowrap'>{t('total')}</p>,
      accessor: (row) => {
        if (row.copy) {
          const score = row.copy.autoGradingOutput?.global.score?.value
          if (score == null) return undefined
          return t('shared:numeric-score', { score })
        }
        if (row.id === 'bareme') {
          const score = autoGrading?.global.score?.max
          if (score == null) return undefined
          return t('shared:numeric-score', { score })
        }
        if (row.id === 'average') {
          const copyGrades = Object.values(data.copies).map(c => c.autoGradingOutput?.global.score?.value).filter(c => c !== undefined)
          if (copyGrades.length == 0) return undefined
          return t('shared:numeric-score', { score: sum(copyGrades) / copyGrades.length })
        }
        return undefined
      }
    },
    ...questions.map(q => ({
      id: `question-${q.id}`,
      header: <p className={clsx('text-nowrap', q.isRootLevel ? 'font-semibold' : 'font-normal')}>{q.title}</p>,
      accessor: (row) => {
        if (row.copy) {
          const score = q.grades[row.copy.id!]
          if (score == null) return undefined
          return <p className={q.isRootLevel ? 'font-semibold' : 'font-normal'}>{t('shared:numeric-score', { score })}</p>
        }
        if (row.id === 'bareme') {
          const score = q.maxScore
          if (score == null) return undefined
          return <p className={q.isRootLevel ? 'font-semibold' : 'font-normal'}>{t('shared:numeric-score', { score })}</p>
        }
        if (row.id === 'average') {
          const copyGrades = Object.values(q.grades).filter(c => c !== undefined)
          if (copyGrades.length == 0) return undefined
          const score = sum(copyGrades) / copyGrades.length
          return <p className={q.isRootLevel ? 'font-semibold' : 'font-normal'}>{t('shared:numeric-score', { score })}</p>
        }
      }
    } satisfies ColumnType<RowType>))
  ], [autoGrading, data.copies, questions, t])

  const xlsExport = useCallback(() => {
    const arrArr = colRowToArrArr(columns, rows)
    console.log(arrArr)
    const worksheet = XLSX.utils.aoa_to_sheet(arrArr)
    const workbook = XLSX.utils.book_new()
    XLSX.utils.book_append_sheet(workbook, worksheet, t('title'))
    XLSX.writeFile(workbook, `${data.evaluation.title}.xlsx`)
  }, [columns, data.evaluation.title, rows, t])

  return (
    <>
      <AsideHeader title={t('title')} onClose={close}>
        <Button onClick={xlsExport} leftIcon={<Icon.Download />} className="text-sm mt-3 bg-[#604AFF]">{t('export-to-excel')}</Button>
      </AsideHeader>
      <div className='overflow-x-auto m-4'>
        <Table
          className='w-max text-sm'
          columns={columns}
          data={rows}
          getRowKey={(row) => row.id!}
          minimal={false}
          columnStructure={`auto repeat(${columns.length - 1}, 1fr)`}
        />
      </div>
    </>
  )
}

export default EvaluationScoreDetailsView