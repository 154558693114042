import { type IconProps } from '../../types/icon'

const Download = (props: IconProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={20}
    height={20}
    viewBox="0 0 20 20"
    {...props}
  >
    <path
      stroke="currentColor"
      fill="none"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
      d="M3.333 12.67v3.074c0 .465.176.912.488 1.242.313.329.737.514 1.179.514h10c.442 0 .866-.185 1.178-.514.313-.33.488-.777.488-1.242V12.67M10.001 2.5v9.952m0 0 3.81-3.803M10 12.452 6.19 8.65"
    />
  </svg>
)

export default Download
