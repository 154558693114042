import type { FC, FormEvent } from 'react'
import { type MouseEvent, useEffect, useState } from 'react'
import clsx from 'clsx'
import { Button, Icon, IconButton, Input, LabelWrapper, RadioGroup, Select } from '@ed/ui'
import { useNavigate } from 'react-router'
import { generatePdfUrl } from '@/utils/userContent'
import type { UserContentType } from '@ed/types'

const EVALUATION_TYPES = [
  { id: 'diagnostique', label: 'Diagnostique', name: 'evaluation-type' },
  { id: 'sommative', label: 'Sommative', name: 'evaluation-type' },
  { id: 'formative', label: 'Formative', name: 'evaluation-type' },
  { id: 'rituel', label: 'Rituel', name: 'evaluation-type' }
]

const MIN_EXERCISE_COUNT = 1
const MAX_EXERCISE_COUNT = 5
const DEFAULT_EXERCISE_COUNT = 2

const Submit = ({ isSubmitting }: { isSubmitting: boolean }) => {
  return (
    <Button
      className="text-sm [&]:bg-dark-primary [&]:border-dark-primary [&]:rounded-[32px] self-end"
      leftIcon={<Icon.Refresh/>}
      type="submit"
      disabled={isSubmitting}
    >
      {isSubmitting ? 'Génération en cours ...' : 'Générer le polycopié'}
    </Button>
  )
}

const ExerciseNavbar: FC<{ reload: () => void, loading: boolean, content: UserContentType }> = ({ reload, loading, content }) => {
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false)
  const navigate = useNavigate()

  const onClickOverlay = (e: MouseEvent<HTMLDivElement>) => {
    if (e.currentTarget === e.target) setIsModalOpen(false)
  }

  useEffect(() => {
    if (!loading) {
      setIsModalOpen(false)
    }
  }, [loading])

  const onSubmit = (e: FormEvent) => {
    e.preventDefault()
    reload()
    return false
  }

  console.log({ content })
  return (
    <nav className="flex justify-between py-4 px-8 bg-white shadow-md mb-7 print:hidden">
      <Button
        theme="tertiary"
        leftIcon={<Icon.ArrowLeft/>}
        className="border-transparent text-sm"
        onClick={() => navigate('/')}
      >
        Accueil
      </Button>

      <div className="inline-flex items-center relative max-w-full w-[530px]">
        <input
          className="h-14 w-full py-5 px-6 placeholder:text-[#625E5B] rounded-[32px] border-[#604AFF33] border-solid border-2 outline-primary"
          placeholder="Thème"
        />
        <IconButton className="absolute right-1 w-12 h-12 bg-dark-primary" onClick={() => setIsModalOpen(true)}>
          <Icon.Settings/>
        </IconButton>
      </div>

      <div className="flex gap-4 items-center">
        <IconButton theme="tertiary" className="border-transparent"><Icon.Refresh/></IconButton>
        <IconButton theme="tertiary" className="border-transparent"><Icon.Dice/></IconButton>
        <IconButton theme="tertiary" className="border-transparent"><Icon.Heart/></IconButton>
        <Button
          className="rounded-[32px] text-sm font-semibold"
          theme="tertiary"
          leftIcon={<Icon.Download/>}
          onClick={() => window.open(generatePdfUrl(content), '_blank')}
        >
          Télécharger
        </Button>
      </div>

      <div className={clsx('relative z-10', !isModalOpen && 'hidden')} role="dialog" aria-modal="true">
        <div className="fixed inset-0 bg-[#29184680]" aria-hidden="true"/>

        <div
          className="flex items-center justify-center fixed inset-0 z-10 w-screen overflow-y-auto"
          onClick={onClickOverlay}
        >
          <form className="flex flex-col gap-5 relative rounded-2xl bg-white shadow-xl p-6 pb-8" onSubmit={onSubmit}>
            <div className="flex flex-col gap-6 bg-[#FCFCFC] border border-[#E7E4E4] rounded-2xl px-4 py-6">
              <div className="text-base">Parametres generaux</div>

              <div className="flex gap-6">
                <LabelWrapper label="Niveau" className="flex-2">
                  <Select name="level" className="sm:w-[150px]">
                    <option value="terminale">Terminale</option>
                  </Select>
                </LabelWrapper>

                <LabelWrapper label="Chapitre" className="flex-1">
                  <Select name="chapter">
                    <option value="les-nombres-décimaux">Les nombres décimaux</option>
                  </Select>
                </LabelWrapper>
              </div>

              <div className="flex gap-6">
                <LabelWrapper label="Nombre d’exercices" className="flex-2">
                  <Input
                    type="number"
                    min={MIN_EXERCISE_COUNT}
                    max={MAX_EXERCISE_COUNT}
                    defaultValue={DEFAULT_EXERCISE_COUNT}
                    className="sm:w-[150px]"
                  />
                </LabelWrapper>

                <LabelWrapper label="Théme" className="flex-1">
                  <Input placeholder="Transports en commun"/>
                </LabelWrapper>
              </div>

              <LabelWrapper label="Type d’évaluation">
                <RadioGroup inputs={EVALUATION_TYPES}/>
              </LabelWrapper>
            </div>

            <Submit isSubmitting={loading}/>
          </form>
        </div>
      </div>
    </nav>
  )
}

export default ExerciseNavbar
