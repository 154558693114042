import { type IconProps } from '../../types/icon'

const DocumentCreated = (props: IconProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="98" height="106" viewBox="0 0 98 106"
    {...props}
  >
    <path d="M94.9757 25.9605L45.0098 14.0109L28.3483 83.6788L78.3142 95.6285L94.9757 25.9605Z" fill="#604AFF"/>
    <g opacity="0.9">
      <path d="M81.9503 35.3584L52.0918 28.2175L51.6384 30.1131L81.4969 37.254L81.9503 35.3584Z" fill="#291846"/>
      <path d="M82.6116 47.0469L46.5898 38.4321L46.3632 39.3799L82.3849 47.9948L82.6116 47.0469Z" fill="#291846"/>
      <path d="M75.1898 48.7737L45.8027 41.7456L45.5761 42.6934L74.9631 49.7215L75.1898 48.7737Z" fill="#291846"/>
      <path d="M81.0276 53.6844L45.0059 45.0696L44.7792 46.0174L80.8009 54.6322L81.0276 53.6844Z" fill="#291846"/>
      <path d="M66.9653 53.8193L44.2129 48.3779L43.9862 49.3257L66.7387 54.7671L66.9653 53.8193Z" fill="#291846"/>
      <path d="M79.4417 60.3143L43.4199 51.6995L43.1932 52.6473L79.215 61.2621L79.4417 60.3143Z" fill="#291846"/>
      <path d="M77.8577 66.9493L41.8359 58.3345L41.6093 59.2823L77.631 67.8971L77.8577 66.9493Z" fill="#291846"/>
      <path d="M70.4262 68.6758L41.0391 61.6477L40.8124 62.5955L70.1995 69.6236L70.4262 68.6758Z" fill="#291846"/>
      <path d="M76.2639 73.5816L40.2422 64.9668L40.0155 65.9146L76.0373 74.5294L76.2639 73.5816Z" fill="#291846"/>
      <path d="M62.2056 73.7292L39.4531 68.2878L39.2265 69.2357L61.9789 74.677L62.2056 73.7292Z" fill="#291846"/>
      <path d="M74.68 80.2192L38.6582 71.6044L38.4315 72.5522L74.4533 81.167L74.68 80.2192Z" fill="#291846"/>
    </g>
    <path d="M51.177 8.38292L52.3294 7.83508L71.4599 75.5863L22.5797 89.3522L5.61914 20.447L51.177 8.38292Z"
      fill="#291846"/>
    <path d="M51.9329 4.70291L2.48438 18.6395L21.9163 87.5861L71.3649 73.6495L51.9329 4.70291Z" fill="#B7AEFB"/>
    <path d="M45.5918 18.7653L15.5742 27.2255L16.1113 29.1312L46.1289 20.671L45.5918 18.7653Z" fill="#3C2F81"/>
    <path d="M51.9754 28.8003L15.7617 39.0068L16.0303 39.9597L52.244 29.7532L51.9754 28.8003Z" fill="#3C2F81"/>
    <path d="M46.2371 34.0121L16.6934 42.3387L16.9619 43.2916L46.5056 34.965L46.2371 34.0121Z" fill="#3C2F81"/>
    <path d="M53.8465 35.4742L17.6328 45.6807L17.9014 46.6335L54.1151 36.427L53.8465 35.4742Z" fill="#3C2F81"/>
    <path d="M41.4537 42.5621L18.5801 49.0088L18.8486 49.9616L41.7223 43.5149L41.4537 42.5621Z" fill="#3C2F81"/>
    <path d="M55.7293 42.1442L19.5156 52.3507L19.7842 53.3036L55.9979 43.0971L55.7293 42.1442Z" fill="#3C2F81"/>
    <path d="M57.6102 48.8112L21.3965 59.0177L21.665 59.9706L57.8787 49.7641L57.6102 48.8112Z" fill="#3C2F81"/>
    <path d="M51.8835 54.0263L22.3398 62.3529L22.6084 63.3058L52.1521 54.9791L51.8835 54.0263Z" fill="#3C2F81"/>
    <path d="M59.4871 55.4779L23.2734 65.6844L23.542 66.6373L59.7557 56.4308L59.4871 55.4779Z" fill="#3C2F81"/>
    <path d="M47.0866 62.5762L24.2129 69.0229L24.4814 69.9758L47.3551 63.5291L47.0866 62.5762Z" fill="#3C2F81"/>
    <path d="M61.3719 62.1518L25.1582 72.3583L25.4268 73.3111L61.6405 63.1046L61.3719 62.1518Z" fill="#3C2F81"/>
    <path
      d="M74.1739 105.82C86.8268 105.82 97.0841 95.5632 97.0841 82.9102C97.0841 70.2572 86.8268 60 74.1739 60C61.5209 60 51.2637 70.2572 51.2637 82.9102C51.2637 95.5632 61.5209 105.82 74.1739 105.82Z"
      fill="#8778F3"/>
    <path
      d="M66.1699 85.2997C67.6605 86.3837 70.6418 89.365 71.8614 91.3976C73.352 88.1454 77.1463 80.8278 81.2116 77.5756"
      stroke="#291846" strokeWidth="4" strokeLinecap="round" strokeLinejoin="round"/>
    <path
      d="M57.2622 98.5001C53.2637 76.5 76.2637 61.5 93.2642 70C89.2642 64.0006 76.2718 54.8034 61.2677 64.0004C46.2637 73.1974 51.2641 93.5001 57.2622 98.5001Z"
      fill="white" fillOpacity="0.1"/>
  </svg>
)

export default DocumentCreated
