import { type IconProps } from '../../types/icon'

const Exercise = (props: IconProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={25}
    viewBox="0 0 24 25"
    {...props}
  >
    <path
      fill="none"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
      d="M9.557 22.414h-4.8a2.4 2.4 0 0 1-2.4-2.4v-14.4a2.4 2.4 0 0 1 2.4-2.4h10.8a2.4 2.4 0 0 1 2.4 2.4v4.8m-11.4-2.4h7.2m-7.2 3.6h7.2m-7.2 3.6h3.6m3 3.89 5.092-5.09 3.394 3.394-5.091 5.091h-3.394v-3.394Z"
    />
  </svg>
)

export default Exercise
