import type { ColumnType } from '@ed/ui'
import { isValidElement, type ReactNode } from 'react'

const extractString = (obj: ReactNode | ReactNode[]): string | undefined => {
  if (typeof obj === 'string') return obj
  if (isValidElement(obj)) return extractString(obj.props.children)
  if (Array.isArray(obj)) return obj.map(extractString).join(' ')
  return obj?.toString()
}

const colRowToArrArr = <RowType extends Record<string, unknown>>(cols: ColumnType<RowType>[], rows: RowType[]) => [
  cols.map(c => extractString(c.header)),
  ...rows.map(row => cols.map(col => extractString(col.accessor(row))))]

export default colRowToArrArr