import AsideHeader from '@/components/AsideHeader'
import useSelection from '@/hooks/useSelection'
import { getPath, URLS } from '@/utils/url'
import { Icon } from '@ed/ui'
import type { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router'

export type CopyListData = Array<{ studentName: string, copyId: number, occurred?: number }>

const EvaluationsStatsCopyList: FC<{ data: CopyListData }> = ({ data }) => {
  const { t } = useTranslation('evaluation-stats-copy-list')
  const { close } = useSelection()
  const navigate = useNavigate()

  return (<>
    <AsideHeader title={t('title')} onClose={close}></AsideHeader>
    <div className="flex flex-col p-6">
      {data.map(copy => (
        <div
          onClick={() => navigate(getPath(URLS.COPY, { copyId: copy.copyId }))}
          className="border border-[#E5E0EB] rounded-lg flex items-center p-4 mb-2 group hover:border-[#c2b6d0] cursor-pointer transition duration-200">
          <div className="text-sm text-[#291846] font-medium flex-1">{copy.studentName}</div>
          {copy.occurred != null && <div
            className="bg-[#604AFF] bg-opacity-5 text-xs text-[#604AFF] rounded-2xl pl-4 pr-4 pt-1 pb-1 pl- flex-none group-hover:text-white group-hover:bg-[#604AFF]">{copy.occurred} occurrences</div>}
          <Icon.ArrowRight width={15} className="flex-one ml-4 text-[#6D717F]"/>
        </div>
      ))}
    </div>
  </>
  )
}

export default EvaluationsStatsCopyList