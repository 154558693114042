import type { CopyType } from '@ed/types'
import { StatusEnum } from '@ed/types'
import type { Namespace, TFunction } from 'i18next'

export const sortCopies = (copies: CopyType[] | undefined): CopyType[] => {
  if (!copies) return []
  const statusOrder = [
    StatusEnum.PROCESSED,
    StatusEnum.PROCESSING,
    StatusEnum.WAITING,
    StatusEnum.DRAFT,
    StatusEnum.ERROR,
    StatusEnum.ERROR_OCR
  ]

  return copies.sort((a, b) => {
    // Comparer les statuts en utilisant leur index dans `statusOrder`
    const statusComparison = statusOrder.indexOf(a.status || 0) - statusOrder.indexOf(b.status || 0)
    if (statusComparison !== 0) {
      return statusComparison
    }

    // Si les statuts sont identiques, comparer les dates `updatedAt` pour trier du plus récent au plus ancien
    return new Date(b.updatedAt || '').getTime() - new Date(a.updatedAt || '').getTime()
  })
}

export const studentName = <Ns extends Namespace>(copy: CopyType, t: TFunction<Ns, undefined>): string =>
  (copy.studentName === 'Copie anonyme' || !copy.studentName) ?
    // @ts-expect-error
    t('shared:anonymous-copy', { id: copy.id }) as string :
    copy.studentName
