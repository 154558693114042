import { createContext, type MutableRefObject, useContext } from 'react'

export const EvaluationIdContext = createContext<MutableRefObject<number | undefined>>({ current: undefined })

const useEvaluationId = () => {
  const evaluationId = useContext(EvaluationIdContext)
  if (evaluationId === undefined) {
    throw new Error('EvaluationId is not set')
  }
  return evaluationId
}

export default useEvaluationId
