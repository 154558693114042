
import config from '@/config/config'
import { CookiesEnum, InternalServerError } from '@ed/types'
import { Cookies } from 'react-cookie'

interface GraphqlParamsType {
  query: string
  variables?: {
    [key: string]: unknown
  }
}
// @deprecated
export const graphql = async ({ query, variables = {} }: GraphqlParamsType) => {
  const token = new Cookies().get<string>(CookiesEnum.AUTH) ?? ''

  console.log({
    query,
    variables,
    token,
  })
  const options: RequestInit = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`
    },
    body: JSON.stringify({
      query,
      variables
    }),
  }

  try {
    const response = await fetch(`${config.api_url}/graphql`, options)

    // Vérifier si la réponse HTTP est OK (status code 200-299)
    if (!response.ok) {
      throw new InternalServerError(`HTTP error! status: ${response.status}`)
    }

    const responseBody = await response.json()

    // Vérifier les erreurs de l'API GraphQL
    if (responseBody.errors) {
      throw new InternalServerError(`GraphQL error: ${JSON.stringify(responseBody.errors)}`)
    }

    return responseBody.data
  } catch (error) {
    // @ts-ignore
    console.error('Error fetching GraphQL data:', (error as Error).message)
  }
}
