import { type IconProps } from '../../types/icon'

const FileChart
  = (props: IconProps) => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={20}
      height={20}
      viewBox="0 0 20 20"
      {...props}
    >
      <path
        fill="none"
        d="M12.5002 2V5C12.5002 5.55228 12.9479 6 13.5002 6H16.5002M7.00019 15V11M10.0002 15L10.0002 7M13.0002 15V11M15.0002 3.5C14.5551 3.10178 14.0933 2.62948 13.8017 2.32273C13.6077 2.11861 13.3397 2 13.058 2H5.49996C4.39539 2 3.49996 2.89542 3.49996 3.99999L3.49988 15.9999C3.49987 17.1045 4.3953 18 5.49987 18L14.4999 18C15.6045 18 16.4999 17.1046 16.4999 16L16.5002 5.39819C16.5002 5.14249 16.4026 4.8967 16.2252 4.71261C15.897 4.3722 15.349 3.8121 15.0002 3.5Z"
        stroke="currentColor"
        strokeWidth="1.2"
        strokeLinecap="round"
        strokeLinejoin="round"/>
    </svg>
  )

export default FileChart

