import type { ExistingFile, UploadProgress } from '@/hooks/useAssetUpload'
import useAssetUpload from '@/hooks/useAssetUpload'
import useEvaluationId from '@/hooks/useEvaluationId'
import { useModal } from '@/hooks/useModal'
import type { TypedDocumentNode } from '@apollo/client'
import { gql, useMutation, useQuery } from '@apollo/client'
import type { AssetType, EvaluationType, GradeType, SubjectType, UserType } from '@ed/types'
import { StatusEnum } from '@ed/types'
import { Button, Icon, Input, LabelWrapper, Select, TagSelect } from '@ed/ui'
import clsx from 'clsx'
import { isEmpty, isNil } from 'lodash/fp'
import type { FC } from 'react'
import { type ChangeEvent, useCallback, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'

import type { MultiValue } from 'react-select'
import { range, sortBy } from 'remeda'
import AssetImage from '@/components/AssetImage'

type ThemeOption = { value: string; label: string }

const gqlQuery: TypedDocumentNode<{
  client: {
    me: UserType,
    grades: { hits: Array<GradeType> },
    subjects: { hits: Array<SubjectType> },
    evaluations: { hits: Array<EvaluationType & { assets: Array<AssetType> }>, total: number }
  }
}> = gql`
  query getEvaluationGradesAndSubjects($id:Int!) {
    client {
      me {
        id
        subjectIds
      }
      grades {
        hits {
          id
          name
        }
      }
      subjects {
        hits {
          id
          name
        }
      }
      evaluations (ids: [$id]) {
        hits {
          id
          status
          title
          subjectId
          gradeId
          tags {
            id
            name
          }
          assets {
            id
            pageCount
            filename
            mimeType
          }
        }
        total
      }
    }
  }
`

const gqlTags: TypedDocumentNode<{ client: { tags: { hits: Array<{ id: number, name: string }> } } }> = gql`
  query getTags($subjectIds:[Int]!, $gradeIds: [Int]!) {
    client {
      tags (subjectIds: $subjectIds, gradeIds: $gradeIds) {
        hits {
          id
          name
        }
      }
    }
  }
`

const gqlUpdateEvaluation = gql`
  mutation updateEvaluation($evaluation: EvaluationInput!) {
    client {
      updateEvaluation (evaluation: $evaluation) {
        id
        status
        title
        tags {
          id
          name
        }
      }
    }
  }
`

const EditEvaluation: FC<{ next: () => void, onQuit: () => void }> = ({ next, onQuit }) => {
  const { t, i18n } = useTranslation('evaluation-edit')

  const [hasChecked, setHasChecked] = useState(false)
  const [selectedFile, setSelectedFile] = useState<UploadProgress | ExistingFile>()
  const { showModal } = useModal()

  const evaluationId = useEvaluationId().current

  const { data } = useQuery(gqlQuery, { variables: { id: evaluationId }, skip: evaluationId == null })
  const evaluation = data?.client?.evaluations?.hits?.[0]
  const grades = useMemo(
    () =>
      data?.client.grades.hits == null ? [] :
        sortBy(data?.client.grades.hits, g => g.id ?? 0),
    [data]
  )
  const subjects = useMemo(
    () =>
      data?.client.subjects.hits == null ? [] :
        sortBy(data?.client.subjects.hits, s => s.name ?? 0),
    [data]
  )
  const subjectIds = data?.client.me.subjectIds

  const [gradeId, setGradeId] = useState<number>()
  const [subjectId, setSubjectId] = useState<number>()
  const [title, setTitle] = useState<string>()
  const [selectedTags, setSelectedTags] = useState<MultiValue<ThemeOption>>([])

  const { data: tagData } = useQuery(gqlTags, {
    variables: { gradeIds: [gradeId], subjectIds: [subjectId] },
    skip: isNil(gradeId) || isNil(subjectId)
  })
  const allTags = useMemo(() => tagData?.client?.tags.hits ?? [], [tagData])

  const [updateEvaluation, { loading, error }] = useMutation(gqlUpdateEvaluation, {
    onCompleted: next
  })
  const { addFiles, removeFile, files, initFiles } = useAssetUpload()

  const onCreate = () => updateEvaluation({
    variables: {
      evaluation: {
        id: evaluationId,
        status: StatusEnum.WAITING,
        title,
        gradeId,
        subjectId,
        tagIds: selectedTags.map(t => parseInt(t.value, 10))
      } satisfies EvaluationType
    }
  })

  const onFilesChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target.size === 0) {
      return
    }

    addFiles(...Array.from(event.target.files || []))

    // Reset the input value so the same file can be selected again
    event.target.value = ''
  }

  useEffect(() => {
    if (isEmpty(files)) return

    setSelectedFile(prev => prev ?? files?.at(0))
  }, [files])

  useEffect(() => {
    if (!evaluation) return

    setTitle(prev => prev ?? evaluation.title ?? '')
    setGradeId(prev => prev ?? evaluation?.gradeId ?? 0)
    setSubjectId(prev => prev ?? evaluation?.subjectId ?? subjectIds?.[0] ?? 0)
    initFiles(evaluation.assets ?? [])
  }, [evaluation, initFiles, subjectIds])

  useEffect(() => {
    if (!evaluation) return

    // do not merge with the previous effect, since allThemes is loaded separately, to avoid reinit of the evaluation
    const tagIds = evaluation.tags?.map(t => t.id)
    const tags = allTags?.filter(t => tagIds?.includes(t.id)).map(t => ({ value: String(t.id), label: t.name })) ?? []
    setSelectedTags(tags)
  }, [allTags, evaluation])

  const editionType = evaluation?.status === StatusEnum.DRAFT ? 'creation' : 'edition'
  const disabled = useMemo(
    () => isNil(gradeId) || isEmpty(title) || (!hasChecked && editionType === 'creation') || isEmpty(selectedTags),
    [title, gradeId, hasChecked, selectedTags, editionType])

  const onSelectThemes = useCallback((data: MultiValue<ThemeOption>) => {
    setSelectedTags(data)
  }, [])

  const onClose = async () => {
    showModal({
      title: t(`quit.${editionType}.dialog-title`),
      message: t(`quit.${editionType}.dialog-message`),
      positive: { onClick: onQuit },
    })
  }

  return (
    <div className="w-full h-screen bg-[#1C1B28]">
      <div className='h-12 text-white text-sm text-right font-["Inter"] flex items-center justify-end pr-8'>
        <button onClick={onClose}>
          {t(`quit.${editionType}.button`)}
          <Icon.Close className="inline-block size-6 ml-4"/>
        </button>
      </div>

      <div className="flex w-full pl-2 bg-[#262336] justify-start items-start rounded-2xl ">

        {/* Left part (assets) */}
        <div
          className="flex flex-0 justify-center h-[calc(100vh-50px)] overflow-y-auto pb-2 rounded-tl-2xl rounded-bl-2xl w-[185px] ">
          <div
            className="pt-6 bg-[#262336] rounded-bl-2xl shadow-[0px_4px_8px_0px_rgba(0,0,0,0.20)] border-r-2 border-[#8F80FF33] flex-col justify-start items-start inline-flex">
            <div className="grow shrink basis-0 flex-col justify-start items-center gap-2 flex">
              {files?.map(asset =>
                (
                  <div
                    key={asset.localId}
                    className="self-stretch px-2 flex-col justify-center items-center gap-1 flex"
                  >
                    <div className="px-2 flex-col justify-center items-center flex">
                      <div
                        className={clsx('relative p-2 rounded transition-all duration-200 hover:bg-[#8F80FF33] group', asset.localId == selectedFile?.localId ? 'is-selected' : '')}>
                        <div
                          className="absolute top-[16px] right-[16px] bg-[#291846] p-2 rounded-lg opacity-0 scale-90 transition-all duration-200 group-hover:opacity-100 group-hover:scale-100 cursor-pointer"
                          onClick={() => removeFile(asset)}>
                          <Icon.Trash
                            className="text-white size-5 hover:scale-110 hover:m-1 transition-all duration-200"/>
                        </div>

                        <AssetImage
                          className="cursor-pointer rounded transition-all duration-200 group-hover:border group-hover:border-[#9789FE] group-[.is-selected]:border-2 group-[.is-selected]:border-[#9789FE]"
                          base={`evaluations/${evaluation?.id}`}
                          assetId={asset.assetId ?? ''}
                          alt={t('asset-alt', { fileName: asset.name })}
                          pageNumber={0}
                          onClick={() => setSelectedFile(asset)}
                        />
                      </div>
                      <div
                        className="justify-start text-white text-xs font-medium font-['Inter'] leading-tight truncate w-36">{asset.name}</div>
                      {'pageCount' in asset && <div
                        className="text-white/70 text-xs font-normal font-['Inter'] leading-tight w-36">{t('page', { count: asset.pageCount })}</div>}
                    </div>
                  </div>
                ))
              }
            </div>
            <label htmlFor="FileUpload"
              className="relative flex flex-col justify-center items-center gap-1 p-2 rounded-lg bg-white/5 m-auto cursor-pointer transition-all duration-200 hover:scale-110 mt-8 mb-8">
              <Icon.Plus className="text-white size-5"/>
              <div className="text-center text-white text-xs font-medium font-['Inter'] leading-snug">
                {t('add-document')}
                <input
                  id="FileUpload"
                  type="file"
                  required={true}
                  accept="image/*,.pdf"
                  className="absolute inset-0 z-50 m-0 h-full w-full p-0 opacity-0 outline-none"
                  name="files"
                  multiple={true}
                  onChange={onFilesChange}
                />
              </div>
            </label>
          </div>
        </div>

        {/* Center part (image) */}
        <div className="flex flex-1 justify-center h-[calc(100vh-50px)] overflow-y-auto px-4">
          <div className="flex flex-col gap-4 bg-[#262336] mb-10">
            {!!selectedFile && 'pageCount' in selectedFile && range(0, selectedFile.pageCount).map(pageNumber => (
              <AssetImage
                key={`${selectedFile?.assetId}-${pageNumber}`}
                base={`evaluations/${evaluationId}`}
                assetId={selectedFile?.assetId || ''}
                alt={t('page-alt', { pageNumber: pageNumber + 1, fileName: selectedFile.name })}
                pageNumber={pageNumber}
                className="w-full max-w-2xl border border-gray-300"
              />
            ))}
          </div>
        </div>

        {/* Right part (form) */}
        <div className="w-96 h-[calc(100vh-50px)] bg-[#262336] flex rounded-2xl">
          <div className="w-full px-12 pt-12 pb-8 bg-white rounded-2xl flex flex-col overflow-y-auto">
            <div className="text-indigo-950 text-2xl font-semibold">{t('settings')}</div>
            <div className="text-gray-500 text-sm font-normal">{t('required-fields')}</div>
            <div className="mt-4 mb-4">
              <LabelWrapper label={t('title')}>
                <Input
                  type="text"
                  name="title"
                  required={true}
                  className="w-full"
                  value={title}
                  onChange={(e) => setTitle(e.currentTarget.value)}
                />
              </LabelWrapper>
            </div>

            <div className="mt-4 mb-4">
              <LabelWrapper label={t('grade')}>
                <Select
                  name="grade"
                  required={true}
                  value={gradeId}
                  onChange={(e) => setGradeId(parseInt(e.currentTarget.value || '0', 10))}
                >
                  <option value="0" disabled></option>
                  {grades?.map(grade => <option key={grade.id} value={grade.id}>{grade.name}</option>)}
                </Select>
              </LabelWrapper>
            </div>

            {(subjectIds?.length ?? 0) > 1 && (<div className="mt-4 mb-4">
              <LabelWrapper label={t('subject')}>
                <Select
                  name="subject"
                  required={true}
                  value={subjectId}
                  onChange={(e) => setSubjectId(parseInt(e.currentTarget.value || '0', 10))}
                >
                  <option value="0" disabled></option>
                  {subjects?.map(subject => <option key={subject.id} value={subject.id}>{subject.name}</option>)}
                </Select>
              </LabelWrapper>
            </div>)}

            <div className="mt-4 mb-4">
              <LabelWrapper label={t('themes')}>
                <TagSelect disabled={allTags?.length === 0} tags={allTags} value={selectedTags}
                  onSelectTags={onSelectThemes} locales={i18n.language}/>
              </LabelWrapper>
            </div>

            <div className="flex flex-grow"/>

            {editionType === 'creation' && (<label className="mb-4">
              <input type="checkbox" checked={hasChecked}
                onChange={(e) => setHasChecked(e.currentTarget.checked)}/><span
                className="text-indigo-950 text-sm font-normal font-['Inter'] pl-2">{t('required-assets')}</span>
            </label>)}

            {!!error && <p className="text-error">{error.message}</p>}
            <Button disabled={disabled} pending={loading} onClick={onCreate}
              className="font-semibold text-sm">{t(`quit.${editionType}.submit`)}
            </Button>
          </div>
        </div>
      </div>
    </div>)
}

export default EditEvaluation
