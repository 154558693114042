import { type IconProps } from '../../types/icon'

const ArrowLeft = (props: IconProps) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    stroke='currentColor'
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path d="M10.6664 19L3.99976 12M3.99976 12L10.6664 5M3.99976 12L19.9998 12" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
)

export default ArrowLeft

