import { type IconProps } from '../../types/icon'

const Evaluation = (props: IconProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <path
      fill="none"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="m3.584 21.6 7.364-12.887m2.63 0L20.418 21.6M4.363 14.762c1.928 2.903 4.753 4.734 7.9 4.734 3.148 0 5.973-1.831 7.901-4.734m-7.9-11.047a2.63 2.63 0 1 0 0 5.26 2.63 2.63 0 0 0 0-5.26Zm0 0V2.4"
    />
  </svg>
)

export default Evaluation

