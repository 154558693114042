import { Icon } from '@ed/ui'
import type { FC, PropsWithChildren } from 'react'

const AsideHeader: FC<PropsWithChildren<{ title: string, onClose: () => void }>> = ({ title, onClose, children }) => (
  <div
    className="flex flex-col px-6 py-5 border-b">
    <div className="flex flex-row items-center justify-between"><h2 className="text-lg font-semibold">{title}</h2>
      <button
        onClick={onClose}
        className="p-2 rounded-full hover:bg-gray-100"
        aria-label="Close panel"
      >
        <Icon.Close className="w-5 h-5"/>
      </button>
    </div>
    {children}
  </div>
)

export default AsideHeader
