import Modal from '@/components/Modal'
import { useState, type FC, type PropsWithChildren, useCallback, useMemo } from 'react'
import type { Modal as TModal } from '@/types/modal'
import { ButtonType, type ModalInternal, type ModalResponse } from '@/types/modal'
import { modalContext } from '@/hooks/useModal'
import { useTranslation } from 'react-i18next'

export const ModalProvider: FC<PropsWithChildren> = ({ children }) => {
  const { t } = useTranslation('shared')
  const [currentModal, setCurrentModal] = useState<ModalInternal | undefined>(undefined)

  const onInternalDismiss = useCallback(() => {
    setCurrentModal(old => (old ? { ...old, visible: false } : undefined))
    setTimeout(() => {
      setCurrentModal(undefined)
    }, 100)
  }, [])

  const showModal = useCallback(
    (modal: TModal) =>
      new Promise<ModalResponse>(resolve => {
        const newModal: ModalInternal = {
          ...modal,
          resolve,
          visible: true,
          positive: modal.positive
            ? {
              label: t('confirm'),
              ...modal.positive,
              onClick: async () => {
                await modal.positive?.onClick?.()
                onInternalDismiss()
                resolve({ button: ButtonType.POSITIVE })
              },
            }
            : undefined,
          negative: modal.negative !== null
            ? {
              label: t('cancel'),
              ...modal.negative,
              onClick: async () => {
                modal.negative?.onClick?.()
                onInternalDismiss()
                resolve({ button: ButtonType.NEGATIVE })
              },
            }
            : undefined,
          onDismiss: !modal.dismissible ? undefined : () => {
            onInternalDismiss()
            resolve({ button: undefined })
          },
        }
        setCurrentModal(newModal)
      }),
    [onInternalDismiss, t],
  )

  const renderModal = useMemo(
    () => (currentModal ? <Modal modal={currentModal} /> : null),
    [currentModal],
  )

  return (
    <modalContext.Provider value={{ showModal }}>
      <>
        {children}
        {renderModal}
      </>
    </modalContext.Provider>
  )
}
