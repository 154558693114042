import clsx from 'clsx'
import { Link } from 'react-router'
import React from 'react'
import remarkMath from 'remark-math'
import rehypeKatex from 'rehype-katex'
import rehypeRaw from 'rehype-raw'
import ReactMarkdown from 'react-markdown'

type IconButtonProps = {
  icon: React.ReactNode
  disabled?: boolean
  onClick?: () => void
  to?: string
  variant?: 'outlined' | 'default' | 'text'
  shape?: 'circle' | 'square'
  size?: 'small' | 'medium'
  tooltip?: string | null
}
export const IconButton = ({ icon, onClick, to, shape = 'circle', variant = 'default', disabled = false, tooltip, size = 'medium' }: IconButtonProps) => {
  const buttonElement = (
    <button disabled={disabled} onClick={onClick} title={tooltip ?? undefined} className={clsx(
      'flex items-center justify-center hover:bg-[#1e1b4b]/10 motion-safe:duration-200 outline outline-1 -outline-offset-1',
      variant === 'outlined' ? 'outline-zinc-300' : 'outline-transparent',
      variant === 'default' ? 'bg-white' : 'bg-transparent',
      shape === 'circle' ? 'rounded-full' : 'rounded-lg',
      size === 'small' ? 'w-8 h-8' : 'w-11 h-11',
      disabled ? 'text-gray-400' : 'text-black',
    )}>
      {React.cloneElement(icon as React.ReactElement, { className: 'w-5 h-5' })}
    </button>
  )

  return (to && !disabled) ? <Link to={to}>{buttonElement}</Link> : buttonElement
}

type ButtonProps = {
  label: string
  icon?: React.ReactNode
  iconPosition?: 'left' | 'right'
  onClick?: () => void
  variant?: 'outlined' | 'default' | 'primary'
  size?: 'small' | 'medium'
}
export const Button = ({ label, icon, iconPosition = 'left', onClick, variant = 'default', size = 'medium' }: ButtonProps) => {
  return (
    <button onClick={onClick} className={clsx(
      'px-6 rounded-md flex items-center gap-2 text-sm hover:bg-[#1e1b4b]/10 motion-safe:duration-200 outline outline-1 -outline-offset-1',
      variant === 'outlined' ? 'outline-zinc-300' : 'outline-transparent',
      variant === 'default' ?
        'bg-white' :
        variant === 'primary' ?
          'bg-[#4f46e5] hover:bg-[#4f46e5]/80 text-white' :
          'bg-transparent',
      size === 'small' ? 'h-8' : 'h-11'
    )}>
      {iconPosition === 'left' && icon}
      {label}
      {iconPosition === 'right' && icon}
    </button>
  )
}


type CardProps = {
  children?: React.ReactNode,
  icon?: React.ReactNode,
  title: string,
  color: string,
  className?: string,
}
export const Card = ({ children, icon, title, color, className }: CardProps) => {
  return <div className={clsx('flex flex-col gap-3', className)}>
    <div className='flex items-center gap-3 text-gray-500'>
      {icon && <div className='w-8 h-8 p-1.5 rounded-full' style={{ backgroundColor: color }}>
        {React.cloneElement(icon as React.ReactElement, { className: 'w-5 h-5 text-white' })}
      </div>}
      <span className='text-[#1e1b4b] font-medium'>{title}</span>
    </div>
    { children && <div>
      {children}
    </div> }
  </div>
}

export const MarkdownViewer = ({ content }: { content: string }) => {
  return <ReactMarkdown
    remarkPlugins={[remarkMath]}
    rehypePlugins={[[rehypeKatex, { strict: false }], rehypeRaw]}>
    {content}
  </ReactMarkdown>
}
