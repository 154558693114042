import type { FC } from 'react'

const Separator: FC<{ title: string }> = ({ title }) => {
  return (
    <div className="flex items-center gap-4">
      <div className="border-b border-opacity-20 border-medium-light-grey flex-grow"/>
      <div className="text-sm text-opacity-80 dark:text-white">{title}</div>
      <div className="border-b border-opacity-20 border-medium-light-grey flex-grow"/>
    </div>
  )
}

export default Separator
