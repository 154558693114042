import CreateCopyForm from '@/app/evaluation/[id]/copies/create/CreateCopyForm1'
import AsideHeader from '@/components/AsideHeader'
import useEvaluationId from '@/hooks/useEvaluationId'
import useSelection from '@/hooks/useSelection'
import { useCallback } from 'react'
import { useTranslation } from 'react-i18next'

const EvaluationCopyCreate = () => {
  const { t } = useTranslation('evaluation-add-copy')

  const { close, selection } = useSelection()
  const evaluationId = useEvaluationId()

  const onDone = useCallback(() => {
    close()
    if (selection.type === 'addCopy') {
      selection.refetch()
    }
  }, [close, selection])

  return (<>
    <AsideHeader title={t('title')} onClose={close} />

    <div className="m-4"><CreateCopyForm evaluationId={evaluationId.current ?? 0} onDone={onDone} /></div>
  </>)
}

export default EvaluationCopyCreate