import dayjs from 'dayjs'
import 'dayjs/locale/fr'
import { type EvaluationType } from '@ed/types'

dayjs.locale('fr')

export const formatEvaluationDate = (evaluation: EvaluationType, format = 'D MMM YYYY') =>
  dayjs(evaluation.createdAt).format(format)

export const formatDate = (date?: string, format = 'D MMM YYYY') =>
  date ? dayjs(date).format(format) : ''

export enum EvaluationPanelActionEnum {
  COPY = 'copy',
  COPY_LIST = 'copyList',
  CREATE_REMEDIATION = 'createRemediation',
  EDIT_RUBRIC = 'editRubric',
  ADD_COPY = 'addCopy',
  SCORE_DETAILS = 'scoreDetails',
  VIEW_EVALUATION_DOCUMENTS = 'viewEvaluationDocuments',
  CLOSE = 'close',
}
