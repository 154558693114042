import { type IconProps } from '../../types/icon'

const ClockForward = (props: IconProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    viewBox="0 0 24 24"
    {...props}
  >
    <path
      fill="none"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="m14.625 14.25-3.375-1.125V8.421m9 3.579a9 9 0 1 0-1.204 4.5m-1.265-5.488 2.25 2.25 2.25-2.25"
    />
  </svg>
)

export default ClockForward
