import config from '@/config/config'
import useEvaluationId from '@/hooks/useEvaluationId'

export const EvaluationAssistant = () => {
  const evaluationId = useEvaluationId()
  if (!evaluationId.current) return null

  return (
    <>
      <iframe src={`${config.bot_url}/evaluation/${evaluationId.current}`} className="w-full h-[calc(100vh-360px)] rounded-lg" />
    </>
  )
}