import { linkToQrcode } from '@/api/marketing.api'
import { Icon } from '@ed/ui'
import type { FC } from 'react'
import { useEffect, useState } from 'react'


const EvaluationCopyError: FC<{ evaluationId: number, copyId: number }> = ({ evaluationId, copyId }) => {
  const [qrcode, setQrcode] = useState<string>()

  useEffect(() => {
    // TODO use DynaLinks to support installing the app if not installed
    linkToQrcode(`https://app.ed.ai/evaluation/${evaluationId}/copy/${copyId}`).then(setQrcode)
  }, [copyId, evaluationId])

  return (
    <div className="w-[400px] flex flex-col p-6">
      <div className="bg-[#FF272A] bg-opacity-5 border border-[#FF272A] border-opacity-10 p-4 rounded-lg">
        <div className='flex items-center'>
          <Icon.Info className='mr-3' width={40} />
          <div className="text-[#112130] text-sm">Ce document n'a pas pu être traité parce que votre photo est floue.</div>
        </div>
        <div className="text-[#FF272A] text-sm underline cursor-pointer text-center mt-4"> Voir le fichier <Icon.ArrowRight className='inline' width={13}/></div>
      </div>
      <div className="bg-[#F9FAFC] mt-6 p-4 rounded-lg flex flex-col items-center justify-center">
        <div className="bg-white p-4 border border-[#291846] border-opacity-20 rounded-lg">
          <img className='inline-block min-h-[120px]' src={qrcode} alt="Re-scan de la copie" />
        </div>
        <div className='text-sm mt-6 mb-2 text-[#291846] text-center'>
          Vous pouvez aussi utiliser l'application pour re-scanner cette copie.
        </div>
      </div>
    </div>
  )
}

export default EvaluationCopyError