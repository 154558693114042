import type { PropsWithChildren } from 'react'
import { useState } from 'react'
import type { ContextType } from '@/components/tabs/TabContext'
import { TabContext } from '@/components/tabs/TabContext'

export const TabProvider = <Ids = string>({
  children,
  initialActiveTabId = '' as Ids
}: PropsWithChildren<{ initialActiveTabId: Ids }>) => {
  const [activeTabId, setActiveTabId] = useState<Ids>(initialActiveTabId)

  const value = { activeTabId, setActiveTabId } as unknown as ContextType
  return (
    <TabContext.Provider value={value}>
      {children}
    </TabContext.Provider>
  )
}
