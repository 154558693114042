import plugin from 'tailwindcss/plugin'

export default plugin(({ addUtilities, theme }) => {
  addUtilities({
    '.visually-hidden': {
      position: 'absolute',
      width: '1px',
      height: '1px',
      margin: '-1px',
      padding: '0',
      border: '0',
      clip: 'rect(0, 0, 0, 0)',
      overflow: 'hidden'
    },
    '.typo-body-xs': {
      fontFamily: theme('fontFamily.inter'),
      fontSize: theme('fontSize.xs'),
      lineHeight: theme('leading.5'),
    },
    '.typo-body-s': {
      fontFamily: theme('fontFamily.inter'),
      fontSize: theme('fontSize.s'),
      lineHeight: theme('leading.5'),
    },
    '.typo-body-l': {
      fontFamily: theme('fontFamily.inter'),
      fontSize: theme('fontSize.sm'),
      lineHeight: theme('leading.5-5'),
    },
    '.typo-title-s': {
      fontFamily: theme('fontFamily.inter'),
      fontSize: theme('fontSize.base'),
      lineHeight: theme('leading.6'),
    },
    '.typo-title-m': {
      fontFamily: theme('fontFamily.inter'),
      fontSize: theme('fontSize.lg'),
      lineHeight: theme('leading.6-5'),
    },
    '.typo-title-l': {
      fontFamily: theme('fontFamily.inter'),
      fontSize: theme('fontSize.xl'),
      lineHeight: theme('leading.7-5'),
    },
    '.typo-title-xl': {
      fontFamily: theme('fontFamily.inter'),
      fontSize: theme('fontSize.2xl'),
      lineHeight: theme('leading.9'),
    },
    '.typo-title-xxl': {
      fontFamily: theme('fontFamily.figtree'),
      fontSize: theme('fontSize.2-5xl'),
      lineHeight: theme('leading.10-5'),
    },
  })
})
