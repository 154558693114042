import { CookiesEnum } from '@ed/types'
import { useCookies } from 'react-cookie'
import { Trans, useTranslation } from 'react-i18next'
import { Button, FileUpload, Icon, Separator } from '@ed/ui'
import { type FC, type ChangeEvent, useState } from 'react'
import useAssetUpload from '@/hooks/useAssetUpload'
import QrCodeModal from '@/components/QrCodeModal'

const Upload: FC<{ next: () => void }> = ({ next }) => {
  const { t } = useTranslation('create-evaluation')
  const { files, addFiles, removeFile, ready } = useAssetUpload()
  const [isQrcodeModalOpen, setIsQrcodeModalOpen] = useState(false)
  const [cookies] = useCookies([CookiesEnum.AUTH])

  const onFilesChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target.size === 0) {
      return
    }

    addFiles(...Array.from(event.target.files || []))

    // Reset the input value so the same file can be selected again
    event.target.value = ''
  }

  return (<div className="max-w-screen-sm mx-auto flex flex-grow flex-col pt-20">
    <div className="text-3-5xl font-semibold">{t('import-mode.title')}</div>
    <div
      className="rounded-lg flex flex-col dark:border-dark-border bg-white dark:bg-dark-card border p-8 mt-10 gap-6 mb-6 ">
      <div className="text-sm">{t('import-mode.import.description')}</div>
      <label htmlFor="FileUpload"
        className="p-12 gap-4 rounded-lg border-dashed dark:border-dark-border bg-white dark:bg-dark-card border flex flex-row dark:hover:bg-blue-800/10 hover:bg-gray-100 dark:hover:border-blue-600 relative">
        <Icon.Upload className="size-20 dark:fill-[#C0BFC4]"/>
        <div className="text-sm/6"><Trans t={t} i18nKey="import-mode.import.prompt" components={{
          'a': <a className="underline text-primary hover:text-blue-800"/>
        }}/></div>
        <input
          id="FileUpload"
          type="file"
          required={true}
          accept="image/*,.pdf"
          className="absolute inset-0 z-50 m-0 h-full w-full p-0 opacity-0 outline-none"
          name="files"
          multiple={true}
          onChange={onFilesChange}
        />
      </label>
      {files.length > 0 && (<div className="flex flex-col gap-2">
        {files.map((file) => (
          <FileUpload key={file.localId} {...file} onCancel={() => removeFile(file)} />
        ))}
      </div>)}
      <Button className="self-end text-sm rounded-lg" disabled={!ready} theme="secondary" onClick={next}>{t('apply')}</Button>
    </div>
    <Separator title={t('or')}/>
    <QrCodeModal
      isOpen={isQrcodeModalOpen}
      onClose={() => setIsQrcodeModalOpen(false)}
      installLink={'https://ed-ai.dynalinks.app/app'}
      connectLink={`https://app.ed.ai/mdm/login?token=${encodeURI(cookies.auth)}`}
    />
    <div
      className="rounded-lg flex flex-row dark:border-dark-border bg-white dark:bg-dark-card border p-8 mt-6 gap-8 items-center cursor-pointer" onClick={() => setIsQrcodeModalOpen(true)}>
      <div className="bg-dark-primary/10 dark:bg-white/10 rounded-full p-4">
        <Icon.CameraSolid className="size-10"/></div>
      <div className="flex flex-col gap-1.5">
        <div className="font-semibold">{t('import-mode.scan.title')}</div>
        <div className="text-sm opacity-80">{t('import-mode.scan.description')}</div>
      </div>
      <Icon.ChevronRight/>
    </div>
  </div>
  )
}

export default Upload
