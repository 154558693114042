import { appendFilesToCopy, createCopy } from '@/api/copy'
import FileBox from '@/components/FileBox'
import { type EntityWithUniqueIdentifier } from '@/types/drag-and-drop'
import { getPath, URLS } from '@/utils/url'
import { Button } from '@ed/ui'
import { uniqueId } from 'lodash/fp'
import type { ChangeEvent } from 'react'
import { type Dispatch, type SetStateAction } from 'react'
import { useController, useForm, useFormState } from 'react-hook-form'
import { Trans, useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router'

const Submit = ({ isSubmitting }: {
  isSubmitting: boolean;
}) => {
  const { t } = useTranslation('create-copy-page')

  return (
    <Button
      type="submit"
      disabled={isSubmitting}
      className="w-full justify-center"
    >
      {t('form.submit')}
    </Button>
  )
}

type Fields = {
  title: string,
  files: EntityWithUniqueIdentifier<File>[]
}

const CreateCopyForm = ({ evaluationId, onDone }: { evaluationId: number, onDone?: (copyId: number | undefined) => void }) => {
  const { t } = useTranslation('create-copy-page')
  const navigate = useNavigate()

  const { handleSubmit, control, register } = useForm<Fields>({
    values: {
      title: '',
      files: []
    }
  })
  const { isSubmitting } = useFormState({ control })
  const { field: filesField } = useController({ control, name: 'files' })

  const setFiles: Dispatch<SetStateAction<EntityWithUniqueIdentifier<File>[]>> = (newFiles: SetStateAction<EntityWithUniqueIdentifier<File>[]>) => filesField.onChange(typeof newFiles === 'function' ? newFiles(filesField.value) : newFiles)

  const formStateCallback = async (
    data: Fields
  ): Promise<void> => {
    const { files, title } = data

    const copy = await createCopy({ copy: { title, evaluationId } })

    for await (const file of files) {
      const formData = new FormData()

      formData.append('files', file.entity)

      await appendFilesToCopy({
        copyId: copy.id,
        formData,
      })
    }

    if (onDone != null) {
      onDone(copy.id)
    } else {
      navigate(getPath(URLS.COPY, { copyId: copy.id }))
    }
  }

  const onFilesChange = (event: ChangeEvent<HTMLInputElement>) =>
    setFiles(prevFiles => {
      // Since FileList from input file is read-only and can't be edited,
      // this util convert the FileList into an classic array of File
      const newFiles: EntityWithUniqueIdentifier<File>[] =
        Array
          .from((event.target.files || []))
          .map((file: File) => ({ entity: file, id: uniqueId('file') }))

      return [...prevFiles, ...newFiles]
    })

  const removeFile = (id: EntityWithUniqueIdentifier<File>['id']) => {
    filesField.onChange(() => filesField.value?.filter(file => file.id !== id))
  }

  return (
    <form onSubmit={handleSubmit(formStateCallback)}>
      <div className="mb-8">
        <label
          htmlFor="title"
          className="mb-2.5 block font-medium text-gray-800 text-xl"
        >
          {t('form.inputs.name.label')}
        </label>
        <input
          type="text"
          required
          {...register('title')}
          placeholder={t('form.inputs.name.placeholder')}
          className="w-full rounded-sm border border-stroke bg-white px-4.5 px-2 py-3 text-gray-800 focus:border-primary focus-visible:outline-none dark:border-strokedark dark:bg-boxdark  dark:focus:border-primary"
        />
      </div>

      <div className="mb-8">
        <label
          htmlFor="taskImg"
          className="mb-2.5 block font-medium text-gray-800 text-xl"
        >
          {t('form.inputs.files.title')}
          <span className="block font-medium text-gray-500 text-sm mt-1">
            <Trans t={t} i18nKey="form.inputs.files.description" components={{
              u: <span className='underline text-red-600'/>,
              'app-link': <a className='underline text-blue-600 hover:text-blue-800' href="https://www.apple.com/fr/app-store/"/>
            }}/>
          </span>
        </label>
        <div>
          <div
            id="FileUpload"
            className="relative block w-full appearance-none rounded-sm border border-dashed border-stroke bg-white px-4 py-4 dark:border-strokedark dark:bg-boxdark sm:py-14"
          >
            <input
              type="file"
              required={true}
              accept="image/*,.pdf"
              className="absolute inset-0 z-50 m-0 h-full w-full p-0 opacity-0 outline-none"
              name="files"
              multiple={true}
              onChange={onFilesChange}
            />
            <div className="flex flex-col items-center justify-center space-y-3">
              <span className="flex h-11.5 w-11.5 items-center justify-center rounded-full border border-stroke bg-primary/5 dark:border-strokedark">
                <svg
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g clipPath="url(#clip0_75_12841)">
                    <path
                      d="M2.5 15.8333H17.5V17.5H2.5V15.8333ZM10.8333 4.85663V14.1666H9.16667V4.85663L4.1075 9.91663L2.92917 8.73829L10 1.66663L17.0708 8.73746L15.8925 9.91579L10.8333 4.85829V4.85663Z"
                      fill="#3C50E0"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_75_12841">
                      <rect width="20" height="20" fill="purple" />
                    </clipPath>
                  </defs>
                </svg>
              </span>
              <p className="text-xs">
                <span className="text-primary">
                  {t('form.inputs.files.click-or-drop-files')}
                </span>
              </p>
            </div>
          </div>

          {filesField.value && (
            <FileBox
              files={filesField.value}
              setFiles={setFiles}
              removeFile={removeFile}
            />
          )}
        </div>
      </div>
      <Submit isSubmitting={isSubmitting}/>
    </form>
  )
}

export default CreateCopyForm
