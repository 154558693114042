import { getAssetThumbnailUrl } from '@/utils/asset'
import type { CopyType, EvaluationType, NormalizedPointType, NormalizedRectangleType } from '@ed/types'
import { BlockType } from '@ed/types'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import type { ExerciseType, QuestionType } from './types'
import { ExerciseContent } from './ExerciseContent'
import ExerciseBoundary from './ExerciseBoundary'
import AssetImage from '@/components/AssetImage'
import clsx from 'clsx'

const IMAGE_GAP = 24
const IMAGE_MARGIN = 24


type PageWithImageSizeType = {
  pageNumber: number,
  assetId: string,
  imageWidth: number,
  imageHeight: number
}

type PageType = PageWithImageSizeType & {
  width: number,
  height: number,
  startY: number,
  endY: number
}

type PointOnPageType = NormalizedPointType & {
  pageNumber: number
}
const computeScore = <T extends { children?: T[] }>(x: T, fn: (value: T) => number | null): number | null => {
  if (fn(x) !== null)
    return fn(x)
  return x.children?.reduce<number | null>((acc, child) => {
    const childScore = computeScore(child, fn)
    if (childScore === null) return acc
    return (acc ?? 0) + childScore
  }, null) ?? null
}

const CopyContent = ({ className, evaluation, copy }: { className?: string, evaluation: EvaluationType, copy: CopyType }) => {
  const imageContainerRef = useRef<HTMLDivElement>(null)
  const [selectedExercise, setSelectedExercise] = useState<ExerciseType | null>(null)
  // Create a ref map to store references to exercise content elements
  const exerciseContentRefs = useRef<Map<string, HTMLDivElement>>(new Map())

  /** The width of the image container */
  const [width, setWidth] = useState(100)
  const observer = useMemo(() => new ResizeObserver((entries) => {
    setWidth(entries[0].contentRect.width)
  }), [])
  useEffect(() => {
    if (imageContainerRef.current)
      observer.observe(imageContainerRef.current)
    return () => observer.disconnect()
  }, [observer])

  /** Pages with their image size.
   * This is fetched asynchronously as the image size is not available in the backend response.
   */
  const [pagesWithImageSize, setPagesWithImageSize] = useState<PageWithImageSizeType[]>([])
  // This will disappear as soon as we have the data from the backend
  useEffect(() => {
    setPagesWithImageSize(copy.transcribedDocument?.pages.map((page) => ({
      pageNumber: page.number,
      assetId: page.assetId,
      imageWidth: 210,
      imageHeight: 297
    })) ?? [])
    const fetchData = async () => {
      const result = await Promise.all(copy.transcribedDocument?.pages.map(async (page) => {
        const image = new Image()
        image.src = getAssetThumbnailUrl(`copies/${copy.id}`, page.assetId)
        await image.decode()
        return {
          pageNumber: page.number,
          assetId: page.assetId,
          imageWidth: image.width,
          imageHeight: image.height,
        } satisfies PageWithImageSizeType
      }) ?? [])
      setPagesWithImageSize(result)
    }
    fetchData()
  }, [copy.transcribedDocument?.pages, copy.id])

  /** Pages with their position on the layout. */
  const pages: PageType[] = useMemo(() => {
    let y = IMAGE_MARGIN
    return pagesWithImageSize.map((page) => {
      const imageWidth = width - IMAGE_MARGIN * 2
      const imageHeight = page.imageHeight / page.imageWidth * imageWidth
      const result = {
        ...page,
        width: imageWidth,
        height: imageHeight,
        startY: y,
        endY: y + imageHeight
      }
      y += result.height + IMAGE_GAP + IMAGE_MARGIN * 2
      return result
    })
  }, [pagesWithImageSize, width])

  /** Convert a point on a page with normalized coordinates to a point on the layout. */
  const pointOnPageToPoint = useCallback((point: PointOnPageType) => {
    const page = pages.find((page) => page.pageNumber === point.pageNumber)
    if (!page)
      return {
        x: 0,
        y: 0
      }
    return {
      x: point.x * page.width,
      y: page.startY + point.y * page.height
    }
  }, [pages])

  const findMatchingTranscribedDocumentBlocks = useCallback((exerciseNumber: string) => {
    return copy.transcribedDocument?.pages.flatMap((page) => {
      const blocks = page.content.filter((block) => block.type === BlockType.Question && block.number === exerciseNumber)
      return blocks.map((block) => ({ page, block }))
    }) ?? []
  }, [copy.transcribedDocument])

  /** List of exercises containing all data (from evaluation, transcription and copy) to display. */
  const exercises = useMemo<ExerciseType[]>(() => {
    if (!copy.autoGradingOutput || !evaluation.autoGradingOutput)
      return []

    const copyExercises = copy.autoGradingOutput.questions
    const evaluationExercises = evaluation.autoGradingOutput.questions

    const competencyShortNames = Object.fromEntries(evaluation.autoGradingOutput.global.competencies?.map((competency) => [competency.name, competency.shortName]) ?? [])

    const result = copyExercises.map((copyExercise) => {
      const evaluationExercise = evaluationExercises.find((evaluationExercise) => evaluationExercise.number === copyExercise.number)
      if (! evaluationExercise)
        throw new Error(`Evaluation exercise ${copyExercise.number} not found`)
      const maxScore = computeScore(evaluationExercise, (x) => x.score?.max ?? null)
      const matchingTranscribedDocumentBlocks = findMatchingTranscribedDocumentBlocks(`${evaluationExercise.transcriptionNumber}`)
      const boundingBoxes = matchingTranscribedDocumentBlocks
        .map(({ block, page }) => ({ boundingBox: 'normalizedBoundingBox' in block ? block.normalizedBoundingBox as NormalizedRectangleType : null, pageNumber: page.number }))
        .filter(({ boundingBox }) => boundingBox != null)
        .map(({ boundingBox, pageNumber }) => ({ startY: Math.min(...boundingBox!.map((point) => pointOnPageToPoint({ ...point, pageNumber }).y)), endY: Math.max(...boundingBox!.map((point) => pointOnPageToPoint({ ...point, pageNumber }).y)) }))
      return {
        simplifiedNumber: evaluationExercise.simplifiedNumber,
        number: copyExercise.number,
        boundingBoxes,
        comment: copyExercise.comment,
        score: maxScore != null ? {
          value: computeScore(copyExercise, (x) => x.score?.value ?? null) ?? 0,
          max: maxScore
        } : undefined,
        errors: copyExercise.errors?.map((error) => ({ type: error.type, details: error.details })),
        competences: evaluationExercise.competencies?.map((competency) => ({ shortName: competencyShortNames[competency], fullName: competency })),
        children: copyExercise.children?.map((copyQuestion) => {
          const evaluationQuestion = evaluationExercise.children?.find((evaluationQuestion) => evaluationQuestion.number === copyQuestion.number)
          if (! evaluationQuestion)
            throw new Error(`Evaluation question ${copyQuestion.number} not found`)
          const maxScore = computeScore(evaluationQuestion, (x) => x.score?.max ?? null)
          return {
            number: copyQuestion.number,
            comment: copyQuestion.comment,
            score: maxScore != null ? {
              value: computeScore(copyQuestion, (x) => x.score?.value ?? null) ?? 0,
              max: maxScore
            } : undefined,
            errors: copyQuestion.errors?.map((error) => ({ type: error.type, details: error.details })),
            competences: evaluationQuestion.competencies?.map((competency) => ({ shortName: competencyShortNames[competency], fullName: competency })),
          } satisfies QuestionType
        })
      } satisfies ExerciseType
    })
    return result
  }, [copy.autoGradingOutput, evaluation.autoGradingOutput, pointOnPageToPoint, findMatchingTranscribedDocumentBlocks])

  /** The spaces correspond to the available block space for a given exercise or a fixed block space. */
  type SpaceType = {
    /** The start top position of the space. */
    startY: number,
    /** The end top position of the first exercise block even if multiple blocks are merged. */
    strictEndY: number,
    /** The end top position of the last exercise block even if multiple blocks are merged. */
    endY: number,
    /** The start top position of the next exercie. */
    extendedEndY: number,
    /** The exercise associated to the space. */
    exercise?: ExerciseType,
    /** Whether the exercise has already been seen. */
    alreadySeen: boolean
  }
  const spaces: SpaceType[] = useMemo(() => {
    const boundingBoxes = exercises.flatMap((exercise) => exercise.boundingBoxes.map((boundingBox) => ({ ...boundingBox, exercise })))
    // Sort by startY
    boundingBoxes.sort((a, b) => a.startY - b.startY)
    // Merge consecutive bounding boxes that are on the same exercise
    const alreadySeenExercises = new Set<ExerciseType>()
    let currentMerged: SpaceType = { startY: 0, strictEndY: 0, endY: 0, extendedEndY: 0, alreadySeen: false }
    const merged: SpaceType[] = [currentMerged]
    for (const current of boundingBoxes) {
      if (currentMerged.exercise === current.exercise) {
        currentMerged.endY = current.endY
      } else {
        currentMerged.extendedEndY = current.startY
        const alreadySeen = alreadySeenExercises.has(current.exercise)
        alreadySeenExercises.add(current.exercise)
        currentMerged = { ...current, strictEndY: current.endY, extendedEndY: current.endY, alreadySeen }
        merged.push(currentMerged)
      }
    }
    if (pages?.length)
      currentMerged.extendedEndY = pages[pages.length - 1].endY
    return merged
  }, [exercises, pages])

  const handleSelectExercise = useCallback((exercise: ExerciseType) => {
    const contentElement = exerciseContentRefs.current.get(exercise.number)
    if (contentElement)
      contentElement.scrollIntoView({ behavior: 'smooth', block: 'nearest' })
    setSelectedExercise(exercise)
  }, [])

  return <div className={clsx('relative grid grid-cols-[minmax(0,60%)_40%] gap-12', className)} onClick={() => setSelectedExercise(null)}>
    <div className='relative'>
      {/* Images */}
      <div ref={imageContainerRef} className='flex flex-col' style={{ gap: IMAGE_GAP }}>
        {pages.map((page, index) => (
          <div key={page.assetId} className='w-full outline outline-1 -outline-offset-1 outline-zinc-300 bg-white' style={{ padding: IMAGE_MARGIN }}>
            <AssetImage
              className='w-full'
              base={`copies/${copy.id}`}
              assetId={page.assetId}
              alt={`Page ${index + 1}`}
              pageNumber={page.pageNumber}
            />
          </div>
        ))}
      </div>
      {/* Exercise boundaries */}
      <div className='absolute top-0 left-0 w-full h-full'>
        {exercises.flatMap((exercise, exerciseIndex) => exercise.boundingBoxes.map((boundingBox, boundingBoxIndex) => {
          return <div
            key={`${exerciseIndex}-${boundingBoxIndex}`}
            className='absolute w-full'
            style={{ top: boundingBox.startY, height: boundingBox.endY - boundingBox.startY }}
            ref={(el) => {
              if (el && exercise && !exerciseContentRefs.current.has(exercise.number))
                exerciseContentRefs.current.set(exercise.number, el)
            }}>
            <ExerciseBoundary
              number={exercise.simplifiedNumber}
              isSelected={selectedExercise?.number === exercise.number}
              onSelect={() => handleSelectExercise(exercise)}
            />
          </div>
        }))}
      </div>
    </div>
    {/* Exercise content */}
    <div className='relative'>
      {spaces.map(({ exercise, startY, extendedEndY, alreadySeen }, index) => (
        (exercise && ! alreadySeen) ?
          <div
            key={index}
            className='pb-2'
            style={{ height: exercise.number === selectedExercise?.number ? undefined : extendedEndY - startY, minHeight: exercise.number === selectedExercise?.number ? extendedEndY - startY : undefined }}
          >
            <ExerciseContent
              key={exercise.number}
              exercise={exercise}
              isSelected={selectedExercise?.number === exercise.number}
              onSelect={() => setSelectedExercise(exercise)}
              onUnselect={() => setSelectedExercise(null)}
            />
          </div>
          :
          <div key={index} style={{ height: extendedEndY - startY }} />
      ))}
    </div>
  </div>
}

export default CopyContent
