import { appendFilesToEvaluation, createEvaluation, deleteEvaluationAssets } from '@/api/evaluation.ts'
import useEvaluationId from '@/hooks/useEvaluationId'
import { type AssetType, StatusEnum } from '@ed/types'
import type { AxiosProgressEvent } from 'axios'
import { nanoid } from 'nanoid'
import { useCallback, useEffect, useMemo, useReducer, useRef } from 'react'
import { useTranslation } from 'react-i18next'

export type UploadProgress = {
  localId: string
  file: File
  name: string
  type: 'image' | 'pdf'
  progress: number
  size: string
  assetId?: string
  error?: string
  abort?: AbortController
}

export type ExistingFile = Omit<UploadProgress, 'file'> & { pageCount: number }

type Action = { type: 'add', payload: UploadProgress }
  | { type: 'remove', payload: Pick<UploadProgress, 'localId'> }
  | { type: 'update', payload: Pick<UploadProgress, 'localId'> & Partial<UploadProgress> }
  | { type: 'init', payload: ExistingFile[] }

const reducer = (state: Array<UploadProgress | ExistingFile>, action: Action) =>
{
  switch (action.type) {
    case 'add':
      return [...state, action.payload]
    case 'remove':
      return state.filter(f => f.localId !== action.payload.localId)
    case 'update':
      return state.map(f => f.localId === action.payload.localId ? { ...f, ...action.payload } : f)
    case 'init':
      return action.payload
    default:
      return state
  }
}

const useAssetUpload = () => {
  const { t } = useTranslation()
  const [files, dispatch] = useReducer(reducer, [])
  const evaluationId = useEvaluationId()
  const uploading = useRef<Readonly<UploadProgress>>()
  const ready = useMemo(() => (
    files.length > 0 &&
    files.every(f => f.progress === 1 && f.error === undefined)),
  [files])

  const startUpload = useCallback(async (newUpload: UploadProgress) => {
    if (evaluationId.current === undefined) {
      const newEvaluation = await createEvaluation(
        { evaluation: { status: StatusEnum.DRAFT } }
      )

      evaluationId.current = newEvaluation.id ?? 0 // TODO handle error
    }

    dispatch({ type: 'update', payload: { ...newUpload, progress: 0.001 } })

    const formData = new FormData()
    formData.append('files', newUpload.file)

    const onUploadProgress = (progressEvent: AxiosProgressEvent) => {
      if (progressEvent.progress ?? 0 >= 0.001) {
        dispatch({ type: 'update', payload: { ...newUpload, progress: progressEvent.progress } })
      }
    }

    try {
      const assets = await appendFilesToEvaluation({
        evaluationId: evaluationId.current,
        formData,
        onUploadProgress,
        signal: newUpload.abort?.signal
      })

      dispatch({
        type: 'update',
        payload: {
          ...newUpload,
          progress: 1,
          assetId: assets?.[0]?.id,
          abort: undefined,
          error: (assets?.length ?? 0) === 0 ? t('upload-error', { ns: 'create-evaluation' }) : undefined
        }
      })
    } catch (e) {
      console.error(e)
      dispatch({
        type: 'update',
        payload: {
          ...newUpload,
          progress: 0,
          abort: undefined,
          error: t('upload-error', { ns: 'create-evaluation' })
        }
      })
    } finally {
      uploading.current = undefined
    }
  }, [evaluationId, t])

  const addFiles = useCallback((...files: File[]) => {
    for (const file of files) {
      const payload: UploadProgress = {
        localId: nanoid(),
        file,
        name: file.name,
        type: file.type.toLowerCase().endsWith('.pdf') ? 'pdf' : 'image',
        progress: 0,
        size: t('size-mb', { sizeMb: (file.size / 1024 / 1024).toFixed(2) }),
        abort: new AbortController()
      }

      dispatch({ type: 'add', payload })
    }
  }, [t])

  useEffect(() => {
    if (uploading.current === undefined) {
      const nextUpload = files.find(f => f.progress === 0 && f.error === undefined && 'file' in f) as UploadProgress | undefined
      uploading.current = nextUpload
      if (nextUpload !== undefined) {
        startUpload(nextUpload)
      }
    }
  }, [files, startUpload])

  const removeFile = useCallback(async (file: UploadProgress | ExistingFile) => {
    file.abort?.abort('User cancelled')

    if (file.assetId !== undefined) {
      await deleteEvaluationAssets({
        evaluation: { id: evaluationId.current, assetIds: [file.assetId] }
      })
    }

    dispatch({ type: 'remove', payload: file })
  }, [evaluationId])

  const initFiles = useCallback((assets: AssetType[]) => dispatch(
    { type: 'init', payload: assets.map(a => ({
      localId: nanoid(),
      assetId: a.id,
      type: a.mimeType as 'image' | 'pdf',
      progress: 1,
      size: '0',
      file: undefined,
      name: a.filename,
      pageCount: a.pageCount,
    })) }
  ), [])

  return { files, ready, addFiles, removeFile, initFiles }
}

export default useAssetUpload
