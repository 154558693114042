import TitleAndButton from '@/components/TitleAndButton'
import useEvaluationId from '@/hooks/useEvaluationId'
import useSelection from '@/hooks/useSelection'
import { studentName } from '@/utils/copy'
import { EvaluationPanelActionEnum } from '@/utils/evaluation'
import { getStatusBackgroundColor, getStatusIconColor, getStatusWordingKey } from '@/utils/status'
import type { TypedDocumentNode } from '@apollo/client'
import { gql, useQuery } from '@apollo/client'
import { type AutoGradingEvaluationOutput, type CopyType } from '@ed/types'
import type { ColumnType } from '@ed/ui'
import { Icon, Table } from '@ed/ui'
import clsx from 'clsx'
import { useMemo } from 'react'
import { useCookies } from 'react-cookie'
import { useTranslation } from 'react-i18next'
import { map, pipe, sortBy } from 'remeda'

const gqlQuery: TypedDocumentNode<{
  client: {
    evaluations: { hits: Array<{ copies: Array<CopyType>, autoGradingOutput: AutoGradingEvaluationOutput }> }
  }
}> = gql`
  query getEvaluationCopies($id: Int!) {
    client {
      evaluations (ids: [$id]) {
        hits {
          copies {
            id
            studentName
            status
            autoGradingOutput
          }
          autoGradingOutput
        }
      }
    }
  }
`

const EvaluationCopies = () => {
  const { t } = useTranslation(['evaluation-copies', 'utils'])
  const evaluationId = useEvaluationId()
  const { dispatch, selection: selection } = useSelection()
  const [cookies] = useCookies(['enableHidden'])

  const { data, refetch } = useQuery(gqlQuery, { variables: { id: evaluationId.current }, skip: evaluationId.current == null })
  const copies = useMemo(
    () =>
      pipe(
        data?.client?.evaluations?.hits[0]?.copies ?? [],
        sortBy(c => c.studentName ?? String(c.id)),
        map(c => ({ ...c, copyName: studentName(c, t) })),
      ),
    [data, t])
  const max = data?.client?.evaluations?.hits[0]?.autoGradingOutput?.global?.score?.max

  // console.log('EvaluationCopies', copies)

  const columns: ColumnType<CopyType & { copyName: string }>[] = [
    {
      id: 'name',
      header: <p className='text-sm font-semibold'>{t('headers.student-name')}</p>,
      accessor: (copy) => (
        <p className='text-[#291846] font-medium text-sm'>{copy.copyName}</p>
      )
    },
    {
      id: 'status',
      header: <p className='text-sm font-semibold'>{t('headers.status')}</p>,
      accessor: (copy) => (
        <p className={clsx(
          'text-sm rounded-full px-2 py-1 w-fit',
          getStatusBackgroundColor(copy.status),
          getStatusIconColor(copy.status))}>
          {t(`utils:copy-status.${getStatusWordingKey(copy.status)}`)}
        </p>
      )
    },
    {
      id: 'score',
      header: <p className='text-sm font-semibold'>{t('headers.score')}</p>,
      accessor: (copy) => {
        const score = copy.autoGradingOutput?.global?.score

        if (!score) return '--'

        return (
          <p className='text-[#291846] font-semibold text-sm'>
            {t('numeric-score', { score: score?.value })}
            {max !== undefined && <span className="font-normal text-[#6D717F]">{t('numeric-max', { max })}</span>}
          </p>
        )
      }
    }
  ]

  const onSelectCopy = (row: CopyType) => {
    dispatch({ type: EvaluationPanelActionEnum.COPY, copyId: row.id })
  }

  return <div className="rounded-2xl bg-white px-8 py-6">
    <TitleAndButton
      buttonLabel={t('add-copy')}
      title={t('title')}
      onClick={() => dispatch({ type: EvaluationPanelActionEnum.ADD_COPY, refetch })}
      buttonIcon={<Icon.Plus className="size-5"/>}
      buttonDisabled={!cookies.enableHidden} // @TEMP
    />

    <Table
      columns={columns}
      data={copies ?? []}
      getRowKey={(row) => row.id ?? 0}
      minimal={true}
      onRowClick={onSelectCopy}
      selectedRow={selection.type === EvaluationPanelActionEnum.COPY ? selection.copyId : 0}
      rowClassName="h-12 items-center hover:bg-gray-50 cursor-pointer transition duration-200 motion-safe:transition-none"/>
  </div>
}

export default EvaluationCopies