import clsx from 'clsx'
import type { PropsWithChildren, ReactNode } from 'react'

type LabelWrapperProps = {
  label: ReactNode;
  className?: string;
}

const LabelWrapper = ({ label, className, children }: PropsWithChildren<LabelWrapperProps>) => (
  <div className={clsx('flex flex-col gap-2', className)}>
    <label className='text-sm font-semibold'>{label}</label>
    {children}
  </div>
)

export default LabelWrapper
