import type { ModalInternal } from '@/types/modal'
import { Button } from '@ed/ui'
import { type FC, useCallback, useState } from 'react'
import { isPromise } from 'remeda'

const Modal: FC<{ modal: ModalInternal }> = ({ modal }) =>
{
  const [positivePending, setPositivePending] = useState(false)
  const positiveOnClick = useCallback(async () => {
    const result = modal.positive?.onClick?.()
    if (isPromise(result)) {
      try {
        setPositivePending(true)
        await result
        setPositivePending(false)
      }
      catch (e) {
        console.error(e)
      }
    }
  }, [modal.positive])

  if (!modal.visible) return null

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50 text-center" onClick={modal.onDismiss}>
      <div className="bg-white p-6 rounded-lg shadow-xl max-w-md w-full">
        {'title' in modal && <h2 className="text-xl font-bold mb-4">{modal.title}</h2>}
        {'message' in modal && <p className="mb-6">{modal.message}</p>}
        {modal.body}
        <div className="flex justify-end space-x-4">
          {!!modal.negative && <Button theme="primary-invert"
            onClick={modal.negative?.onClick}
            disabled={positivePending}
            className="font-semibold text-sm">{modal.negative?.label}</Button>}
          {!!modal.positive && <Button
            pending={positivePending}
            onClick={positiveOnClick}
            className="font-semibold text-sm">{modal.positive?.label}</Button>}
        </div>
      </div>
    </div>
  )
}

export default Modal
