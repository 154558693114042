import { type IconProps } from '../../types/icon'

const Upload = (props: IconProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={48}
    height={48}
    viewBox="0 0 72 72"
    {...props}
  >
    <path
      fill="currentColor"
      stroke="none"
      d="M36 26V12.92C37.826 13.612 39.506 14.678 40.93 16.1L47.898 23.072C49.322 24.494 50.388 26.174 51.08 28H38C36.896 28 36 27.102 36 26ZM48 36C41.372 36 36 41.372 36 48C36 54.628 41.372 60 48 60C54.628 60 60 54.628 60 48C60 41.372 54.628 36 48 36ZM53.864 46.918C53.592 47.574 52.952 48 52.24 48H49.998V54C49.998 55.104 49.104 56 47.998 56C46.892 56 45.998 55.104 45.998 54V48H43.756C43.046 48 42.404 47.574 42.132 46.918C41.856 46.26 42.008 45.506 42.512 45.004L45.512 42.004C46.882 40.634 49.112 40.634 50.482 42.004L53.482 45.004C53.988 45.508 54.136 46.268 53.864 46.918ZM32 48C32 39.178 39.178 32 48 32H38C34.692 32 32 29.308 32 26V12.048C31.678 12.026 31.356 12 31.03 12H22C16.486 12 12 16.486 12 22V50C12 55.514 16.486 60 22 60H37.452C34.118 57.066 32 52.78 32 48Z"
    />
  </svg>
)

export default Upload
