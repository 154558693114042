import { Icon } from '@ed/ui'
import clsx from 'clsx'
import { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { MarkdownViewer } from './components'
import ExerciseNumber from './ExerciseNumber'
import type { ErrorType, ExerciseType, QuestionType } from './types'

const ErrorDetails = ({ errors }: { errors: ErrorType[] }) => {
  const { t } = useTranslation('copy-page')
  const [isOpen, setIsOpen] = useState(false)

  return <>{errors?.length > 0 && <div className='flex flex-col gap-2'>
    <button onClick={() => setIsOpen(!isOpen)} className='text-gray-500 font-medium flex flex-row gap-1 items-center'>
      {isOpen ? <>{t('exercise-content.hide-details')}<Icon.ChevronDown width={16} height={16} /></> : <>{t('exercise-content.show-details')}<Icon.ChevronRight width={16} height={16} /></>}
    </button>
    {isOpen && <div className='border-l-2 border-[#4f46e5] pl-4 my-2 flex flex-col gap-3'>
      {errors.map((error) => (
        <div key={error.type} className='text-[#1e1b4b]'>
          <div className='text-[#4f46e5] font-medium'>{error.type}</div>
          {error.details && <MarkdownViewer content={error.details} />}
        </div>
      ))}
    </div>}
  </div>}
  </>
}

const QuestionTitle = ({ question }: { question: QuestionType }) => {
  return <div className='flex flex-row gap-3 items-center'>
    <div className="w-2 h-2 bg-[#4f46e5] rounded-[16px]" />
    <div className='flex-1 font-medium text-sm'>
      {question.number}
    </div>
    <div className='invisible'>
      <Icon.Edit />
    </div>
    {question.score && <ScoreCard value={question.score.value} max={question.score.max} />}
  </div>
}

const QuestionContent = ({ question }: { question: QuestionType }) => {
  return <div className='py-3 flex flex-col gap-2'>
    <QuestionTitle question={question} />
    {question.competences?.length && <div className='flex flex-row gap-2'>
      {question.competences?.map((competence) => <CompetencyBadge key={competence.shortName} shortName={competence.shortName} fullName={competence.fullName} />)}
    </div>}
    {question.comment && <MarkdownViewer content={question.comment} />}
    <ErrorDetails errors={question.errors ?? []} />
  </div>
}

const ScoreCard = ({ value, max }: { value: number, max: number }) => {
  const percentage = value / max * 100
  let color: string
  if (percentage < 50)
    color = '#e11d48'
  else if (percentage < 70)
    color = '#d97706'
  else
    color = '#10b981'

  const { t } = useTranslation('copy-page')
  return <div className='rounded font-medium p-2' style={{ backgroundColor: color + '0D', color }}>
    {t('exercise-content.score', { value, max })}
  </div>
}

const CompetencyBadge = ({ shortName, fullName }: { shortName: string, fullName: string }) => {
  return <div
    className='rounded-full bg-[#4f46e5]/5 text-xs px-4 py-1.5 text-[#4f46e5] outline outline-1 outline-offset-[-1px] outline-[#4f46e5]/20'
    title={shortName !== fullName ? fullName : undefined}>
    {shortName}
  </div>
}

const ExerciseTitle = ({ exercise }: { exercise: ExerciseType }) => {
  return <div className='flex text-sm flex-row gap-3 items-center'>
    <ExerciseNumber number={exercise.simplifiedNumber} />
    <div className='flex-1 font-semibold'>
      {exercise.number}
    </div>
    <div className='invisible'>
      <Icon.Edit />
    </div>
    {exercise.score && <ScoreCard value={exercise.score.value} max={exercise.score.max} />}
  </div>
}

/**
 * Display the content of an exercise correction.
 */
export const ExerciseContent = ({ exercise, isSelected, onSelect, onUnselect }: { exercise: ExerciseType, isSelected?: boolean, onSelect?: () => void, onUnselect?: () => void }) => {
  const { t } = useTranslation('copy-page')
  const [shouldShowMore, setShouldShowMore] = useState(false)
  /** Reference to the very bottom of the exercise content. */
  const sentinelRef = useRef<HTMLDivElement>(null)
  /** Reference to the container of the exercise content. */
  const containerRef = useRef<HTMLDivElement>(null)

  /**
   * Intersection observer to detect when the exercise content doesn't have enough space to be displayed entirely.
   * In that case, show a "show more" button.
   */
  useEffect(() => {
    if (!containerRef.current || !sentinelRef.current)
      return
    const intersectionObserver = new IntersectionObserver((entries) => {
      setShouldShowMore(entries[0].intersectionRatio !== 1)
    }, { root: containerRef.current, threshold: [1] })
    intersectionObserver.observe(sentinelRef.current)
    return () => intersectionObserver.disconnect()
  }, [containerRef, sentinelRef])

  /**
   * Intersection observer to detect when the exercise is not visible anymore.
   * In that case, auto-collapse the exercise.
   */
  useEffect(() => {
    if (!containerRef.current)
      return
    const intersectionObserver = new IntersectionObserver((e) => {
      if (isSelected && !e[0].isIntersecting) {
        onUnselect?.()
      }
    }, { threshold: [0] })
    intersectionObserver.observe(containerRef.current)
    return () => intersectionObserver.disconnect()
  }, [containerRef, isSelected, onUnselect])

  // The top- property is set to 68px to account for the navigation bar.
  return <div ref={containerRef} className={clsx(
    'sticky top-[68px] group rounded-xl overflow-clip w-full h-fit max-h-full',
    isSelected ? 'bg-white outline outline-1 outline-offset-[-1px] outline-[#4f46e5]' : 'bg-[#f1f5f9] hover:bg-indigo-50')}
  onClick={(event) => {
    event.stopPropagation()
    onSelect?.()
  }}>
    <div ref={sentinelRef}
      className={clsx(
        'p-5 gap-2 flex flex-col text-sm')}>
      <ExerciseTitle exercise={exercise} />
      {exercise.competences?.length && <div className='flex flex-row gap-2'>
        {exercise.competences?.map((competence) => <CompetencyBadge key={competence.shortName} shortName={competence.shortName} fullName={competence.fullName} />)}
      </div>}
      {exercise.comment && <MarkdownViewer content={exercise.comment} />}
      {exercise.children?.length ?
        <>
          <div className='flex flex-col'>
            <div className='border-t border-[#1e1b4b]/10 -mx-5 my-2' />
            {exercise.children.map(question => (<QuestionContent key={question.number} question={question} />))}
          </div>
        </>
        :
        <ErrorDetails errors={exercise.errors ?? []} />}
    </div>
    {shouldShowMore && <div className='absolute bottom-0 flex flex-col w-full '>
      <div className="w-full h-12 bg-gradient-to-t from-[#f1f5f9] to-[#f1f5f9]/0 group-hover:from-indigo-50 group-hover:to-indigo-50/0" />
      <div className='border-t border-[#1e1b4b]/10' />
      <div className='text-[#4f46e5] font-medium text-sm flex justify-center pt-2 pb-4 bg-[#f1f5f9] group-hover:bg-indigo-50'>
        {t('exercise-content.show-all-questions')}
      </div>
    </div>}
  </div>
}
