import { type IconProps } from '../../types/icon'

const ArrowRight = (props: IconProps) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    stroke='currentColor'
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <path d="M13.3333 5L20 12M20 12L13.3333 19M20 12L4 12" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
  </svg>
)

export default ArrowRight
