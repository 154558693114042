import { type IconProps } from '../../types/icon'

const Check = (props: IconProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={18}
    height={18}
    viewBox="0 0 18 18"
    {...props}
  >
    <path
      stroke="none"
      fill="currentColor"
      d="M6.75 12.127 3.623 9l-1.065 1.057L6.75 14.25l9-9-1.057-1.058-7.943 7.935Z"
    />
  </svg>
)

export default Check
