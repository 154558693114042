import { useRef } from 'react'
import { useNavigate, useParams } from 'react-router'
import EditEvaluation from '@/app/evaluation/EditEvaluation'
import EvaluationIdProvider from '@/contexts/EvaluationIdProvider'

const EvaluationEditWrapper = () => {
  const { evaluationId: iEvaluationId } = useParams<{ evaluationId: string }>()
  const evaluationId = useRef(parseInt(iEvaluationId || '0', 10))

  const navigate = useNavigate()

  return (
    <EvaluationIdProvider evaluationId={evaluationId}>
      <EditEvaluation next={() => navigate(-1)} onQuit={() => navigate(-1)} />
    </EvaluationIdProvider>
  )
}

export default EvaluationEditWrapper
