
import type { FC } from 'react'
import { useState } from 'react'
import type { UserExerciseType } from '@ed/types'
import ExerciseMenu from '@/components/exercise/menu'
import ExerciseToolbar from './toolbar'
import Markdown from 'react-markdown'
import remarkMath from 'remark-math'
import rehypeKatex from 'rehype-katex'
import rehypeRaw from 'rehype-raw'

const Exercise: FC<{ exercise: UserExerciseType, viewMode: 0 | 1, index: number }> = ({ exercise, viewMode, index }) => {
  const [isMenuShown, setIsMenuShown] = useState<boolean>(false)

  return (
    <div className="flex flex-col p-6 outline-none hover:outline hover:outline-1 hover:outline-[#604AFF33] rounded-lg gap-3 group print:border-none print:outline-none" contentEditable={true}>
      <div className='flex justify-between items-center border-b border-b-[#E5E0EB] pb-3 print:border-none'>
        <h2 className="text-sm font-semibold mb-4">Exercice {index + 1}</h2>

        {viewMode === 0 && (
          <ExerciseToolbar
            isMenuShown={isMenuShown}
            toggleMenu={() => setIsMenuShown(prevIsMenuShown => !prevIsMenuShown)}
          />
        )}
      </div>

      {isMenuShown && viewMode === 0 && <ExerciseMenu />}

      <Markdown
        remarkPlugins={[remarkMath]}
        rehypePlugins={[[rehypeKatex, { strict: false }], rehypeRaw]}>
        {exercise.question}
      </Markdown>
      {viewMode === 0 ? (
        <div className="flex flex-col gap-3 mt-2 bg-[#B049F00D] p-5 rounded-lg print:bg-[#FFF] print:text-red-500">
          <div
            className='self-start uppercase font-semibold bg-[#B049F00D] text-[#B049F0] text-xs leading-5 px-4 py-0.5 rounded-3xl'
            contentEditable={false}
          >
            Correction de l'exercice {index + 1}
          </div>
          <Markdown
            remarkPlugins={[remarkMath]}
            rehypePlugins={[[rehypeKatex, { strict: false }], rehypeRaw]}>
            {exercise.response}
          </Markdown>
        </div>
      ) : null}
    </div>
  )
}

export default Exercise
