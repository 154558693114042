import { linkToQrcode } from '@/api/marketing.api'
import { Accordion, Icon } from '@ed/ui'
import React, { useEffect, useState } from 'react'
import { createPortal } from 'react-dom'
import { useTranslation } from 'react-i18next'

interface QrCodeModalProps {
  isOpen: boolean
  onClose: () => void
  installLink: string
  connectLink: string
}

const QrCodeModal: React.FC<QrCodeModalProps> = ({ isOpen, onClose, installLink, connectLink }) => {
  const { t } = useTranslation('qrcode-modal')

  const [qrcodeInstall, setQrcodeInstall] = useState<string>()
  const [qrcodeConnect, setQrcodeConnect] = useState<string>()
  const [accordionApp, setAccordionApp] = useState(true)
  const [accordionLogin, setAccordionLogin] = useState(false)

  useEffect(() => {
    if (installLink) linkToQrcode(installLink).then(setQrcodeInstall)
  }, [installLink])
  useEffect(() => {
    if (connectLink) linkToQrcode(connectLink).then(setQrcodeConnect)
  }, [connectLink])

  const onExpandClick = () => {
    setAccordionApp(prev => !prev)
    setAccordionLogin(prev => !prev)
  }

  if (!isOpen) return null
  return createPortal(
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50 text-center">
      <div className="bg-white p-6 rounded-lg shadow-xl max-w-md w-full">
        <div className='text-right'><Icon.Close onClick={onClose} className='inline cursor-pointer' /></div>

        <h2 className="text-xl font-bold mb-4">{t('title')}</h2>
        <Accordion
          title={t('first-accordion.title')}
          subtitle={t('first-accordion.subtitle')}
          className='mb-4'
          open={accordionApp}
          onExpandClick={onExpandClick}
        >
          <div className="p-4">
            <img className='inline-block min-h-[120px]' src={qrcodeInstall} alt="Installer l'application" />
          </div>
        </Accordion>

        <Accordion
          title={t('second-accordion.title')}
          subtitle={t('second-accordion.subtitle')}
          open={accordionLogin}
          onExpandClick={onExpandClick}
        >
          <img className='inline-block min-h-[120px]' src={qrcodeConnect} alt="QR code de connexion" />
        </Accordion>
      </div>
    </div>,
    document.body
  )
}

export default QrCodeModal
