import { type IconProps } from '../../types/icon'

const Edit = (props: IconProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    viewBox="0 0 24 24"
    {...props}
  >
    <path
      fill="none"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M13.8 19.551h6m-15.6 0 4.366-.88a1.2 1.2 0 0 0 .612-.327l9.774-9.78a1.2 1.2 0 0 0-.001-1.697L16.88 4.8a1.2 1.2 0 0 0-1.697 0l-9.775 9.781a1.2 1.2 0 0 0-.327.61L4.2 19.552Z"
    />
  </svg>
)

export default Edit
