import clsx from 'clsx'
import { Icon } from '..'
import type { DetailedHTMLProps, SelectHTMLAttributes } from 'react'
import { forwardRef } from 'react'

const Select = forwardRef<HTMLSelectElement, DetailedHTMLProps<SelectHTMLAttributes<HTMLSelectElement>, HTMLSelectElement>>((props, ref) => (
  <div className="flex items-center relative">
    <select
      {...props}
      ref={ref}
      className={clsx('appearance-none border border-[#B0B0B0] p-3 pr-9 text-sm rounded-lg bg-white w-full', props.className)}
    />
    <Icon.ArrowDownFilled className="absolute right-3 pointer-events-none"/>
  </div>
))

Select.displayName = 'Select'

export default Select
