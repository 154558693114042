import AsideHeader from '@/components/AsideHeader'
import AssetImage from '@/components/AssetImage'
import useSelection from '@/hooks/useSelection'
import type { AssetType } from '@ed/types'
import { type FC } from 'react'
import { useTranslation } from 'react-i18next'

export type ViewEvaluationDocumentsData = {
  evaluationId: number
  assets: Array<AssetType>
}

const EvaluationDocumentsView: FC<{ data: ViewEvaluationDocumentsData }> = ({ data }) => {
  const { t } = useTranslation('evaluation-documents-view')
  const { close } = useSelection()
  const evaluationId = data.evaluationId
  const assets = data.assets

  return (
    <>
      <AsideHeader title={t('title')} onClose={close}/>
      <div className="flex flex-col items-center p-6 space-y-4">
        {assets.map(asset =>
          <AssetImage key={asset.id} base={`evaluations/${evaluationId}`} assetId={asset.id} alt={asset.filename} className='w-full max-w-[700px] border border-[#E5E0EB] bg-cover bg-top bg-no-repeat' />
        )}
      </div>
    </>
  )
}

export default EvaluationDocumentsView