import config, { __DEV__ } from '@/config/config'
import appConfig from '@/config/config.ts'
import { ApolloClient, ApolloLink, HttpLink, InMemoryCache } from '@apollo/client'
import { setContext } from '@apollo/client/link/context'
import { onError } from '@apollo/client/link/error'
import { CookiesEnum } from '@ed/types'
import { Cookies } from 'react-cookie'

const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors) {
    console.log(`[Graphql error]: ${JSON.stringify(graphQLErrors)}`)
    for (const err of graphQLErrors) {
      if (err.message === 'Authentication error') {
        console.log(' --- AUTHENTICATION ERROR --- ')
        window.location.href = `${appConfig.sso_url}?origin=${window.location.href}`
      }
    }
  }

  if (networkError) console.log(`[Network error]: ${networkError}`)
})

const authLink = setContext((_, { headers }) => {
  // get the authentication token from local storage if it exists
  const token = new Cookies().get<string>(CookiesEnum.AUTH) ?? ''

  // return the headers to the context so httpLink can read them
  return {

    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : '',
    }
  }
})

const httpLink = new HttpLink({ uri: `${config.api_url}/graphql` })

const client = new ApolloClient({
  cache: new InMemoryCache(),
  link: ApolloLink.from([errorLink, authLink, httpLink]),
  devtools: {
    enabled: __DEV__,
  },
  connectToDevTools: __DEV__,
})

export default client
