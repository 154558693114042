import { Card } from '@/app/copy/[id]/components'
import AsideHeader from '@/components/AsideHeader'
import useSelection from '@/hooks/useSelection'
import { gql, useMutation } from '@apollo/client'
import { Button, Checkbox, Icon, RadioGroup } from '@ed/ui'
import { type ChangeEvent, type FC, useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { constant, entries, fromKeys, pipe, sumBy } from 'remeda'
import { ExerciseDifficultyEnum, ExerciseTypeEnum } from '@ed/types'

export type CreateRemediationData = {
  evaluationId: number,
  gaps: string[]
}

const EvaluationsStatsCreateRemediation: FC<{ data: CreateRemediationData, onDone?: () => void }> = ({ data, onDone }) => {
  const { t } = useTranslation('evaluation-stats-create-remediation')
  const { close } = useSelection()

  const [selectedExerciseType, setSelectedExerciseType] = useState(ExerciseTypeEnum.PRINT)
  const [selectedDifficulty, setSelectedDifficulty] = useState(ExerciseDifficultyEnum.MEDIUM)
  const [selectedGaps, setSelectedGaps] = useState(fromKeys(data.gaps, constant(true)))

  const [startUserContentGeneration, { loading }] = useMutation(gql`mutation createUserContent($evaluationId: Int!, $difficulty: String!, $type: String!, $skills: [String!]!) {
    client {
      createUserContent(evaluationId: $evaluationId, difficulty: $difficulty, type: $type, skills: $skills) {
        id,
        title,
        createdAt,
        updatedAt
      }
    }
  }`)

  const EXERCISE_TYPES = [
    { id: ExerciseTypeEnum.PRINT, label: t('type.print'), name: ExerciseTypeEnum.PRINT },
    { id: ExerciseTypeEnum.QCM, label: t('type.multiple-choice'), name: ExerciseTypeEnum.QCM }
  ]

  const EXERCISE_DIFFICULTIES = [
    { id: ExerciseDifficultyEnum.EASY, label: t('difficulty.easy'), name: ExerciseDifficultyEnum.EASY },
    { id: ExerciseDifficultyEnum.MEDIUM, label: t('difficulty.medium'), name: ExerciseDifficultyEnum.MEDIUM },
    { id: ExerciseDifficultyEnum.HARD, label: t('difficulty.hard'), name: ExerciseDifficultyEnum.HARD }
  ]

  const onChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    setSelectedGaps(prev => ({ ...prev, [e.target.id]: !(prev[e.target.id] ?? false) }))
  }, [])

  const handleStartContentGeneration = useCallback(async () => {
    await startUserContentGeneration({
      variables: {
        evaluationId: data.evaluationId,
        difficulty: selectedDifficulty,
        type: selectedExerciseType,
        skills: Object.keys(selectedGaps).filter(key => selectedGaps[key])
      }
    })
    onDone?.()
    close()
  }, [close, data.evaluationId, onDone, selectedDifficulty, selectedExerciseType, selectedGaps, startUserContentGeneration])

  return (
    <>
      <AsideHeader title={t('title')} onClose={close} />
      <div className="flex flex-col p-6">
        <p className="font-semibold text-sm text-[#291846] mb-4">{t('select-difficulty')}</p>
        <RadioGroup
          className="mb-7"
          inputs={EXERCISE_DIFFICULTIES.map(difficulty => ({
            ...difficulty,
            checked: difficulty.id === selectedDifficulty,
            onChange: () => setSelectedDifficulty(difficulty.id)
          }))}
        />
        <p className="font-semibold text-sm text-[#291846] mb-4">{t('select-type')}</p>
        <RadioGroup
          className="mb-6"
          inputs={EXERCISE_TYPES.map(exerciseType => ({
            ...exerciseType,
            checked: exerciseType.id === selectedExerciseType,
            onChange: () => setSelectedExerciseType(exerciseType.id)
          }))}
        />
        <p className="font-semibold text-sm text-[#291846] mb-4">{t('prompt')}</p>
        {
          data.gaps.map(gap => (
            <div className="flex mb-5" key={gap}>
              <Checkbox id={gap} labelProps={{ className: 'text-[#291846] text-sm justify-center' }} checked={selectedGaps[gap]} onChange={onChange}>
                {gap}
              </Checkbox>
            </div>
          ))
        }
        <Button
          pending={loading}
          disabled={pipe(selectedGaps, entries(), sumBy(e => e[1] ? 1 : 0)) === 0}
          onClick={handleStartContentGeneration}
          theme="primary"
          className="text-sm mt-3 bg-[#604AFF]">{t('create')}</Button>
        {loading && (
          <Card
            className="m-4 border border-medium-light-grey rounded-lg p-4"
            title={t('long-wait.title')}
            color={'#604AFF'}
            icon={<Icon.ClockForward/>}
          >
            <p className="text-s">{t('long-wait.message')}</p>
          </Card>
        )}
      </div>
    </>
  )
}

export default EvaluationsStatsCreateRemediation