import { StrictMode } from 'react'
import { createRoot } from 'react-dom/client'
import i18n from '@/i18n/init'
import App from '@/App'
import { I18nextProvider } from 'react-i18next'
import { ApolloProvider } from '@apollo/client'
import client from '@/api/apolloClient'
import { CookiesProvider } from 'react-cookie'
import '@/utils/instrument'
import { ModalProvider } from './contexts/ModalProvider'

createRoot(document.getElementById('root')!).render(
  <StrictMode>
    <CookiesProvider>
      <I18nextProvider i18n={i18n}>
        <ApolloProvider client={client}>
          <ModalProvider>
            <App/>
          </ModalProvider>
        </ApolloProvider>
      </I18nextProvider>
    </CookiesProvider>
  </StrictMode>,
)
