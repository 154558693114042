import { getPath, URLS } from '@/utils/url'
import { useQuery } from '@apollo/client'
import { gql } from '@apollo/client'
import type { TypedDocumentNode } from '@apollo/client'
import type { CopyType } from '@ed/types'
import { FRAGMENT_EVALUATION } from '@ed/types'
import type { EvaluationType } from '@ed/types'
import { useEffect, useMemo, useRef, useState } from 'react'
import { Icon } from '@ed/ui'
import { Button, IconButton } from './components'
import { useTranslation } from 'react-i18next'
import clsx from 'clsx'
import { useNavigate } from 'react-router'
type RequiredField<T, K extends keyof T> = Omit<T, K> & Required<Pick<T, K>>
const gqlQuery: TypedDocumentNode<{ client: { evaluations: { hits: RequiredField<EvaluationType, 'id' | 'copies'>[] } } }> = gql`
query ($id:Int!) {
  client {
    evaluations (ids: [$id]) {
      hits {
        ${FRAGMENT_EVALUATION}
      }
    }
  }
}`

const CopyNavigationBar = ({ className, copy, togglePanel }: { className?: string, copy?: CopyType, togglePanel?: () => void }) => {
  const { t } = useTranslation('copy-page')
  const navigate = useNavigate()
  const containerRef = useRef<HTMLDivElement>(null)
  const [isSticky, setIsSticky] = useState(false)
  const buttonVariant = isSticky ? 'outlined' : 'default'
  const { data } = useQuery(gqlQuery, {
    variables: { id: copy?.evaluationId },
    skip: !copy?.evaluationId
  })
  const evaluation = data?.client.evaluations.hits[0]

  const [previousCopy, nextCopy] = useMemo(() => {
    if (!evaluation || !copy) return [null, null]
    const orderedCopies = evaluation.copies
      .map((copy) => ({ value: copy, sortValue: copy.studentName ?? copy.title ?? '' }))
      .sort((a, b) => a.sortValue.localeCompare(b.sortValue))
      .map(({ value }) => value)
    const currentCopyIndex = orderedCopies.findIndex(({ id }) => id === copy.id)
    return [orderedCopies[currentCopyIndex - 1], orderedCopies[currentCopyIndex + 1]]
  }, [evaluation, copy])

  useEffect(() => {
    if (!containerRef.current)
      return
    const intersectionObserver = new IntersectionObserver((e) => {
      setIsSticky(e[0].intersectionRatio !== 1)
    }, { threshold: [1], rootMargin: '-1px 0px 0px 0px' })
    intersectionObserver.observe(containerRef.current)
    return () => intersectionObserver.disconnect()
  }, [containerRef])

  // The size of the navigation bar impacts the top- property of the exercise content.
  return (
    <div ref={containerRef} className={clsx(
      'sticky top-0 grid grid-cols-[1fr_auto_auto_auto_auto] px-6 py-2 gap-2 motion-safe:duration-100 z-10',
      isSticky ? 'bg-white border-b border-zinc-200' : '',
      className
    )}>
      <IconButton icon={<Icon.ArrowLeft />} to={getPath(URLS.EVALUATION, { evaluationId: copy?.evaluationId })} variant={buttonVariant} tooltip={t('navigation-bar.back-to-evaluation')} />
      <Button label={t('navigation-bar.actions')} variant={buttonVariant} />
      <IconButton
        icon={<Icon.ArrowLeft />}
        shape="circle"
        variant={buttonVariant}
        disabled={!previousCopy}
        onClick={() => navigate(getPath(URLS.COPY, { copyId: previousCopy?.id }), { state: { copy: { ...previousCopy, evaluation } } })}
        tooltip={t('navigation-bar.previous-copy', { name: previousCopy?.studentName })} />
      <IconButton
        icon={<Icon.ArrowRight />}
        shape="circle"
        variant={buttonVariant}
        disabled={!nextCopy}
        onClick={() => navigate(getPath(URLS.COPY, { copyId: nextCopy?.id }), { state: { copy: { ...nextCopy, evaluation } } })}
        tooltip={t('navigation-bar.next-copy', { name: nextCopy?.studentName })} />
      <IconButton icon={<Icon.Menu />} shape="circle" variant={buttonVariant} onClick={togglePanel} tooltip={t('navigation-bar.list-of-copies')} />
    </div>
  )
}

export default CopyNavigationBar
