import EvaluationAsideContent from '@/components/evaluation/EvaluationAsideContent'
import EvaluationHeader from '@/components/evaluation/EvaluationHeader'
import EvaluationTabContent from '@/components/evaluation/EvaluationTabContent'
import LogoutButton from '@/components/LogoutButton'
import TabBar, { type Tab } from '@/components/tabs/TabBar'
import { TabProvider } from '@/components/tabs/TabProvider'
import EvaluationIdProvider from '@/contexts/EvaluationIdProvider'
import SelectionProvider from '@/contexts/SelectionProvider'
import { useBreakpoint } from '@/hooks/useBreakpoint'
import type { TypedDocumentNode } from '@apollo/client'
import { gql, useQuery } from '@apollo/client'
import type { EvaluationType } from '@ed/types'
import { Header, Icon } from '@ed/ui'
import clsx from 'clsx'
import React, { useMemo, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router'

const gqlQuery: TypedDocumentNode<{
  client: {
    evaluations: { hits: Array<EvaluationType> }
  }
}> = gql`
  query getEvaluationGradesAndSubjects($id: Int!) {
    client {
      evaluations (ids: [$id]) {
        hits {
          id
          title
          assetIds
          createdAt
          updatedAt
          status
          userId
          copies {
            id
          }
          tags { id name grades { id name } }
        }
      }
    }
  }
`

export type TabId = 'docs' | 'copies' | 'analysis' | 'assistant'

const ViewEvaluation: React.FC = () => {
  const { evaluationId: iEvaluationId } = useParams<{ evaluationId: string }>()
  const evaluationId = useRef(parseInt(iEvaluationId || '0', 10))

  const { t } = useTranslation('evaluation-view')

  const { data } = useQuery(gqlQuery, { variables: { id: evaluationId.current }, skip: evaluationId.current == null })
  const evaluation = data?.client?.evaluations?.hits?.[0]

  const [isDocked, setIsDocked] = useState(false)
  const [isAsideVisible, setIsAsideVisible] = useState(false)
  const [isAsideWide, setIsAsideWide] = useState(false)
  const isLargeScreen = useBreakpoint('lg')

  const tabs: Tab<TabId>[] = useMemo(() => [{
    id: 'docs',
    title: t('tabs.docs.title', { count: evaluation?.assetIds?.length ?? 0 }),
    count: evaluation?.assetIds?.length,
    icon: <Icon.FileAttach />
  }, {
    id: 'copies',
    title: t('tabs.copies.title', { count: evaluation?.copies?.length }),
    count: evaluation?.copies?.length,
    icon: <Icon.Edit2 />
  }, {
    id: 'analysis',
    title: t('tabs.analysis.title'),
    icon: <Icon.FileChart />
  }, {
    id: 'assistant',
    title: t('tabs.assistant.title'),
    icon: <Icon.Stars />
  }], [evaluation, t])

  const toggleAside = () => setIsAsideVisible(!isAsideVisible)
  const openAside = () => setIsAsideVisible(true)
  const closeAside = () => setIsAsideVisible(false)

  const handleScroll = (e: React.UIEvent<HTMLDivElement>) => {
    setIsDocked(e.currentTarget.scrollTop > 0)
  }

  return (
    <EvaluationIdProvider evaluationId={evaluationId}>
      <TabProvider initialActiveTabId="docs">
        <SelectionProvider toggleAside={toggleAside} openAside={openAside} closeAside={closeAside}
          setIsAsideVisible={setIsAsideVisible} setIsAsideWide={setIsAsideWide}>
          <div className="h-screen flex flex-col relative coloredBackground">
            {/* Main Content Area with scrollable container */}
            <div
              className={clsx(
                'flex-1 overflow-hidden flex flex-col w-full',
                isAsideVisible && isLargeScreen && !isAsideWide && 'lg:pr-[392px]' // Push content when aside is visible on large screens
              )}
            >
              {/* Scrollable container that includes both header and content */}
              <div
                className="flex-1 overflow-y-auto"
                onScroll={handleScroll}
              >
                <Header><div className="mr-2"><LogoutButton/></div></Header>
                <EvaluationHeader evaluation={evaluation}/>

                {/* Main Content */}
                <div className="flex-1">
                  <div className="sticky top-2 z-10 bg-white shadow-sm rounded-full mx-6">
                    <TabBar tabs={tabs}/>
                  </div>

                  <div className="m-6 mt-4">
                    <EvaluationTabContent/>
                  </div>
                </div>
              </div>
            </div>

            {/* Aside Panel */}
            <aside
              className={clsx(
                'fixed top-0 right-0 h-screen w-[400px] bg-white shadow-lg z-20 transition-all duration-300 ease-in-out overflow-y-auto rounded-l-2xl',
                isAsideVisible ? 'translate-x-0' : 'translate-x-full',
                isDocked && 'shadow-xl',
                isAsideWide && 'min-w-[400px] w-[50vw]'
              )}
            >
              <EvaluationAsideContent />
            </aside>
          </div>
        </SelectionProvider>
      </TabProvider>
    </EvaluationIdProvider>)
}

export default ViewEvaluation
