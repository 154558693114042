import Exercise from '@/components/exercise'
import Navbar from '@/components/exercise/navbar'
import H5PPlayer from '@/components/h5p/H5pPlayer'
import type { UserContentType } from '@ed/types'
import { Button, Icon } from '@ed/ui'
import clsx from 'clsx'
import isEmpty from 'lodash/fp/isEmpty'
import type { FC } from 'react'
import { useState } from 'react'

const ExerciseBody: FC<{ content: UserContentType }> = ({ content }) => {
  const [viewMode, setViewMode] = useState<0 | 1>(0)

  const exercises = content.exercises
  const h5ps = content.h5ps

  const isH5p = !!h5ps?.at(0)

  return (
    <div className="coloredBackground">
      <Navbar content={content} loading={false} reload={() => {}} />

      <div className={`flex justify-center items-center mb-12 print:hidden ${isH5p ? 'hidden' : ''}`}>
        <Button
          onClick={() => setViewMode(0)}
          theme={viewMode === 0 ? 'primary' : 'tertiary'}
          leftIcon={viewMode === 0 ? <Icon.Check/> : null}
          className={clsx(viewMode === 0 ? 'bg-[#604AFF] border-[#604AFF]' : 'text-[#604AFF]', 'px-4 py-3 rounded-r-none w-[200px] text-sm justify-center')}
        >

          Vue professeur
        </Button>
        <Button
          onClick={() => setViewMode(1)}
          theme={viewMode === 1 ? 'primary' : 'tertiary'}
          leftIcon={viewMode === 1 ? <Icon.Check/> : null}
          className={clsx(viewMode === 1 ? 'bg-[#604AFF] border-[#604AFF]' : 'text-[#604AFF]', 'px-4 py-3 rounded-l-none w-[200px] text-sm justify-center')}
        >
          Vue élève
        </Button>
      </div>

      <div
        className="flex flex-col gap-6 mx-auto p-12 bg-white shadow-md rounded-lg max-w-screen-md border-light-primary border-[1px] border-solid print:border-none print:shadow-none print:p-0">
        <h1 contentEditable={true} suppressContentEditableWarning={true} className="text-xl font-bold text-center">
          {content.title}
        </h1>

        {
          !isEmpty(h5ps) && <H5PPlayer contentId={h5ps?.at(0)?.id || ''} />
        }

        {!isEmpty(exercises) && exercises?.map((exercise, index) => <>
          <Exercise key={exercise.id} index={index} exercise={exercise} viewMode={viewMode}/>
          <div className="px-6">
            <hr/>
          </div>
        </>)}

        <Button
          theme="primary-invert"
          className="text-sm rounded-[32px] mx-auto print:hidden"
          leftIcon={<Icon.Plus/>}
        >
          Ajouter un exercice
        </Button>
      </div>

    </div>
  )
}

export default ExerciseBody
