import CopyHeader from '@/app/copy/[id]/CopyHeader'
import AsideHeader from '@/components/AsideHeader'
import EvaluationCopyError from '@/components/evaluation/EvaluationCopyError'
import EvaluationCopyNotReady from '@/components/evaluation/EvaluationCopyNotReady'
import useEvaluationId from '@/hooks/useEvaluationId'
import useSelection from '@/hooks/useSelection'
import { studentName } from '@/utils/copy'
import { getPath, URLS } from '@/utils/url'
import type { TypedDocumentNode } from '@apollo/client'
import { gql, useQuery } from '@apollo/client'
import {
  type AutoGradingCopyQuestionOutput,
  type AutoGradingEvaluationOutput,
  type CopyType, type EvaluationType,
  StatusEnum
} from '@ed/types'
import type { ColumnType } from '@ed/ui'
import { Button, Table } from '@ed/ui'
import clsx from 'clsx'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router'
import { sumBy } from 'remeda'

const gqlQuery: TypedDocumentNode<{
  client: {
    copies: { hits: Array<CopyType> }
    evaluations: { hits: Array<{ autoGradingOutput: AutoGradingEvaluationOutput }> }
  }
}> = gql`
  query getEvaluationCopyAndEval($id: Int!, $evaluationId: Int!) {
    client {
      copies (ids: [$id]) {
        hits {
          id
          studentName
          status
          autoGradingOutput
        }
      }
      evaluations(ids: [$evaluationId]) {
        hits {
          autoGradingOutput
        }
      }
    }
  }
`

const EvaluationCopy = () => {
  const { t } = useTranslation('evaluation-copy')
  const navigate = useNavigate()

  const { selection, close } = useSelection()
  const copyId = selection.type === 'copy' ? selection.copyId : undefined
  const evaluationId = useEvaluationId()

  const { data } = useQuery(gqlQuery, { variables: { id: copyId, evaluationId: evaluationId.current }, skip: !copyId || evaluationId.current == null })
  const copy = data?.client?.copies?.hits[0] as CopyType
  const evaluation = data?.client?.evaluations?.hits[0] as EvaluationType

  const status = copy?.status ?? StatusEnum.WAITING
  const questions: Array<AutoGradingCopyQuestionOutput & { isTotal?: boolean, score: { value: number, max: number } }> = useMemo(() =>
    copy?.autoGradingOutput?.questions?.flatMap((e: AutoGradingCopyQuestionOutput, ie) =>
      [
        ...(e.children?.map((q, iq) => ({ ...q,
          number: t('exercise-question', { exercise: e.number, question: q.number }),
          score: {
            value: q.score?.value ?? 0,
            // associate max points
            max: evaluation?.autoGradingOutput?.questions?.[ie]?.children?.[iq]?.score?.max ?? 0
          }
        })) ?? []),
        { ...e,
          number: t('total', { name: e.number }),
          isTotal: true,
          score: {
            value: e.children?.length ? sumBy(e.children, q => q.score?.value ?? 0) : e.score?.value ?? 0,
            max: evaluation?.autoGradingOutput?.questions?.[ie]?.score?.max ??
              sumBy(evaluation?.autoGradingOutput?.questions?.[ie]?.children ?? [], q => q.score?.max ?? 0)
          }
        }
      ]) ?? []
  , [copy, evaluation, t])

  const columns = useMemo(() => {
    const result: ColumnType<AutoGradingCopyQuestionOutput & { isTotal?: boolean, score: { value: number, max: number } }>[] = []
    result.push({
      id: 'name',
      header: <p className='text-sm font-semibold'>{t('results')}</p>,
      accessor: (scaleItem) => (
        <p className={clsx('text-[#291846] text-sm', scaleItem.isTotal ? 'font-semibold' : undefined)}>{scaleItem.number}</p>
      )
    })
    if (evaluation?.autoGradingOutput?.global.isScoreBased) {
      result.push({
        id: 'score',
        header: '',
        accessor: (scaleItem) => <p className={clsx('text-[#291846] text-sm', scaleItem.isTotal ? 'font-semibold' : undefined)}>
          {t('numeric-score', { score: scaleItem.score?.value })}
          {scaleItem.score?.max !== undefined && <span className="font-normal text-[#6D717F]">{t('numeric-max', { max: scaleItem.score?.max })}</span>}
        </p>
      })
    }
    return result
  }, [evaluation, t])

  if (!copy) return null

  return (<>
    <AsideHeader title={studentName(copy, t)} onClose={close}>
      <Button
        theme="primary"
        className="text-sm mt-3 bg-[#604AFF]"
        onClick={() => navigate(getPath(URLS.COPY, { copyId }))}>
        {t('view-correction')}
      </Button>
    </AsideHeader>

    {status >= StatusEnum.ERROR && <EvaluationCopyError evaluationId={evaluationId.current ?? 0} copyId={copyId ?? 0} />}
    {status <= StatusEnum.PROCESSING && <EvaluationCopyNotReady />}
    {status === StatusEnum.PROCESSED && (
      <>
        <CopyHeader copy={copy} evaluation={evaluation} disableCols />

        <div className="flex flex-col px-6">
          <Table
            columns={columns}
            data={questions}
            getRowKey={(row) => row.number}
            minimal={false}
            columnStructure='1fr max-content'
          />
        </div>
      </>)}
  </>)
}

export default EvaluationCopy