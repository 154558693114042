import type { Environment } from '@ed/ui'
import { Env } from '@ed/ui'

export const __DEV__ = import.meta.env.DEV ?? false
// @ts-ignore
globalThis.__DEV__ = __DEV__

export const SentryDsn = 'https://16c397b35d22c568eb2e6684e2f2bf02@o4508607683428352.ingest.de.sentry.io/4508608195723344'

type Config = {
  api_url: string;
  sso_url: string;
  front_url: string;
  bot_url: string;
  dev: boolean;
}

const config_local: Config = {
  api_url: 'http://localhost:4000',
  sso_url: 'http://localhost:3001/fr/login',
  front_url: 'http://localhost:3000',
  bot_url: 'http://localhost:3004',
  dev: true
}

const config_staging: Config = {
  api_url: 'https://api.staging.ed.ai',
  sso_url: 'https://sso.staging.ed.ai/fr/login',
  front_url: 'https://app.staging.ed.ai',
  bot_url: 'https://chat.staging.ed.ai',
  dev: false
}

const config_preview: Config = {
  ...config_staging,
  dev: true
}

const config_production: Config = {
  api_url: 'https://api.ed.ai',
  sso_url: 'https://sso.ed.ai/fr/login',
  front_url: 'https://app.ed.ai',
  bot_url: 'https://chat.ed.ai',
  dev: false
}

const config = (env: Environment): Config => {
  switch (env) {
    case 'local':
      return config_local
    case 'preview':
      return config_preview
    case 'staging':
      return config_staging
    case 'production':
      return config_production
    default:
      return config_local
  }
}

export default config(Env.CONF_ENV)
