import clsx from 'clsx'
import {
  type PropsWithChildren,
  type DetailedHTMLProps,
  type InputHTMLAttributes,
  type LabelHTMLAttributes
} from 'react'

type CheckboxProps = PropsWithChildren<
  DetailedHTMLProps<InputHTMLAttributes<HTMLInputElement>, HTMLInputElement> & {
    labelProps?: DetailedHTMLProps<LabelHTMLAttributes<HTMLLabelElement>, HTMLLabelElement>
  }
>

const Checkbox = ({ labelProps = {}, children, ...props }: CheckboxProps) => {
  return (
    <label
      {...labelProps}
      className={clsx(
        props.disabled ? 'cursor-not-allowed' : 'cursor-pointer',
        'flex gap-3 items-center',
        labelProps.className
      )}
      htmlFor={props.id}
    >
      <span className="relative w-5 h-5">
        <input
          {...props}
          type="checkbox"
          className={clsx(
            'visually-hidden peer',
            props.className
          )}
        />
        <span
          className={clsx(
            'block w-5 h-5 rounded-sm border border-[#B0B0B0] bg-white',
            'peer-checked:bg-[#604AFF] peer-checked:border-[#604AFF]',
            'peer-disabled:bg-light-grey peer-disabled:border-light-grey',
            'transition-colors duration-200'
          )}
        />
        <span className="pointer-events-none absolute inset-0 flex items-center justify-center text-white scale-0 peer-checked:scale-100">
          <svg
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
            className="w-4 h-4"
          >
            <path d="M5 13l4 4L19 7" />
          </svg>
        </span>
      </span>

      {children}
    </label>
  )
}

export default Checkbox
