'use client'

import React, { useMemo } from 'react'
import { type TagType } from '@ed/types'

import type { MultiValue } from 'react-select'
import Select from 'react-select'

type TagOption = { value: string; label: string }

type TagSelectProps = {
  /** An array of tag objects. Each tag should provide fields like `id`, `name`, `subjectId` and `gradeId`. */
  tags: TagType[];
  value?: MultiValue<TagOption>;
  /** Callback triggered when a tag is selected or cleared. Receives the selected tag code or `undefined` if cleared. */
  onSelectTags: (tagIds: MultiValue<TagOption>) => void;
  /** Is the select disabled ? */
  disabled: boolean;
  locales?: string | string[]
}

/**
 * The **TagSelect** component provides a user interface for selecting a list of tags. It leverages `react-select` for the dropdowns and manages the state of selected tags.
 *
 * **Key features:**
 * - Dropdown selection of tags.
 * - Multi selection enabled
 * - Clearable selections to reset the component’s state.
 *
 * Note: This component only manages selections and does not handle data fetching internally. You must perform data fetching (e.g., via fetch or axios) in the parent component and pass the resulting tags down as props.
 *
 */
const TagSelect: React.FC<TagSelectProps> = ({ tags, onSelectTags, value, disabled, locales = 'fr' }) => {

  const handleTagChange = (
    newValues: MultiValue<TagOption>
  ) => {
    onSelectTags(newValues)
  }

  const tagOptions: TagOption[] = useMemo(
    () => tags.map((tag) => ({
      value: String(tag.id),
      label: tag.name ?? '',
    })),
    [tags])

  const sortedTagOptions = useMemo(
    () => tagOptions.sort((a, b) => a.label.localeCompare(b.label, locales, { ignorePunctuation: true })),
    [locales, tagOptions])

  return (
    <div>
      <div className="mb-4">
        <Select<TagOption, true>
          id="tag-select"
          name="tag"
          placeholder='Sélectionnez...'
          value={value}
          options={sortedTagOptions}
          onChange={handleTagChange}
          isClearable
          isMulti={true}
          isDisabled={disabled}
        />
      </div>
    </div>
  )
}

export default TagSelect
