import { getBackendUrl } from './url'
import { Cookies } from 'react-cookie'
import { CookiesEnum } from '@ed/types'

export const getAssetThumbnailUrl = (
  base: string,
  assetId: string,
  options: { width?: number, height?: number, quality?: number, pageNumber?: number } = {}): string => {
  const token = new Cookies().get(CookiesEnum.AUTH) ?? ''

  const queryParameters: Record<string, string | number | boolean> = {}
  if (options.width != null)
    queryParameters.width = options.width
  if (options.height != null)
    queryParameters.height = options.height
  if (options.quality != null)
    queryParameters.quality = options.quality
  base = base.endsWith('/') ? base.substring(0, base.length - 1) : base

  const pagePath = options.pageNumber !== undefined ? `/pages/${options.pageNumber}` : ''

  return getBackendUrl(`${base}/assets/${assetId}${pagePath}/thumbnail`, token, queryParameters)
}

export const getAssetDownloadUrl = (base: string, id: string, token?: string): string => {
  base = base.endsWith('/') ? base.substring(0, base.length - 1) : base
  return getBackendUrl(`${base}/${id}/raw`, token)
}
