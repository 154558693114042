import config from '@/config/config'
import { getPath, URLS } from '@/utils/url'
import { CookiesEnum } from '@ed/types'
import { Button } from '@ed/ui'
import { useCallback } from 'react'
import { useCookies } from 'react-cookie'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router'
import { useLongPress } from 'use-long-press'

const LogoutButton = () => {
  const { t } = useTranslation('shared')
  const [, , removeCookie] = useCookies([CookiesEnum.AUTH])
  const navigate = useNavigate()

  const handleLogout = useCallback(async () => {
    removeCookie(CookiesEnum.AUTH)
    navigate(getPath(URLS.HOME))
  }, [navigate, removeCookie])

  const bind = useLongPress(() => {
    navigate(getPath(URLS.DEV))
  })

  return (
    <Button theme="tertiary" className="text-sm" onClick={handleLogout} {...(config.dev ? bind() : {})}>
      {t('logout')}
    </Button>
  )
}

export default LogoutButton
