
import { type IconProps } from '../../types/icon'

const Dice = (props: IconProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={20}
    height={20}
    viewBox="0 0 20 20"
    {...props}
  >
    <rect
      width={18}
      height={18}
      x={1}
      y={1}
      fill="none"
      stroke="currentColor"
      strokeWidth={1.5}
      rx={2}
    />
    <circle
      cx={5.098}
      cy={5.48}
      r={1.57}
      stroke="none"
      fill="currentColor"/>
    <circle cx={14.902}
      cy={5.48}
      r={1.57}
      stroke="none"
      fill="currentColor"/>
    <circle
      cx={10}
      cy={10.039}
      r={1.57}
      stroke="none"
      fill="currentColor"/>
    <circle
      cx={5.098}
      cy={14.596}
      r={1.57}
      stroke="none"
      fill="currentColor"/>
    <circle
      cx={14.902}
      cy={14.596}
      r={1.57}
      stroke="none"
      fill="currentColor"/>
  </svg>
)

export default Dice
