import { type IconProps } from '../../types/icon'

const Eye = (props: IconProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    viewBox="0 0 24 24"
    {...props}
  >
    <path
      fill="none"
      stroke="currentColor"
      strokeWidth={1.8}
      d="M14.4 12.029c0 1.29-1.074 2.335-2.4 2.335-1.325 0-2.4-1.045-2.4-2.335s1.075-2.335 2.4-2.335c1.326 0 2.4 1.045 2.4 2.335Z"
    />
  </svg>
)

export default Eye
