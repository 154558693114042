import clsx from 'clsx'
import ExerciseNumber from './ExerciseNumber'

const ExerciseBoundaryTopSvg = ({ className }: { className?: string }) => {
  return <svg width="18" height="15" viewBox="0 0 18 15" fill="currentColor" stroke="currentColor" xmlns="http://www.w3.org/2000/svg" className={className}>
    <path d="M10.1698 13.8144L10.1701 13.8144L10.1695 13.8043C10.1591 13.6259 10.151 13.4457 10.1428 13.264C10.1136 12.6189 10.0836 11.9556 9.94961 11.2961L9.75732 10.071L9.7555 10.0593L9.75231 10.048L9.41458 8.84346L9.41463 8.84344L9.4133 8.83907C8.92791 7.24859 8.26757 5.61464 7.18307 4.25576L7.1832 4.25565L7.1779 4.24954C5.61169 2.44214 3.65397 1.11836 1.57336 0.329024L1.57336 0.329013L1.57134 0.328273C1.24546 0.208628 0.974747 0.399319 0.863805 0.630129C0.753599 0.859407 0.770503 1.18713 1.04865 1.38157C2.71453 2.59495 4.09167 4.12781 5.01649 5.87917L5.0229 5.89131L5.03091 5.90247C6.64138 8.14434 7.38223 11.0124 7.47813 13.8957C7.52151 17.5979 7.51283 21.3427 7.50415 25.0909C7.49835 27.5918 7.49256 30.0943 7.50221 32.5867V112.508V112.708H7.70221H9.99397H10.194V112.508V32.5863C10.194 31.0242 10.1955 29.4652 10.197 27.9064C10.2015 23.2308 10.206 18.5577 10.1698 13.8144Z" strokeWidth="0.4"/>
  </svg>
}

type ExerciseBoundaryProps = {
  number: string,
  isSelected?: boolean,
  onSelect?: () => void
}

/**
 * Display the boundary around an exercise on the copy image view.
 */
const ExerciseBoundary = ({ number, isSelected, onSelect }: ExerciseBoundaryProps) => {
  return <div className='relative w-full h-full cursor-pointer' onClick={(event) => {
    event.stopPropagation()
    onSelect?.()
  }}>
    <div className={clsx('absolute right-0 h-full motion-safe:duration-500',
      isSelected ? 'translate-x-[calc(100%+4px)]' : 'translate-x-full')}>
      <div className={clsx('grid grid-rows-[auto_minmax(0,1fr)_auto] h-full w-[24px] justify-items-center motion-safe:duration-500',
        isSelected ? '' : 'opacity-0 scale-x-0 -translate-x-1/2')}>
        <div>
          <ExerciseBoundaryTopSvg className="text-[#4f46e5]" />
        </div>
        <div className="bg-[#4f46e5] w-[3px]" />
        <div className='-scale-y-100'>
          <ExerciseBoundaryTopSvg className="text-[#4f46e5] self-end" />
        </div>
      </div>
      {/* This box simulated the margin on the exercise number - while playing nicely with the transition */}
      <div className={clsx('absolute top-0 left-0 w-full h-full motion-safe:duration-500',
        isSelected ? 'translate-y-1/2' : '')}>
        <div className={clsx('motion-safe:duration-500 bg-slate-50 h-10',
          isSelected ? '-translate-y-1/2' : '')} />
      </div>
      <div className={clsx('absolute top-0 left-0 w-full h-full motion-safe:motion-safe:duration-500',
        isSelected ? 'translate-y-1/2 translate-x-1/2' : '')}>
        <div className={clsx('motion-safe:motion-safe:duration-500 -translate-x-1/2',
          isSelected ? '-translate-y-1/2' : '')}>
          <ExerciseNumber number={number} size="small" variant={isSelected ? 'default' : 'outline'} />
        </div>
      </div>
    </div>
  </div>
}

export default ExerciseBoundary
