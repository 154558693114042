import { type IconProps } from '../../types/icon'

const Pdf = (props: IconProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={34}
    height={34}
    viewBox="-1 0 33 34"
    {...props}
  >
    <path
      fill="currentColor"
      stroke="none"
      d="M3.88962 33.9063C2.5591 33.9063 1.53604 33.3633 0.90674 32.6432C-0.477718 31.061 -0.256564 28.595 1.43176 26.7791C2.66159 25.4575 6.09936 24.0407 10.0765 22.8289C10.9135 20.5742 11.8377 17.7936 12.8463 14.4906C11.8359 11.969 11.1562 9.09036 10.998 5.85037C10.8263 2.30112 12.7133 0.273878 14.6677 0.104867C16.2194 -0.0308821 18.0524 1.06949 18.3905 3.79795C18.5343 4.95766 18.4147 6.20907 18.0336 7.51442L17.5427 9.2333C17.0546 10.954 16.5601 12.6603 16.063 14.3225C17.1705 16.7633 18.6188 18.7897 20.1453 20.4061C22.4575 19.9925 24.4039 19.7723 25.7047 19.7615C29.5228 19.7831 31.0016 21.4947 31.5419 22.5124C32.2099 23.7674 32.1434 25.2714 31.3684 26.4347C30.5503 27.6619 29.1092 28.2993 27.5135 28.1563C25.5816 27.9765 22.311 26.4869 19.1861 23.6389C16.9889 24.0803 14.6021 24.6583 12.3645 25.2903C10.9656 28.7703 9.56859 31.3235 8.20391 32.2899C6.55515 33.4577 5.09608 33.9045 3.88872 33.9045L3.88962 33.9063ZM8.59857 26.4626C6.16409 27.3014 4.28698 28.1482 3.64509 28.8387C2.98343 29.5498 2.88903 30.3202 3.18121 30.6529C3.50485 31.0232 4.64568 31.1086 6.45716 29.8258C7.0532 29.4032 7.7688 28.2804 8.59857 26.4635V26.4626ZM23.077 22.9817C24.9388 24.3203 26.6712 25.0422 27.7958 25.1474C28.2813 25.195 28.657 25.0548 28.853 24.7599C29.0157 24.5145 29.0238 24.2142 28.8746 23.9328C28.7335 23.6667 28.0853 22.7983 25.6885 22.7848C24.9774 22.7848 24.0964 22.8522 23.077 22.9817ZM14.8125 18.3752C14.4286 19.5727 14.0438 20.727 13.659 21.8274C14.6974 21.5604 15.7393 21.3114 16.7588 21.0866C16.0746 20.2568 15.4202 19.3533 14.8125 18.3752ZM14.9302 3.11741H14.9275C14.6246 3.14348 13.9323 3.92471 14.0186 5.70473C14.0771 6.90669 14.2164 8.05292 14.425 9.1443C14.4942 8.89977 14.5643 8.65614 14.6345 8.40802L15.1316 6.67026C15.3959 5.76137 15.4831 4.9208 15.3905 4.17104C15.2907 3.36913 15.003 3.11831 14.9293 3.11831L14.9302 3.11741Z"/>
  </svg>
)

export default Pdf
