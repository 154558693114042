import { getAssetThumbnailUrl } from '@/utils/asset'
import clsx from 'clsx'
import { useEffect, useRef, useState } from 'react'
import { useDevicePixelRatio } from 'use-device-pixel-ratio'

type AssetImageProps = {
  base: string,
  assetId: string,
  alt: string,
  pageNumber?: number,
  className?: string,
  onClick?: () => void
}
const AssetImage = ({ base, assetId, alt, pageNumber, className, onClick }: AssetImageProps) => {
  const ref = useRef<HTMLImageElement>(null)
  const dpr = useDevicePixelRatio()
  const [src, setSrc] = useState<string>()
  const [isLoading, setIsLoading] = useState(true)

  useEffect(() => {
    if (!ref.current || !base || !assetId)
      return
    const resizeObserver = new ResizeObserver((e) => {
      let width = dpr * e[0].contentRect.width
      width = Math.max(64, Math.pow(2, Math.ceil(Math.log2(width))))
      const newSrc = getAssetThumbnailUrl(base, assetId, { width, pageNumber })
      setSrc(newSrc)
    })
    resizeObserver.observe(ref.current)
    return () => resizeObserver.disconnect()
  }, [ref, base, assetId, dpr, pageNumber, src])

  return (
    <div className={clsx('relative', className, isLoading && 'aspect-[210/297]')}>
      {isLoading && <div className='animate-pulse absolute inset-0 bg-gray-100' />}
      <img ref={ref} src={src} alt={alt} className={className} onClick={onClick} onLoad={() => setIsLoading(false)} />
    </div>
  )
}

export default AssetImage
