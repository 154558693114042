import type { FC, PropsWithChildren, ReactNode } from 'react'

const Success: FC<PropsWithChildren<{ title: string, description?: string, image?: ReactNode }>> = ({
  title,
  description,
  image,
  children
}) => {
  return (
    <div className="bg-white dark:bg-dark-card border dark:border-dark-border rounded-lg mx-auto max-w-2xl flex flex-col items-center shadow-md dark:shadow-none gap-8 p-8 dark:text-white h-fit mt-24">
      {image}
      <h1 className="text-2xl text-center">{title}</h1>
      {!!description && <div className="opacity-80 text-center" >{description}</div>}
      {children}
    </div>
  )
}

export default Success
