import { type SetStateAction, type Dispatch, useState } from 'react'
import type {
  DragEndEvent,
  DragStartEvent } from '@dnd-kit/core'
import {
  DndContext,
  closestCenter,
  PointerSensor,
  useSensor,
  useSensors,
  DragOverlay
} from '@dnd-kit/core'
import {
  arrayMove,
  SortableContext,
  useSortable,
  rectSortingStrategy,
} from '@dnd-kit/sortable'
import { CSS } from '@dnd-kit/utilities'
import clsx from 'clsx'
import { Icon, IconButton } from '@ed/ui'
import type { EntityWithUniqueIdentifier } from '@/types/drag-and-drop'

export type FileBoxProps = {
  files: EntityWithUniqueIdentifier<File>[];
  setFiles: Dispatch<SetStateAction<EntityWithUniqueIdentifier<File>[]>>;
  removeFile: (id: EntityWithUniqueIdentifier<File>['id']) => void;
}

type FileProps = {
  file: EntityWithUniqueIdentifier<File>;
  removeFile: FileBoxProps['removeFile'];
  isActive?: boolean;
}

const File = ({ file, removeFile, isActive }: FileProps) => {
  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
  } = useSortable({ id: file.id })

  const style = {
    transform: CSS.Translate.toString(transform),
    transition,
  }

  return (
    <li
      className={clsx('relative cursor-move rounded-lg', isActive && 'shadow-lg outline outline-primary rotate-3')}
      ref={setNodeRef} style={style} {...attributes} {...listeners}
    >
      <img
        src={URL.createObjectURL(file.entity)}
        className='border border-solid border-[#DDDDDD] rounded-lg'
        width={500}
        height={500}
        alt={file.entity.name}
      />
      <IconButton
        className='absolute top-3 right-3 w-6 h-6 [&]:bg-grey-old [&]:border-grey-old'
        onClick={() => removeFile(file.id)}
      >
        <Icon.Close className='size-5' />
      </IconButton>
    </li>
  )
}

const FileBox = ({ files, setFiles, removeFile }: FileBoxProps) => {
  const [activeFile, setActiveFile] = useState<EntityWithUniqueIdentifier<File> | null>(null)

  const sensors = useSensors(
    useSensor(PointerSensor, {
      activationConstraint: { distance: 10 }
    })
  )

  const handleDragEnd = (event: DragEndEvent) => {
    const { active, over } = event

    if (active.id !== over?.id) {
      setFiles((prevFiles: EntityWithUniqueIdentifier<File>[]) => {
        const oldIndex = prevFiles.findIndex(file => file.id === active.id)
        const newIndex = prevFiles.findIndex(file => file.id === over?.id)

        if (oldIndex !== -1 && newIndex !== -1) {
          return arrayMove(prevFiles, oldIndex, newIndex)
        }

        return arrayMove(prevFiles, oldIndex, newIndex)
      })
    }

    setActiveFile(null)
  }

  const handleDragStart = (event: DragStartEvent) => {
    const activeFile = files.find(file => file.id === event.active.id) || null
    setActiveFile(activeFile)
  }

  if (files.length === 0) return <div/>
  const arrFiles = Array.from(files)

  return (
    <DndContext
      sensors={sensors}
      collisionDetection={closestCenter}
      onDragEnd={handleDragEnd}
      onDragStart={handleDragStart}
    >
      <ul className='grid grid-cols-2 gap-4'>
        <SortableContext
          items={files}
          strategy={rectSortingStrategy}
        >
          {arrFiles.map(file => <File key={file.id} file={file} removeFile={removeFile} />)}
        </SortableContext>

        <DragOverlay>
          {activeFile ? (
            <File file={activeFile} removeFile={removeFile} isActive />
          ) : null}
        </DragOverlay>
      </ul>
    </DndContext>
  )
}

export default FileBox
