import { type IconProps } from '../../types/icon'

const Camera = (props: IconProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    viewBox="0 0 40 40"
    {...props}
  >
    <g id="Group">
      <g id="Group 7838">
        <path
          stroke="none"
          d="M20.4129 27.3357C23.3704 27.3357 25.7679 24.9492 25.7679 22.0052C25.7679 19.0613 23.3704 16.6748 20.4129 16.6748C17.4554 16.6748 15.0579 19.0613 15.0579 22.0052C15.0579 24.9492 17.4554 27.3357 20.4129 27.3357Z"
          fill="currentColor"/>
        <path
          stroke="none"
          d="M15.3906 5.3335L12.3244 8.66392H7.01302C5.16978 8.66392 3.66724 10.1683 3.66724 11.9944V31.9944C3.66724 33.8292 5.17852 35.3248 7.01302 35.3248H33.8055C35.6488 35.3248 37.1513 33.8292 37.1513 31.9944V11.9944C37.1513 10.1596 35.6488 8.66392 33.8055 8.66392H28.4942L25.428 5.3335H15.3819H15.3906ZM20.4136 30.3335C15.7924 30.3335 12.0448 26.603 12.0448 22.003C12.0448 17.403 15.7924 13.6726 20.4136 13.6726C25.0348 13.6726 28.7825 17.403 28.7825 22.003C28.7825 26.603 25.0348 30.3335 20.4136 30.3335Z"
          fill="currentColor"/>
      </g>
    </g>
  </svg>
)

export default Camera
