import { studentName } from '@/utils/copy'
import type { CopyType, EvaluationType } from '@ed/types'
import { AutoGradingCompetencyLevel } from '@ed/types'
import { Icon } from '@ed/ui'
import clsx from 'clsx'
import type { FC } from 'react'
import { useTranslation } from 'react-i18next'
import Comment from './Comment'
import { Card } from './components'

const NotAutogradedHeader = () => {
  const { t } = useTranslation('copy-page')
  return <div className='text-center text-gray-500'>
    {t('header.not-autograded')}
  </div>
}

const CompetencyBadge = ({ levelName, standardLevel }: { levelName: string, standardLevel: AutoGradingCompetencyLevel }) => {
  const standardLevelToColor: Record<AutoGradingCompetencyLevel, string> = {
    [AutoGradingCompetencyLevel.NOT_ACQUIRED]: '#e11d48',
    [AutoGradingCompetencyLevel.IN_PROGRESS]: '#d97706',
    [AutoGradingCompetencyLevel.ACQUIRED]: '#16a34a',
    [AutoGradingCompetencyLevel.ADVANCED]: '#10b981'
  }

  return <div className='rounded-full px-3 py-2 min-w-[200px]' style={{ backgroundColor: standardLevelToColor[standardLevel] + '10' }}>
    <div className='flex flex-row gap-2 items-center'>
      <div className='rounded-full w-3 h-3' style={{ backgroundColor: standardLevelToColor[standardLevel] }} />
      <span className='text-sm' style={{ color: standardLevelToColor[standardLevel] }}>{levelName}</span>
    </div>
  </div>
}

const CopyHeader: FC<{
  className?: string,
  copy: CopyType,
  evaluation: EvaluationType,
  updateCopy?: (copy: Pick<CopyType, 'autoGradingOutput'>) => void,
  disableCols?: boolean
}> = ({ className, copy, evaluation, updateCopy, disableCols = false }) => {
  const { t } = useTranslation('copy-page')
  const autoGradingOutput = copy.autoGradingOutput
  const evaluationAutoGradingOutput = evaluation.autoGradingOutput
  const competencyShortNames = evaluationAutoGradingOutput?.global.competencies ? Object.fromEntries(evaluationAutoGradingOutput.global.competencies.map((competency) => [competency.name, competency.shortName])) : null
  const competencyLevelNames = evaluationAutoGradingOutput?.global.competencyLevels ? Object.fromEntries(evaluationAutoGradingOutput.global.competencyLevels.map((competency) => [competency.standardLevel, competency.name])) : null

  const nameOrAnonymous = studentName(copy, t)
  return <div className={clsx('flex flex-col items-center ', className)}>
    {!disableCols && <div className='text-3xl font-semibold text-[#1e1b4b] font-figtree'>
      {nameOrAnonymous}
    </div>}
    {copy.evaluation?.title && <div className='text-gray-500 text-sm mt-3'>
      {copy.evaluation?.title}
    </div>}
    <div className={clsx('bg-white rounded-[20px] w-full px-8 py-6', disableCols ? '' : 'mt-8')}>
      {autoGradingOutput && evaluationAutoGradingOutput ?
        <div className={clsx('grid gap-x-12 gap-y-6', disableCols ? '' : 'md:grid-cols-[1fr_auto_1fr]')}>
          <div className='flex flex-col gap-6'>
            <Card className='col-start-1 flex flex-col' title={t('header.result')} icon={<Icon.CopyResult />} color='#4f46e5'>
              <div className='flex flex-col gap-4'>
                {/* Score */}
                {autoGradingOutput.global.score && <div>
                  <span className='m-1 text-4xl text-[#1e1b4b] font-bold'>{t('header.score', { value: autoGradingOutput.global.score?.value })}</span>
                  <span className='text-lg text-gray-500'>{t('header.maxScore', { max: evaluationAutoGradingOutput.global.score?.max })}</span>
                </div>}
                {/* Competencies */}
                {autoGradingOutput.global.competencies &&
                <div className='w-full rounded-lg border border-[#e4e4e7] grid grid-cols-[1fr_auto] items-center'>
                  {autoGradingOutput.global.competencies.map((competency) =>
                    <div key={competency.name} className='grid grid-cols-subgrid col-span-2 items-center border-b last:border-b-0 border-[#e4e4e7] px-6 py-2'>
                      <div className='text-sm text-[#1e1b4b]'>{competencyShortNames![competency.name]}</div>
                      <div><CompetencyBadge levelName={competencyLevelNames![competency.result]} standardLevel={competency.result} /></div>
                    </div>)}
                </div>}
              </div>
            </Card>
            {/* Comment */}
            <Comment
              comment={autoGradingOutput.global.comment ?? ''}
              updateComment={updateCopy ? (comment) => {
                updateCopy({ autoGradingOutput: { ...autoGradingOutput, global: { ...autoGradingOutput.global, comment } } })
              } : undefined} />
          </div>
          {disableCols || <div className={'w-[1px] h-full bg-gray-200 max-md:hidden'} />}
          <div className='flex flex-col gap-6'>
            {/* Strengths */}
            <Card title={t('header.strengths')} icon={<Icon.CopyStrength />} color='#10b981'>
              <ul className='text-sm'>
                {autoGradingOutput.global.strengths.map(strength => <li key={strength}> • {strength}</li>)}
              </ul>
            </Card>
            {/* Gaps */}
            <Card title={t('header.gaps')} icon={<Icon.CopyGap />} color='#e11d48'>
              <ul className='text-sm'>
                {autoGradingOutput.global.weaknesses.map(gap => <li key={gap}> • {gap}</li>)}
              </ul>
            </Card>
          </div>
        </div>
        : <NotAutogradedHeader />}
    </div>
  </div>
}

export default CopyHeader
