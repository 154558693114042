import { type IconProps } from '../../types/icon'

const Settings = (props: IconProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={25}
    height={24}
    viewBox="0 0 25 24"
    {...props}
  >
    <path
      fill="none"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
      d="M10.5 18h11m-18 0h3m0 0v2m0-2v-2m14-4h1m-18 0h13m0 0v2m0-2v-2m-2-4h7m-18 0h7m0 0v2m0-2V4"
    />
  </svg>
)

export default Settings
