import type { ViewEvaluationDocumentsData } from '@/components/evaluation/EvaluationDocumentsView'
import type { CopyListData } from '@/components/evaluation/EvaluationsStatsCopyList'
import type { CreateRemediationData } from '@/components/evaluation/EvaluationsStatsCreateRemediation'
import type { ViewEvaluationScoreDetailsData } from '@/components/EvaluationScoreDetailsView'
import { useTabContext } from '@/components/tabs/TabContext'
import { SelectionContext } from '@/hooks/useSelection'
import { EvaluationPanelActionEnum } from '@/utils/evaluation'
import type { FC, PropsWithChildren } from 'react'
import { type Dispatch, type SetStateAction, useCallback, useEffect, useReducer, } from 'react'

export type Action = {
  type: EvaluationPanelActionEnum.COPY
  copyId?: number
} | {
  type: EvaluationPanelActionEnum.CREATE_REMEDIATION
  data: CreateRemediationData
  onDone?: () => void
} | {
  type: EvaluationPanelActionEnum.COPY_LIST
  data: CopyListData
} | {
  type: EvaluationPanelActionEnum.EDIT_RUBRIC
} | {
  type: EvaluationPanelActionEnum.ADD_COPY
  refetch: () => void
} | {
  type: EvaluationPanelActionEnum.SCORE_DETAILS
  data: ViewEvaluationScoreDetailsData
} | {
  type: EvaluationPanelActionEnum.CLOSE
} | {
  type: EvaluationPanelActionEnum.VIEW_EVALUATION_DOCUMENTS
  data: ViewEvaluationDocumentsData
}

const wide: Partial<Record<EvaluationPanelActionEnum, boolean>> = {
  [EvaluationPanelActionEnum.VIEW_EVALUATION_DOCUMENTS]: true,
  [EvaluationPanelActionEnum.SCORE_DETAILS]: true
}

const reducer = (state: Action, action: Action): Action => {
  switch (action.type) {
    case EvaluationPanelActionEnum.COPY:
      return state.type == EvaluationPanelActionEnum.COPY && state.copyId === action.copyId ? { type: EvaluationPanelActionEnum.CLOSE } : action
    case EvaluationPanelActionEnum.CREATE_REMEDIATION:
    case EvaluationPanelActionEnum.COPY_LIST:
    case EvaluationPanelActionEnum.EDIT_RUBRIC:
    case EvaluationPanelActionEnum.ADD_COPY:
    case EvaluationPanelActionEnum.SCORE_DETAILS:
    case EvaluationPanelActionEnum.CLOSE:
    case EvaluationPanelActionEnum.VIEW_EVALUATION_DOCUMENTS:
      return action
  }
}

const SelectionProvider: FC<PropsWithChildren<{ toggleAside: () => void,
  openAside: () => void,
  closeAside: () => void,
  setIsAsideVisible: Dispatch<SetStateAction<boolean>>,
  setIsAsideWide: Dispatch<SetStateAction<boolean>> }>> = ({ children, openAside, closeAside, setIsAsideWide }) => {
  const { activeTabId } = useTabContext()

  const [selection, dispatch] = useReducer(reducer, { type: EvaluationPanelActionEnum.CLOSE })

  useEffect(() => {
    dispatch({ type: EvaluationPanelActionEnum.CLOSE })
  }, [activeTabId])

  const close = useCallback(() => dispatch({ type: EvaluationPanelActionEnum.CLOSE }), [])

  useEffect(() => {
    if (selection.type !== EvaluationPanelActionEnum.CLOSE) {
      openAside()
      setIsAsideWide(wide[selection.type] ?? false)
    } else {
      setIsAsideWide(false)
      closeAside()
    }
  }, [closeAside, selection, openAside, setIsAsideWide])

  return (
    <SelectionContext.Provider value={{ selection, dispatch, close }}>
      {children}
    </SelectionContext.Provider>
  )
}

export default SelectionProvider
