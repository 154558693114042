import type { TypedDocumentNode } from '@apollo/client'
import { gql, useQuery } from '@apollo/client'
import { InternalServerError } from '@ed/types'
import { Button, Separator } from '@ed/ui'
import { useCookies } from 'react-cookie'

const query: TypedDocumentNode<{ client: { me: { id: number, firstname: string, lastname: string, email: string  } } }> = gql`query getMe { client { me { id firstname lastname email } } }`

const Developer = () => {
  const { data } = useQuery(query)

  const [cookies, setCookie] = useCookies(['enableHidden'])

  return (
    <div>
      <h1>Developer</h1>
      <Button onClick={() => {
        throw new InternalServerError('Intentional error')
      }}>trigger error</Button>
      <p>User id {data?.client.me.id} email {data?.client.me.email}</p>

      <Separator title="Hidden screens" />

      <div>
        <input
          id="enableHidden"
          type="checkbox"
          onChange={(e) => {
            setCookie('enableHidden', e.target.checked, { path: '/' })
          }}
          checked={cookies.enableHidden}
        />
        <label htmlFor="enableHidden">Enable hidden features</label>
      </div>

    </div>
  )
}

export default Developer
