import { forwardRef } from 'react'
import clsx from 'clsx'
import type { DetailedHTMLProps, InputHTMLAttributes } from 'react'

type InputProps = DetailedHTMLProps<InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>

const Input = forwardRef<HTMLInputElement, InputProps>(({ className, ...props }, ref) => (
  <input
    ref={ref}
    type="text"
    {...props}
    className={clsx(
      'border border-[#B0B0B0] p-3 text-sm rounded-lg bg-white placeholder:font-normal placeholder:text-sm',
      className
    )}
  />
))

Input.displayName = 'Input'

export default Input
