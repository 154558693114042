import { type IconProps } from '../../types/icon'

const ArrowDownFilled = (props: IconProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={16}
    height={17}
    viewBox="0 0 16 17"
    {...props}
  >
    <path
      fill="currentColor"
      stroke="none"
      d="M7.589 12.284a.499.499 0 0 0 .822 0l4.5-6.5A.5.5 0 0 0 12.5 5h-9a.501.501 0 0 0-.411.785l4.5 6.5Z"
    />
  </svg>
)

export default ArrowDownFilled

