import { type IconProps } from '../../types/icon'

const Check = (props: IconProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={20}
    height={20}
    viewBox="0 0 20 20"
    {...props}
  >
    <path
      d="M3.33325 5.14706H16.6666M8.33325 13.9706V8.67647M11.6666 13.9706V8.67647M13.3333 17.5H6.66659C5.74611 17.5 4.99992 16.7099 4.99992 15.7353V6.02941C4.99992 5.5421 5.37301 5.14706 5.83325 5.14706H14.1666C14.6268 5.14706 14.9999 5.5421 14.9999 6.02941V15.7353C14.9999 16.7099 14.2537 17.5 13.3333 17.5ZM8.33325 5.14706H11.6666C12.1268 5.14706 12.4999 4.75202 12.4999 4.26471V3.38235C12.4999 2.89504 12.1268 2.5 11.6666 2.5H8.33325C7.87301 2.5 7.49992 2.89504 7.49992 3.38235V4.26471C7.49992 4.75202 7.87301 5.14706 8.33325 5.14706Z"
      stroke="currentColor"
      strokeWidth="1.4"
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"/>
  </svg>
)

export default Check
