import config from '@/config/config'

export const linkToQrcode = async (link: string): Promise<string> => {
  const myHeaders = new Headers()
  myHeaders.append('Content-Type', 'application/json')

  const response = await fetch(`${config.api_url}/marketing/link-to-qrcode`, {
    method: 'POST',
    headers: myHeaders,
    body: JSON.stringify({ link })
  })
  const data = await response.json() as { dataUrl: string }
  return data.dataUrl
}