import type { Config } from 'tailwindcss'
import screens from './tailwind.screens'
import sharedConfig from '@ed/ui/tailwind.config'

const config: Config = {
  content: [
    './src/pages/**/*.{js,ts,jsx,tsx}',
    './src/components/**/*.{js,ts,jsx,tsx}',
    './src/app/**/*.{js,ts,jsx,tsx}',
    './src/utils/**/*.{js,ts,jsx,tsx}',
    '../../packages/ui/src/**/*.{js,ts,jsx,tsx}' // Include classNames generated by the UI lib
  ],
  theme: {
    extend: {
      colors: {
        'light-primary': '#BFC0DF',
        'sandbox-banner': 'rgba(255, 194, 72, 0.12)'
      },
      fontSize: {
        's': ['13px', { lineHeight: 'calc(1.15 / 0.8125)' }]
      },
      keyframes: {
        sparkleOne: {
          '0%, 100%': {
            transform: 'scale(1) rotate(0deg)',
            opacity: '1'
          },
          '50%': {
            transform: 'scale(1.3) rotate(15deg)',
            opacity: '0.6'
          }
        },
        sparkleTwo: {
          '0%, 100%': {
            transform: 'scale(1) rotate(0deg)',
            opacity: '1'
          },
          '50%': {
            transform: 'scale(1.3) rotate(-15deg)',
            opacity: '0.6'
          }
        },
        sparkleThree: {
          '0%, 100%': {
            transform: 'scale(1) rotate(0deg)',
            opacity: '1'
          },
          '50%': {
            transform: 'scale(1.3) rotate(10deg)',
            opacity: '0.6'
          }
        }
      },
      animation: {
        sparkleOne: 'sparkleOne 1.5s infinite ease-in-out',
        sparkleTwo: 'sparkleTwo 1.7s infinite ease-in-out',
        sparkleThree: 'sparkleThree 1.4s infinite ease-in-out'
      }
    },
    screens,
  },
  presets: [
    sharedConfig
  ]
}
export default config
