import type { FC } from 'react'
import { useEffect } from 'react'
import config from '@/config/config'

const H5PPlayer: FC<{ contentId: string }> = ({ contentId }) => {
  useEffect(() => {
    const initH5P = () => {
      const el = document.getElementById('h5p-container')
      const options = {
        h5pJsonPath: `${config.api_url}/h5p/${contentId}`,
        librariesPath: `${config.api_url}/h5p/libraries`,
        frameJs: `${config.api_url}/h5p/standalone/frame.bundle.js`,
        frameCss: `${config.api_url}/h5p/standalone/styles/h5p.css`,
        frame: true,
        copyright: false,
        icon: false,
        fullScreen: true,
        export: false,
        embed: true,
        embedCode: `<iframe width=":w" height=":h" src="${window.location.origin}/h5p/view/${contentId}" frameBorder="0" scrolling="no" style="width:100%"></iframe>`,
      }

      // @ts-ignore
      if (window.H5PStandalone && window.H5PStandalone.H5P) {
      // @ts-ignore
        new window.H5PStandalone.H5P(el, options)
      } else {
        console.error('Le script H5PStandalone n\'est pas chargé correctement.')
      }
    }

    const scriptSrc = `${config.api_url}/h5p/standalone/main.bundle.js`
    if (!document.querySelector(`script[src="${scriptSrc}"]`)) {
      const script = document.createElement('script')
      script.src = scriptSrc
      script.async = true
      script.onload = () => {
        initH5P()
      }
      document.body.appendChild(script)
    }
  }, [contentId])

  return <div id="h5p-container"></div>
}

export default H5PPlayer
