import { type DetailedHTMLProps } from 'react'
import clsx from 'clsx'

export type LinkOverlayProps<T extends React.ElementType> = {
  /** Defines the HTML element or React component to render. */
  as?: T;
  /** Additional CSS classes to customize the link styles. */
  className?: string;
  /** For React-Navigation, also accepts a string to use as the `href` attribute. */
  to?: string;
} & DetailedHTMLProps<React.AnchorHTMLAttributes<HTMLAnchorElement>, HTMLAnchorElement>

type LinkBoxProps<T extends React.ElementType> = {
  /** Defines the HTML element or React component to render. */
  as?: T;
  /** Additional CSS classes to customize the link styles. */
  className?: string;
} & React.ComponentPropsWithoutRef<T>

/**
 * A customizable `LinkOverlay` component. It ensures that the entire parent container is clickable when wrapped in a `LinkBox`.
 *
 * The `LinkOverlay` is designed to be used alongside the `LinkBox` component. Together, they create fully clickable containers where the `LinkOverlay` makes part of the content within the `LinkBox` interactable.
 */
export const LinkOverlay = <T extends React.ElementType = 'a'>({ as, className, ...props }: LinkOverlayProps<T>) => {
  const Component = as || 'a'

  return (
    <Component {...props} className={clsx('before:content-[\'\'] before:absolute before:top-0 before:left-0 before:w-full before:h-full', className)} />
  )
}

/**
 * A customizable `LinkBox` component that acts as a container for `LinkOverlay`, making it easier to create fully clickable areas.
 *
 * - The `LinkOverlay` must be used inside a `LinkBox` for proper functionality.
 * - Ensure that the `LinkBox` has a `relative` class applied for the `LinkOverlay` to work correctly.
 */
export const LinkBox = <T extends React.ElementType = 'div'>({ as, className, ...props }: LinkBoxProps<T>) => {
  const Component = as || 'div'

  return (
    <Component {...props} className={clsx('relative z-[1]', className)} />
  )
}
