import { type IconProps } from '../../types/icon'

const Plus = (props: IconProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={18}
    height={18}
    viewBox="0 0 18 18"
    {...props}
  >
    <path
      fill="none"
      stroke="currentColor"
      strokeLinecap="round"
      strokeWidth={1.8}
      d="M9 3.6v10.8M14.4 9H3.6"
    />
  </svg>
)

export default Plus
