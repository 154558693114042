import type { Config } from 'tailwindcss'
import customPlugins from './tailwind-plugin'
import * as defaultTheme from 'tailwindcss/defaultTheme'
import tailwindTypography from '@tailwindcss/typography'

const config: Config = {
  darkMode: 'selector',
  content: ['./src/**/*.{js,ts,jsx,tsx}'],
  theme: {
    extend: {
      backgroundImage: {
        'gradient-radial': 'radial-gradient(var(--tw-gradient-stops))',
        'gradient-conic':
            'conic-gradient(from 180deg at 50% 50%, var(--tw-gradient-stops))',
      },
      data: {
        active: 'ui~="active"'
      },
      fontFamily: {
        sans: ['Inter', ...defaultTheme.fontFamily.sans],
        figtree: ['Figtree'],
        inter: ['Inter'],
      },
      colors: {
        'grey-old': '#656779',
        'medium-grey': '#6D717F',
        'medium-darker-grey': '#8F929E',
        'medium-light-grey': '#DBD6E1',
        'light-grey': '#F5F3F7',
        'primary': '#8244EA',
        'secondary': '#F8B330',
        'dark-primary': '#291846',
        'dark-background': '#1C1B28',
        'dark-card': '#262336',
        'dark-border': '#38334E',
        'primary-darker-20': '#6018d9',
        'error': '#C91F2E',
        'light-error': '#FFABB0',
        'lighter-error': 'green',
        'typo-80': '#112130CC',
        /* https://www.figma.com/design/junpmaPWpkvgKkPbZxNqin/Design-System?node-id=24-697&m=dev */
        'purple': {
          DEFAULT: '#604AFF',
          'hover': 'rgb(84, 56, 250)', /* echo ".toto { background: color-mix(in hsl, #604AFF 90%, #291846) }" | npx -y @csstools/csstools-cli postcss-color-mix-function -p '{ "preserve": true }' */
          'pressed': 'rgb(74, 39, 243)',
        },
        'black': '#291846',
        'white': '#FFF',
        'grey': {
          '50': '#F9FAFC',
          '100': '#E1E0EB',
          '200': '#CECDDA',
          '300': '#686D84',
        },
        'cyan': '#02B3FF',
        'pink': '#CB66E2',
        'green': {
          '1': '#14BC7E',
          '2': '#60C754',
        },
        'orange': '#E46E07',
        'red': '#E0245E',
      },
      fontSize: {
        '2-5xl': '1.75rem',
        '3-5xl': '2rem',
        's': '0.8125rem'
      },
      lineHeight: {
        '5-5': '5.5',
        '6-5': '6.5',
        '7-5': '7.5',
        '10-5': '10.5',
      },
      fontWeight: {
        'bold': '600',
      },
      screens: {
        'xs': '390px'
      },
    },
  },
  plugins: [customPlugins, tailwindTypography],
}

export default config
