import useEvaluationId from '@/hooks/useEvaluationId'
import useSelection from '@/hooks/useSelection'
import type { TypedDocumentNode } from '@apollo/client'
import { gql, useQuery } from '@apollo/client'
import { type AutoGradingEvaluationOutput, type CopyType, type UserContentType } from '@ed/types'
import type { FC } from 'react'
import { useCallback, useMemo } from 'react'
import EvaluationStatsHeader from '@/components/evaluation/EvaluationStatsHeader'
import ClassGapsSection from '@/components/evaluation/EvaluationStatsClassGap'
import EvaluationsStatsRemediation from '@/components/evaluation/EvaluationsStatsRemediation'
import { useTranslation } from 'react-i18next'
import {
  clamp,
  countBy,
  entries,
  filter, first,
  flatMap,
  identity,
  isNonNullish, last,
  map,
  pipe,
  sortBy,
  sumBy, take,
} from 'remeda'
import { EvaluationPanelActionEnum } from '@/utils/evaluation'
export interface DistributionItem {
  scoreRange: string
  count: number
}

export interface ClassGap {
  id: number
  title: string
  description: string
  copiesPercentage: number
  copiesCount: number
}

export interface EvaluationStatsType {
  average: number
  min: number
  max: number
  evaluationMax: number
  correctedCopies: number
  copiesWithScore: number
  distribution: DistributionItem[]
  classGaps: ClassGap[]
}

const gqlQuery: TypedDocumentNode<{
  client: {
    evaluations: { hits: Array<{ copies: Array<CopyType>, autoGradingOutput: AutoGradingEvaluationOutput, contents: UserContentType[] }> }
  }
}> = gql`
  query getEvaluationCopies($id: Int!) {
    client {
      evaluations (ids: [$id]) {
        hits {
          title
          copies {
            id
            studentName
            status
            autoGradingOutput
          }
          autoGradingOutput
          contents {
            id
            title
            createdAt
            exercises {
              createdAt
              difficulty
              question
              response
            }
          }
        }
      }
    }
  }
`

const EvaluationStats: FC = () => {
  const { t } = useTranslation('evaluation-stats')

  const evaluationId = useEvaluationId()
  const { dispatch } = useSelection()

  const { data, loading, refetch } = useQuery(gqlQuery, { variables: { id: evaluationId.current }, skip: evaluationId.current == null })
  const evaluation = useMemo(() => data?.client?.evaluations?.hits?.[0], [data])
  const copies = useMemo(() => evaluation?.copies ?? [], [evaluation])

  const stats: EvaluationStatsType | undefined = useMemo(() => {
    const correctedCopies = copies?.filter(c => c.autoGradingOutput?.global?.score?.value != null || c.autoGradingOutput?.global?.competencies != null)
    const sortedCopies = sortBy(correctedCopies, c => c.autoGradingOutput?.global?.score?.value ?? 0)
    const evaluationMax = evaluation?.autoGradingOutput?.global?.score?.max ?? 20
    const distribution = Array(evaluationMax + 1).fill(undefined).map((_, i) => ({ scoreRange: String(i), count: 0 }))
    return correctedCopies.length > 0 ? ({
      average: sumBy(correctedCopies, c => c.autoGradingOutput?.global?.score?.value ?? 0) / correctedCopies.length,
      correctedCopies: correctedCopies.length,
      copiesWithScore: sumBy(correctedCopies, c => c.autoGradingOutput?.global?.score?.value != null ? 1 : 0),
      distribution: correctedCopies.reduce((acc, c) => {
        const bucket = Math.floor(clamp(c.autoGradingOutput?.global?.score?.value ?? -1, { min: 0, max: evaluationMax }))
        acc[bucket].count += 1
        return acc
      }, distribution),
      classGaps: pipe(copies,
        flatMap(c => c.autoGradingOutput?.global?.weaknesses),
        filter(isNonNullish),
        countBy(identity()),
        entries(),
        sortBy([e => e[1], 'desc']),
        take(3),
        map(([title, copiesCount], id) => ({
          title,
          copiesCount,
          copiesPercentage: Math.round(copiesCount / correctedCopies.length * 100),
          id,
          description: ''
        }))
      ),
      min: first(sortedCopies)?.autoGradingOutput?.global?.score?.value ?? 0,
      max: last(sortedCopies)?.autoGradingOutput?.global?.score?.value ?? 0,
      evaluationMax
    }) : undefined
  }, [copies, evaluation])

  const remediationData = useMemo(
    () => sortBy(data?.client?.evaluations?.hits?.[0]?.contents ?? [], [c => c.createdAt, 'desc']),
    [data])

  const onCreateRemediation = useCallback(() => {
    dispatch({ type: EvaluationPanelActionEnum.CREATE_REMEDIATION, data: {
      evaluationId: evaluationId.current ?? 0,
      gaps: stats?.classGaps.map(g => g.title) ?? []
    }, onDone: refetch })
  }, [dispatch, evaluationId, stats?.classGaps, refetch])

  const onViewCopies = useCallback((gapId: string) =>
    dispatch({ type: EvaluationPanelActionEnum.COPY_LIST, data: copies?.filter(c => c.autoGradingOutput?.global?.weaknesses.includes(gapId)).map(c => ({ studentName: c.studentName ?? 'anonyme', copyId: c.id ?? 0 })) }), [copies, dispatch])

  if (loading) {
    return (
      <div className="p-4 text-center">
        <p>{t('loading')}</p>
      </div>
    )
  }

  if (!stats) {
    return (
      <div className="p-4 text-center">
        <p>{t('empty')}</p>
      </div>
    )
  }

  return (
    <div className="w-full h-full bg-white rounded-2xl">
      {stats.copiesWithScore > 0 && <EvaluationStatsHeader stats={stats} onDetailClick={() => dispatch({ type: EvaluationPanelActionEnum.SCORE_DETAILS, data: {
        evaluation: evaluation!,
        copies
      } })}/>}

      <ClassGapsSection
        classGaps={stats.classGaps}
        onCreateRemediation={onCreateRemediation}
        onViewCopies={onViewCopies}
      />

      {<EvaluationsStatsRemediation data={remediationData} />}
    </div>
  )
}

export default EvaluationStats
