import { useTabContext } from '@/components/tabs/TabContext'
import type { TabId } from '@/app/evaluation/[id]/ViewEvaluation'
import EvaluationDocuments from '@/components/evaluation/EvaluationDocuments'
import EvaluationStats from '@/components/evaluation/EvaluationStats'
import { EvaluationAssistant } from '@/components/evaluation/EvaluationAssistant'
import EvaluationCopies from '@/components/evaluation/EvaluationCopies'

const EvaluationTabContent = () => {
  const { activeTabId } = useTabContext<TabId>()

  switch (activeTabId) {
    case 'docs':
      return <EvaluationDocuments/>
    case 'copies':
      return <EvaluationCopies />
    case 'analysis':
      return <EvaluationStats />
    case 'assistant':
      return <EvaluationAssistant />
  }
}

export default EvaluationTabContent