import { useModal } from '@/hooks/useModal'
import { getPath, URLS } from '@/utils/url'
import { gql, useMutation } from '@apollo/client'
import React, { useCallback, useMemo } from 'react'
import type { EvaluationType } from '@ed/types'
import { formatEvaluationDate } from '@/utils/evaluation.ts'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router'
import { filter, flatMap, isNonNullish, pipe, uniqueBy } from 'remeda'
import { Icon, IconButton, MoreActionsMenu, type MoreActionsMenuItem } from '@ed/ui'

interface EvaluationHeaderProps {
  evaluation?: EvaluationType;
  isDocked?: boolean;
  isLoading?: boolean;
}

export const EvaluationHeader: React.FC<EvaluationHeaderProps> = ({
  evaluation,
  isLoading = false
}) => {
  const { t } = useTranslation('evaluation-page')
  const navigate = useNavigate()
  const { showModal } = useModal()
  const [deleteEvaluation] = useMutation(
    gql`mutation deleteEvaluation($id: Int!) { client { deleteEvaluation(evaluation: { id: $id }) { id }}}`,
    { variables: { id: evaluation?.id }
    })

  const handleDelete = useCallback(async () => {
    const response = await showModal({
      title: t('actions.delete.title'),
      message: t('actions.delete.description'),
      positive: { onClick: deleteEvaluation },
    })
    if (response?.button === 'positive') {
      navigate(getPath(URLS.EVALUATIONS))
    }
  }, [deleteEvaluation, navigate, showModal, t])

  const menuItems: MoreActionsMenuItem[] = useMemo(() => [
    {
      label: 'Exporter les corrections',
      icon: <Icon.Download className="size-4" />
    },
    {
      label: 'Exporter les résultats',
      icon: <Icon.Download className="size-4" />
    },
    {
      label: 'Exporter l’analyse',
      icon: <Icon.Download className="size-4" />
    },
    {
      label: 'Partager l’évaluation',
      dividerBefore: true,
      icon: <Icon.Share className="size-4" />
    },
    {
      label: t('actions.edit.title'),
      dividerBefore: true,
      onClick: () => navigate(getPath(URLS.EVALUATION_EDIT, { evaluationId: evaluation?.id })),
      icon: <Icon.Edit className="size-4" />
    },
    {
      label: 'Dupliquer l\'évaluation',
      icon: <Icon.Duplicate className="size-4" />
    },
    {
      onClick: handleDelete,
      label: t('actions.delete.title'),
      icon: <Icon.Delete className="size-4" />
    },
  ], [evaluation?.id, handleDelete, navigate, t])

  if (isLoading) {
    return (
      <div className="transition-shadow duration-300">
        <div className="container mx-auto px-4 py-3">
          <div className="flex flex-col gap-2">
            <div className="h-6 bg-gray-200 rounded animate-pulse w-48"></div>
            <div className="h-8 bg-gray-200 rounded animate-pulse w-3/4"></div>
            <div className="flex gap-4 items-center">
              <div className="h-5 bg-gray-200 rounded animate-pulse w-24"></div>
              <div className="h-5 bg-gray-200 rounded animate-pulse w-24"></div>
            </div>
          </div>
        </div>
      </div>
    )
  }

  if (!evaluation) {
    return null
  }

  const evaluationDate = formatEvaluationDate(evaluation)
  const grades = pipe(
    evaluation.tags ?? [],
    flatMap(tag => tag.grades),
    filter(isNonNullish),
    uniqueBy(grade => grade.id)
  )

  return (
    <div className="relative flex justify-center items-center transition-shadow duration-300 h-48">
      <IconButton className="absolute left-6 top-4 !rounded-full !bg-white" theme="secondary" onClick={() => navigate(getPath(URLS.EVALUATIONS))}><Icon.ArrowLeft/></IconButton>
      <MoreActionsMenu items={menuItems} className="absolute right-6 top-4 size-12 !rounded-full !bg-white" iconClassName="m-[10px] !size-5" menuClassName="rounded-xl" menuWidth={220}/>
      <div className="flex flex-col px-4 py-3 gap-3">
        {/* Evaluation title */}
        <h1 className="text-3-5xl font-semibold text-gray-900 text-center font-figtree">
          {evaluation.title}
        </h1>

        {/* Evaluation metadata */}
        <div className="text-center text-[#6b7280]">
          {grades.map(g => (
            <span key={g.id}><span>{g.name}</span><span className="mx-2">-</span></span>
          ))}
          <span>{evaluationDate}</span>
        </div>

        <div className="flex flex-row justify-center gap-3">
          {evaluation.tags?.map(tag => (
            <div key={tag.id} className="px-4 py-2 text-[#291846] bg-[#2918460D] text-sm rounded">
              <span>{tag.name}</span>
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}

export default EvaluationHeader
