import { type IconProps } from '../../types/icon'

const Edit2 = (props: IconProps) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 20 20"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <path
      d="M10.9471 16.036L11.5656 15.3427C12.3246 14.4921 13.6863 14.6026 14.2981 15.5646C14.868 16.4606 16.1062 16.6305 16.8964 15.921L18.0176 14.9144M2.98239 16.2252L6.62071 15.4921C6.81386 15.4531 6.99121 15.358 7.13049 15.2187L15.2752 7.06947C15.6657 6.67876 15.6655 6.04543 15.2746 5.65505L13.5493 3.93165C13.1586 3.54143 12.5256 3.5417 12.1353 3.93225L3.98969 12.0823C3.85068 12.2214 3.75576 12.3984 3.71681 12.5911L2.98239 16.2252Z"
      strokeWidth="1.2"
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="currentColor"
    />
  </svg>
)

export default Edit2
