import { type IconProps } from '../../types/icon'

const Check = (props: IconProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={20}
    height={20}
    viewBox="0 0 20 20"
    {...props}
  >
    <path
      d="M3.33325 10.9375L3.33325 5.83333C3.33325 3.99238 4.82564 2.5 6.66659 2.5L11.7708 2.5M8.33325 17.5L13.9583 17.5C14.9938 17.5 15.8333 16.6605 15.8333 15.625L15.8333 7.5C15.8333 6.46447 14.9938 5.625 13.9583 5.625L8.33325 5.625C7.29772 5.625 6.45825 6.46447 6.45825 7.5L6.45825 15.625C6.45825 16.6605 7.29772 17.5 8.33325 17.5Z"
      stroke="currentColor"
      strokeWidth="1.4"
      strokeLinecap="round"
      fill="none"/>
  </svg>
)

export default Check
