import type { FC } from 'react'
import { Icon, IconButton } from '..'

type Props = {
  name: string,
  type: 'image' | 'pdf',
  progress: number,
  size: string,
  onCancel?: () => void
  error?: string
}

const FileUpload: FC<Props> = ({ name, type, size, progress, onCancel, error }) => {
  return (<div
    className="rounded-lg flex flex-col gap-2 p-2 bg-white dark:bg-dark-card shadow dark:shadow-none dark:border dark:border-dark-border">
    <div className="flex flex-row items-center gap-4 w-full">
      <div className="flex size-14 bg-grey rounded items-center justify-center flex-shrink-0">{type === 'image' ?
        <Icon.Image className="fill-white"/> : <Icon.Pdf className="fill-white"/>}</div>
      <div className="flex flex-col w-[calc(100%-136px)]">
        <div className=" dark:text-white flex-shrink truncate">{name}</div>
        {error ?
          <div className="text-error">{error}</div> :
          <div className="text-medium-darker-grey">{progress === 1 ? size : `${Math.round(progress * 100)}%`}</div>
        }
      </div>
      {!!onCancel && <IconButton className="size-12 flex-shrink-0" theme="secondary" onClick={onCancel}><Icon.Close
        className="size-8"/></IconButton>}
    </div>
    <div className="h-2 bg-grey rounded-lg">
      <div className="h-full bg-primary rounded-lg" style={{ width: `${progress * 100}%` }}/>
    </div>
  </div>)
}

export default FileUpload
