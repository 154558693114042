import { type ReactNode, type ButtonHTMLAttributes, type DetailedHTMLProps, type ComponentPropsWithoutRef, } from 'react'
import { clsx } from 'clsx'

type BaseProps = {
  /** Defines the button theme to adjust styles. */
  theme?: 'primary' | 'primary-invert' | 'secondary' | 'tertiary'
  /** Additional CSS classes to customize the button. */
  className?: string
  /** Trigger pending animation */
  pending?: boolean;
}

type ButtonProps = BaseProps & DetailedHTMLProps<ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement> & {
  /** Icon to be displayed on the left side of the button text. */
  leftIcon?: ReactNode;
  /** Icon to be displayed on the right side of the button text. */
  rightIcon?: ReactNode;
}

type IconButtonProps = BaseProps & DetailedHTMLProps<ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement>

type LinkButtonProps<T extends React.ElementType> = BaseProps & ComponentPropsWithoutRef<T> & {
  /** Determines which element to use (`<a>` by default). */
  as?: T;
  /** Icon to be displayed on the left side of the button text. */
  leftIcon?: ReactNode;
  /** Icon to be displayed on the right side of the button text. */
  rightIcon?: ReactNode;
}

const LoadingDots = () => (
  <span className="inline-flex gap-1">
    <span className="animate-bounce">.</span>
    <span className="animate-bounce delay-150">.</span>
    <span className="animate-bounce delay-300">.</span>
  </span>
)

const baseClassName = 'py-3 px-6 rounded-xl text-lg transition-transform transition-colors border ' +
  'disabled:bg-[#B4BCD3] disabled:cursor-not-allowed ' +
  'focus:outline-primary focus:shadow-outline ' +
  'dark:text-dark-primary dark:disabled:bg-white/50 dark:disabled:text-dark-primary'
const buttonBaseClassName = 'hover:enabled:scale-[1.02]'

const getClassNameByTheme = (theme: BaseProps['theme']) => {
  switch (theme) {
    case 'primary':
    default:
      return 'bg-[#291846] hover:bg-[#291846]-darker-20 text-white dark:text-white fill-white dark:bg-primary/80 border-transparent'
    case 'primary-invert':
      return 'bg-transparent text-[#291846] fill-[#291846] dark:text-white dark:fill-white'
    case 'secondary':
      return 'bg-[#f2ecfd] hover:bg-[#f2ecfd] disabled:text-primary/50 border-transparent dark:disabled:bg-white/30'
    case 'tertiary':
      return 'border-[#11213033] disabled:text-dark-primary/80 disabled:bg-[#B4BCD3]/50 dark:text-white dark:border-white dark:disabled:bg-white/10 dark:disabled:bg-white/10 dark:disabled:text-white/80'
  }
}

/**
 * A customizable `Button` component based on CSS classes.
 */
export const Button = ({ className,
  theme = 'primary', children, leftIcon, rightIcon, pending, ...props }: ButtonProps) => (
  <button
    {...props}
    disabled={pending || props.disabled}
    className={clsx(
      baseClassName,
      buttonBaseClassName,
      getClassNameByTheme(theme),
      'flex gap-2 items-center justify-center',
      className
    )}
  >
    {
      pending ? <LoadingDots /> : <>
        {leftIcon}
        {children}
        {rightIcon}
      </>}
  </button>
)

/**
 * An `IconButton` component for buttons with icons, based on the `Button` component.
 */
export const IconButton = ({ className, theme = 'primary', ...props }: IconButtonProps) => (
  <button
    {...props}
    className={clsx(
      baseClassName,
      buttonBaseClassName,
      'inline-flex items-center justify-center w-12 h-12 rounded-full [&]:px-0 [&]:py-0',
      getClassNameByTheme(theme),
      className
    )}
  />
)

export const LinkButton = <T extends React.ElementType = 'a'>({ className, as, theme = 'primary', children, leftIcon, rightIcon, ...props }: LinkButtonProps<T>) => {
  const Component = as || 'a'

  return (
    <Component
      {...props}
      className={clsx(
        baseClassName,
        'hover:scale-[1.02]',
        getClassNameByTheme(theme),
        'flex gap-2 items-center justify-center',
        className
      )}
    >
      {leftIcon}
      {children}
      {rightIcon}
    </Component>
  )
}
