import { useRef, useState } from 'react'
import { Button, Icon, Screen } from '@ed/ui'
import { useTranslation } from 'react-i18next'
import Upload from '@/app/evaluation/Upload'
import NotFound from '@/components/NotFound'
import { getPath, URLS } from '@/utils/url'
import { useNavigate } from 'react-router'
import { deleteEvaluation } from '@/api/evaluation'
import EvaluationIdProvider from '@/contexts/EvaluationIdProvider'
import useEvaluationId from '@/hooks/useEvaluationId'
import EditEvaluation from '@/app/evaluation/EditEvaluation'
import { useModal } from '@/hooks/useModal'

type Page = 'importMode' | 'upload' | 'review' | 'success'

const Success = () => {
  const evaluationId = useEvaluationId()
  const { t } = useTranslation('create-evaluation')
  const navigate = useNavigate()

  const onReturn = () => navigate(getPath(URLS.EVALUATIONS), { replace: true })
  const onAddCopies = () => navigate(getPath(URLS.EVALUATION, { evaluationId: evaluationId.current }))
  return (
    <Screen.Success title={t('success.title')} description={t('success.description')} image={
      <Icon.DocumentCreated/>}>
      <div className="grid w-full gap-8 grid-flow-col justify-stretch">
        <Button theme="tertiary" onClick={onReturn}>{t('success.return')}</Button>
        <Button theme="secondary" onClick={onAddCopies}>{t('success.add-copy')}</Button>
      </div>
    </Screen.Success>)
}

const NewEvaluation = () => {
  const [page, setPage] = useState<Page>('upload')
  const { t } = useTranslation(['create-evaluation', 'evaluation-edit'])
  const evaluationId = useRef<number>()
  const navigate = useNavigate()
  const { showModal } = useModal()

  const onQuit = async () => {
    if (evaluationId.current != null) {
      await deleteEvaluation({ evaluation: { id: evaluationId.current } })
    }
    navigate(getPath(URLS.EVALUATIONS), { replace: true })
  }

  return (
    <EvaluationIdProvider evaluationId={evaluationId}>
      {page !== 'review' && <div className="dark h-screen relative">
        <div
          className="flex h-full dark:bg-dark-background dark:text-white overflow-y-auto coloredBackground dark:bg-none">
          {page !== 'success' &&
            <div className='absolute right-2 h-12 text-white text-sm text-right font-["Inter"] flex items-center justify-end pr-8'>
              <button onClick={() => showModal({
                title: t('evaluation-edit:quit.creation.dialog-title'),
                message: t('evaluation-edit:quit.creation.dialog-message'),
                positive: { onClick: onQuit } })}>
                {t('quit')}
                <Icon.Close className='inline-block size-6 ml-4' />
              </button>
            </div>
          }

          {page === 'importMode' && <NotFound/>}
          {page === 'upload' && <Upload next={() => setPage('review')}/>}
          {page === 'success' && <Success/>}
        </div>
      </div>
      }
      {page === 'review' && <EditEvaluation onQuit={onQuit} next={() => setPage('success')}/>}
    </EvaluationIdProvider>
  )
}

export default NewEvaluation
