import { type IconProps } from '../../types/icon'

const Trash = (props: IconProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={18}
    height={18}
    viewBox="0 0 12 12"
    {...props}
  >
    <path
      fill="none"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M2 3.088h8M5 8.382V5.206m2 3.176V5.206M8 10.5H4c-.552 0-1-.474-1-1.059V3.618c0-.293.224-.53.5-.53h5c.276 0 .5.237.5.53V9.44c0 .585-.448 1.059-1 1.059ZM5 3.088h2c.276 0 .5-.237.5-.53V2.03c0-.292-.224-.529-.5-.529H5c-.276 0-.5.237-.5.53v.529c0 .292.224.53.5.53Z"
    />
  </svg>
)

export default Trash
