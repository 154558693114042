import React, { useState, useRef, useEffect } from 'react'
import { createPortal } from 'react-dom'
import { Icon } from '@ed/ui'
import clsx from 'clsx'

export type MoreActionsMenuItem = {
  /** Label of a menu item */
  label: string
  /** onClick action on menu item */
  onClick?: () => void
  /** is this menu item disabled */
  disabled?: boolean
  /** is there a divider before this menu item */
  dividerBefore?: boolean
  /** Icon component associated to this menu item */
  icon?: React.ReactNode
}

type MoreActionsMenuProps = {
  /** list of menu items */
  items: MoreActionsMenuItem[]
  /** custom class names for wrapper */
  className?: string
  /** custom class names for trigger Icon */
  triggerClassName?: string
  /** custom class names for portable menu */
  menuClassName?: string
  iconClassName?: string
  /** width of the menu */
  menuWidth?: number | string
}

const MoreActionsMenu: React.FC<MoreActionsMenuProps> = ({
  items,
  className,
  triggerClassName,
  menuClassName,
  iconClassName,
  menuWidth = '150px' // Default width is 150px
}) => {
  const [open, setOpen] = useState(false)
  const [menuStyles, setMenuStyles] = useState<{ top: number; left: number }>({ top: 0, left: 0 })

  const triggerRef = useRef<HTMLButtonElement>(null)
  const menuRef = useRef<HTMLDivElement>(null)

  const toggleMenu = () => setOpen((prev) => !prev)

  useEffect(() => {
    const handleClickOutside = (e: MouseEvent) => {
      if (
        triggerRef.current &&
        !triggerRef.current.contains(e.target as Node) &&
        menuRef.current &&
        !menuRef.current.contains(e.target as Node)
      ) {
        setOpen(false)
      }
    }

    if (open) {
      document.addEventListener('mousedown', handleClickOutside)
    } else {
      document.removeEventListener('mousedown', handleClickOutside)
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [open])

  useEffect(() => {
    if (open && triggerRef.current) {
      const rect = triggerRef.current.getBoundingClientRect()
      const top = rect.bottom + window.scrollY + 4
      const left =
        rect.right + window.scrollX - (typeof menuWidth === 'number' ? menuWidth : parseInt(menuWidth || '150', 10))
      setMenuStyles({ top, left })
    }
  }, [menuWidth, open])

  const handleItemClick = (item: MoreActionsMenuItem) => {
    if (!item.disabled) {
      item.onClick?.()
      setOpen(false)
    }
  }

  return (
    <div className={clsx('inline-block', className)}>
      <button
        ref={triggerRef}
        type="button"
        onClick={toggleMenu}
        className={clsx('p-1 text-gray-600 hover:text-gray-800 hover:bg-[#F6F8FB] rounded-full', triggerClassName)}
      >
        <Icon.MoreVertical className={clsx('size-5', iconClassName)} />
      </button>

      {open &&
        createPortal(
          <div
            ref={menuRef}
            style={{
              position: 'absolute',
              top: menuStyles.top,
              left: menuStyles.left,
              width: typeof menuWidth === 'number' ? `${menuWidth}px` : menuWidth, // Dynamically set the width
            }}
            className={`
              bg-white border border-gray-200 rounded shadow z-50 
              transition transform ease-out duration-200 origin-top-right
              p-2
              ${menuClassName || ''}
              ${open ? 'opacity-100 scale-100' : 'opacity-0 scale-80'}
            `}
          >
            {items.map((item, idx) => (
              <div key={idx} className={clsx(item.onClick === undefined ? 'disabled cursor-not-allowed' : undefined)}>
                {item.dividerBefore && <div className="border-t border-gray-200 my-1" />}
                <button
                  type="button"
                  disabled={(item.disabled ?? false) || item.onClick === undefined}
                  onClick={() => handleItemClick(item)}
                  className="w-full text-left text-sm hover:bg-[#F6F8FB] disabled:bg-inherit disabled:text-gray-400"
                >
                  <div className="px-2 py-2  relative group">
                    {item.icon && <span className="inline-block mr-2">{item.icon}</span>}
                    {item.label}

                    {item.onClick === undefined && (
                      <div className="absolute bg-black text-white text-xs rounded px-2 py-1 invisible group-hover:visible" style={{
                        bottom: '100%',
                        left: '50%',
                        transform: 'translateX(-50%)',
                        marginBottom: '6px',
                        whiteSpace: 'nowrap'
                      }}>
                        Bientôt disponible
                      </div>
                    )}
                  </div>
                </button>
              </div>
            ))}
          </div>,
          document.body
        )}
    </div>
  )
}
export default MoreActionsMenu
