import type { FC } from 'react'
import type { UserType } from '@ed/types'
import { Icon } from '..'

const UserMenu: FC<{ user?: UserType & { avatar?: string } }> = ({ user }) => {
  return <div
    className="flex items-center rounded-full h-13 border-medium-light-grey border gap-2 p-2 border-opacity-100">
    {user?.avatar ? <img className="rounded-full w-9 h-9" alt={user.firstname} src={user.avatar}/> :
      <div className="w-2"/>}
    <div className="flex-col">
      <div className="text-sm font-semibold text-dark-primary">{user?.firstname ?? ''} {user?.lastname ?? ''}</div>
      {!!user?.subjects?.[0] && <div className="text-xs font-normal text-medium-grey">{user.subjects?.[0]?.name}</div>}
    </div>
    <Icon.ChevronDown className="text-medium-grey"/>
  </div>
}

export default UserMenu
