import { type IconProps } from '../../types/icon'

const Camera = (props: IconProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={32}
    height={32}
    viewBox="0 0 32 32"
    {...props}
  >
    <g clipPath="url(#clip0_202_3037)">
      <path
        d="M8.53668 16.9443C9.43822 16.9443 10.1691 16.2135 10.1691 15.3119C10.1691 14.4104 9.43822 13.6796 8.53668 13.6796C7.63514 13.6796 6.9043 14.4104 6.9043 15.3119C6.9043 16.2135 7.63514 16.9443 8.53668 16.9443Z"
      />
      <path
        d="M24.6 15.9365C24.945 15.1035 24.5495 14.1486 23.7166 13.8036C22.8836 13.4586 21.9287 13.8541 21.5837 14.687C21.2387 15.52 21.6342 16.4749 22.4671 16.8199C23.3001 17.1649 24.255 16.7694 24.6 15.9365Z"
      />
      <path
        d="M15.8959 -0.410278C7.11607 -0.410278 0 6.70579 0 15.484V27.7665H4.40644V11.9929C4.40644 10.0102 5.88187 8.28861 7.85626 8.09695C10.128 7.8772 12.0578 9.67646 12.0578 11.9036V16.4968C12.0578 18.7008 13.8439 20.4869 16.0479 20.4869C18.1297 20.4869 19.8166 18.8 19.8166 16.7182V11.9053C19.8166 9.80203 21.5382 8.08043 23.6415 8.08043C25.7447 8.08043 27.4663 9.80203 27.4663 11.9053V27.7665H32V15.6971C32.0017 6.80162 24.7914 -0.410278 15.8959 -0.410278ZM16 12.8091C17.4473 12.8091 18.6221 13.9755 18.6369 15.4212H13.3631C13.3779 13.9755 14.5527 12.8091 16 12.8091ZM16 19.1337C14.5428 19.1337 13.3614 17.9524 13.3614 16.4951C13.3614 16.4852 13.3631 16.477 13.3631 16.4687H18.6369C18.6369 16.477 18.6386 16.4869 18.6386 16.4951C18.6386 17.9524 17.4572 19.1337 16 19.1337Z"
      />
    </g>
    <defs>
      <clipPath id="clip0_202_3037">
        <rect width="32"
          height="32"
          rx="16"
          fill="white"/>
      </clipPath>
    </defs>
  </svg>
)

export default Camera
