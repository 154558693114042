import { Button, Icon, RadioGroup, LabelWrapper, Select, Input } from '@ed/ui'

const EXERCISE_TYPES = [
  { id: 'standart', label: 'Standart', name: 'exercise-type' },
  { id: 'qcm', label: 'QCM', name: 'exercise-type' },
  { id: 'true-or-false', label: 'Vrai ou Faux', name: 'exercise-type' }
]

const ExerciseMenu = () => (
  <form className='flex flex-col gap-3 px-4 py-6 bg-[#FCFCFC] border border-[#E7E4E4] rounded-lg print:hidden' contentEditable={false} onSubmit={(e) => e.preventDefault()}>
    <div>
      <div className='flex gap-6'>
        <LabelWrapper label='Modifier le théme de l’exercice' className='flex-1'>
          <Input value='Transports en commun' />
        </LabelWrapper>

        <LabelWrapper label='Modifier le théme de l’exercice' className='flex-1'>
          <Select>
            <option value="application-lesson">Application du cours</option>
            <option value="application-1">Application 1</option>
            <option value="application-2">Application 2</option>
            <option value="application-3">Application 3</option>
          </Select>
        </LabelWrapper>
      </div>
      <LabelWrapper label='Modifier le théme de l’exercice' className='mt-4'>
        <RadioGroup inputs={EXERCISE_TYPES} />
      </LabelWrapper>
    </div>
    {/* <Switch labelProps={{ className: 'text-sm' }}>Afficher l’espace de reponse</Switch> */}
    <Button
      theme='primary-invert'
      className='text-sm leading-4 rounded-[32px] self-end py-[10px] border-2 mt-3'
      leftIcon={<Icon.Refresh />}
    >
      Modifier l’exercice
    </Button>
  </form>
)

export default ExerciseMenu
