import { forwardRef } from 'react'
import clsx from 'clsx'
import type { DetailedHTMLProps, TextareaHTMLAttributes } from 'react'

type TextareaProps = DetailedHTMLProps<TextareaHTMLAttributes<HTMLTextAreaElement>, HTMLTextAreaElement>

const TextArea = forwardRef<HTMLTextAreaElement, TextareaProps>(({ className, ...props }, ref) => (
  <textarea
    ref={ref}
    {...props}
    className={clsx(
      'border border-[#B0B0B0] p-3 text-sm rounded-lg bg-white placeholder:font-normal placeholder:text-sm',
      className
    )}
  />
))

TextArea.displayName = 'TextArea'

export default TextArea
