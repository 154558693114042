import type { EnvironmentVariables } from './vite-env.d.ts'

import { Environment } from './types/environment'

const hostname = typeof window !== 'undefined' ? window.location.hostname : ''

let conf_env = import.meta.env?.VITE_CONF_ENV

if (!conf_env) {
  if (hostname.includes('.local.') || hostname === 'localhost')
    conf_env = Environment.Local
  else if (hostname.includes('.preview.'))
    conf_env = Environment.Preview
  else if (hostname.includes('.staging.'))
    conf_env = Environment.Staging
  else
    conf_env = Environment.Production
}

export const Env: EnvironmentVariables = {
  CONF_ENV: conf_env,
  GIT_SHA: import.meta.env?.VITE_GIT_SHA ?? null
}
