import type { FC } from 'react'
import { useMemo } from 'react'
import { Button, Icon } from '@ed/ui'
import { Doughnut } from 'react-chartjs-2'
import type {
  ChartOptions
} from 'chart.js'
import {
  Chart as ChartJS,
  ArcElement,
  Tooltip,
  Legend
} from 'chart.js'
import { useTranslation } from 'react-i18next'

ChartJS.register(ArcElement, Tooltip, Legend)

type ClassGap = {
  id: number
  title: string
  description: string
  copiesPercentage: number
  copiesCount: number
}

type Props = {
  classGaps: ClassGap[]
  onCreateRemediation: () => void
  onViewCopies?: (gapId: string) => void
}

const StarSVG: FC<{ className?: string }> = ({ className }) => (
  <svg
    className={className}
    width="11"
    height="12"
    viewBox="0 0 11 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_228_84741)">
      <path
        d="M10.9987 6.02539C10.9987 6.20336 10.9025 6.3559 10.758 6.40675L8.44731 7.34742C7.62893 7.67793 7.00311 8.36437 6.6902 9.22878L5.82368 11.7712C5.82368 11.7712 5.82368 11.7966 5.79961 11.822C5.79961 11.822 5.79961 11.8474 5.79961 11.8728C5.79961 11.8728 5.77554 11.8983 5.75147 11.9237C5.7274 11.9491 5.70333 11.9745 5.65519 11.9745C5.65519 11.9745 5.60705 11.9745 5.58298 11.9745C5.58298 11.9745 5.53484 11.9745 5.51077 11.9745H5.43856C5.43856 11.9745 5.39042 11.9745 5.36635 11.9491C5.34228 11.9491 5.29414 11.8983 5.27007 11.8728C5.27007 11.8728 5.246 11.8474 5.22193 11.822C5.22193 11.822 5.22193 11.822 5.22193 11.7966C5.22193 11.7966 5.19786 11.7712 5.19786 11.7457L4.33134 9.20336C4.0425 8.33895 3.39261 7.65251 2.5983 7.322L0.287575 6.38132C0.143155 6.33047 0.046875 6.17793 0.046875 5.99997C0.046875 5.822 0.167225 5.66946 0.311645 5.61861L2.42981 4.90675C3.34447 4.60166 4.06657 3.86437 4.37948 2.89827L5.17379 0.22878C5.17379 0.203357 5.17379 0.177933 5.22193 0.152509C5.22193 0.152509 5.22193 0.127086 5.27007 0.101662C5.29414 0.0762381 5.34228 0.0253906 5.39042 0.0253906C5.39042 0.0253906 5.41449 0.0253906 5.43856 0.0253906C5.43856 0.0253906 5.4867 0.0253906 5.51077 0.0253906C5.53484 0.0253906 5.55891 0.0253906 5.58298 0.0253906C5.58298 0.0253906 5.60705 0.0253906 5.63112 0.0253906C5.67926 0.0253906 5.70333 0.0762381 5.75147 0.101662C5.75147 0.101662 5.75147 0.127086 5.77554 0.152509C5.77554 0.177933 5.82368 0.203357 5.82368 0.254204L6.6902 2.9237C7.00311 3.8898 7.72521 4.62708 8.63987 4.93217L10.758 5.64403C10.9265 5.69488 11.0228 5.84742 11.0228 6.02539H10.9987Z"
        fill="white"
      />
    </g>
    <defs>
      <clipPath id="clip0_228_84741">
        <rect width="11" height="12" fill="white" transform="translate(-0.0012207)" />
      </clipPath>
    </defs>
  </svg>
)

type SparkleButtonProps = {
  onClick?: () => void
  label: string
}

const SparkleButton: FC<SparkleButtonProps> = ({ onClick, label }) => {
  return (
    <div className="relative inline-block">
      {/* Bouton principal (votre composant Button) */}
      <Button onClick={onClick} className="font-semibold text-sm relative">
        <Icon.MagicWand className="inline-block size-5 mr-1" />
        {label}
      </Button>

      {/* Étoile 1 : à gauche (utilise animation "sparkleOne") */}
      <div className="absolute bottom-[4px] left-[7px] -translate-y-1/2 -translate-x-3 animate-sparkleOne">
        <StarSVG />
      </div>

      {/* Étoile 2 : à droite (animation "sparkleTwo") */}
      <div className="absolute top-[2px] right-[15px] -translate-y-1/2 translate-x-3 animate-sparkleTwo">
        <StarSVG />
      </div>

      {/* Étoile 3 : en haut, centrée (animation "sparkleThree") */}
      <div className="absolute bottom-[20px] right-[5px] -translate-x-1/2 -translate-y-3 animate-sparkleThree">
        <StarSVG className='scale-50' />
      </div>
    </div>
  )
}

const ClassGapsSection: FC<Props> = ({
  classGaps,
  onCreateRemediation,
  onViewCopies
}) => {
  const { t } = useTranslation('evaluation-stats')

  const chartOptions: ChartOptions<'doughnut'> = useMemo(() => {
    return {
      cutout: '80%',
      responsive: false,
      maintainAspectRatio: false,
      plugins: {
        legend: { display: false },
        tooltip: { enabled: false }
      }
    }
  }, [])

  return (
    <div className="w-full p-6">
      <div className="flex items-center justify-between mb-4">
        <h2 className="text-xl font-semibold text-[#291846]">
          {t('gaps-title')}
        </h2>
        <div className="mt-6 relative">
          <SparkleButton onClick={onCreateRemediation} label={t('create-remediation')} />
        </div>
      </div>

      <div className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-6">
        {classGaps.map((gap, idx) => {
          const chartData = {
            labels: [],
            datasets: [
              {
                data: [gap.copiesPercentage, 100 - gap.copiesPercentage],
                backgroundColor: ['#8F80FF', '#ECECF8'],
                borderWidth: 0
              }
            ]
          }

          return (
            <div
              key={gap.id}
              className="bg-white border border-[#E7E4E4] rounded-xl p-2 flex flex-col gap-2"
            >
              <div className="flex items-start gap-4 m-2">
                <div className="text-2xl bg-[#604AFF] bg-opacity-10 text-[#604AFF] rounded-lg font-bold w-[60px] h-[60px] flex flex-shrink-0 items-center justify-center">
                  {idx + 1}
                </div>
                <h3 className="text-[#291846] font-medium text-sm mt-1">
                  {gap.title}
                </h3>
              </div>

              {/*<p className="text-sm text-[#6D717F] mb-4 min-h-[100px]">*/}
              {/*  {gap.description}*/}
              {/*</p>*/}

              <div className="flex bg-[#F9FAFC] p-4 items-center gap-4 rounded-lg">
                <div className="relative w-[60px] h-[60px]">
                  <Doughnut data={chartData} options={chartOptions} width={60} height={60} />
                  <div className="absolute inset-0 flex items-center justify-center text-sm font-bold text-[#604AFF]">
                    {gap.copiesPercentage}%
                  </div>
                </div>
                <div className="text-sm text-[#291846]">
                  {t('problem-percentage', { percentage: gap.copiesPercentage })}
                  <p onClick={() => onViewCopies?.(gap.title)} className='text-[#604AFF] cursor-pointer hover:underline'>{t('view-copies')}<Icon.ArrowRight className='inline ml-2' width={14} /></p>
                </div>
              </div>
            </div>
          )
        })}
      </div>
    </div>
  )
}

export default ClassGapsSection
