import { type IconProps } from '../../types/icon'

const Menu = (props: IconProps) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    stroke='currentColor'
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path d="M20 18H4M14.5 12H4M20 6H4" strokeWidth="2" strokeLinecap="round"/>
  </svg>
)

export default Menu
