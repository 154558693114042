import { useEffect, useState } from 'react'
import resolveConfig from 'tailwindcss/resolveConfig'
import tailwindConfig from '../../tailwind.config'

const fullConfig = resolveConfig(tailwindConfig)

type BreakpointKey = keyof typeof fullConfig.theme.screens

export function useBreakpoint(breakpointKey: BreakpointKey) {
  const breakpoints = fullConfig.theme.screens
  const breakpointValue = breakpoints[breakpointKey]

  const pixels = parseInt(breakpointValue.replace('px', ''), 10)

  const [isAboveBreakpoint, setIsAboveBreakpoint] = useState(
    window.innerWidth >= pixels
  )

  useEffect(() => {
    const checkBreakpoint = () => {
      setIsAboveBreakpoint(window.innerWidth >= pixels)
    }

    window.addEventListener('resize', checkBreakpoint)
    return () => window.removeEventListener('resize', checkBreakpoint)
  }, [pixels])

  return isAboveBreakpoint
}