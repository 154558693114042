import { type IconProps } from '../../types/icon'

const Heart = (props: IconProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    viewBox="0 0 24 24"
    {...props}
  >
    <path
      fill="none"
      stroke="currentColor"
      strokeLinejoin="round"
      strokeWidth={1.5}
      d="M3.807 6.207a4.8 4.8 0 0 1 6.787 0L12 7.612l1.407-1.405a4.8 4.8 0 1 1 6.787 6.787L12 21.189l-8.193-8.195a4.8 4.8 0 0 1 0-6.787Z"
      clipRule="evenodd"
    />
  </svg>
)

export default Heart
