import { Icon } from '@ed/ui'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Button, IconButton, MarkdownViewer } from './components'

const Comment = ({ comment, updateComment }: { comment: string, updateComment?: (comment: string) => void }) => {
  const { t } = useTranslation('copy-page')
  const [isEditing, setIsEditing] = useState(false)
  const [localComment, setLocalComment] = useState(comment)

  return isEditing
    ? <div className='bg-white rounded-lg outline outline-1 outline-[#e4e4e7] flex flex-col'>
      <span
        className='input text-sm p-4 min-h-20 h-max focus:outline-none overflow-hidden resize-none'
        role='textbox'
        suppressContentEditableWarning
        contentEditable={updateComment != null}
        onInput={(e) => setLocalComment(e.currentTarget.innerText)}>
        {localComment}
      </span>
      {!!updateComment && <div className='flex justify-end bg-[#f9fafb] gap-2 items-center border-t border-[#e4e4e7] p-2'>
        <Button size='small' variant='primary' label={t('header.save')} onClick={() => {
          updateComment(localComment)
          setIsEditing(false)
        }} />
      </div>}
    </div>
    : <div className='bg-[#f9fafb] rounded-lg py-2.5 px-4 flex flex-col gap-2'>
      <div className='grid grid-cols-[1fr_auto] items-center'>
        <div className='text-sm text-[#1e1b4b] font-medium'>
          {t('header.comment')}
        </div>
        {!!updateComment && <IconButton variant='text' size='small' icon={<Icon.Edit />} onClick={() => setIsEditing(true)} />}
      </div>
      <div className='text-sm'>
        <MarkdownViewer content={comment} />
      </div>
    </div>
}

export default Comment
