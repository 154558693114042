import { type IconProps } from '../../types/icon'

const Thunderstorm = (props: IconProps) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 20 20"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fill="currentColor"
      stroke="none"
      d="M13.3191 5.39299C12.9702 3.2845 11.129 1.66602 8.92395 1.66602C7.30547 1.66602 5.82804 2.54208 5.04849 3.93784C3.05137 4.19769 1.49969 5.92754 1.49969 7.97664C1.49969 10.2262 3.33349 12.06 5.58304 12.06H13.0073C14.8485 12.06 16.3482 10.5603 16.3482 8.71906C16.3482 6.98179 15.0193 5.5489 13.3191 5.39299Z"/>
    <path
      fill="currentColor"
      stroke="none"
      d="M11.8566 15.1105L11.233 14.7987L11.9011 14.034C12.1684 13.7222 12.1387 13.2545 11.8343 12.9872C11.5225 12.7199 11.0548 12.7496 10.7875 13.054L9.48826 14.5389C9.33978 14.7096 9.27296 14.9472 9.31751 15.1699C9.36205 15.3926 9.51054 15.5857 9.71099 15.6896L10.3346 16.0014L9.66645 16.7661C9.39917 17.0779 9.42887 17.5457 9.73326 17.8129C9.87433 17.9392 10.0451 17.9986 10.2233 17.9986C10.4311 17.9986 10.6316 17.9095 10.7801 17.7461L12.0793 16.2613C12.2278 16.0905 12.2946 15.8529 12.2501 15.6302C12.2055 15.4001 12.0571 15.207 11.8566 15.1105Z"/>
    <path
      fill="currentColor"
      stroke="none"
      d="M7.40162 15.1121L6.77056 14.7928L7.43875 14.0281C7.70602 13.7163 7.67632 13.2486 7.37193 12.9813C7.06011 12.714 6.59238 12.7437 6.32511 13.0481L5.02586 14.533C4.87738 14.7037 4.81056 14.9413 4.8551 15.1641C4.89965 15.3868 5.04813 15.5798 5.24859 15.6838L5.87223 15.9956L5.21147 16.7677C4.94419 17.0795 4.97389 17.5472 5.27829 17.8145C5.41935 17.9407 5.59753 18.0001 5.76829 18.0001C5.97617 18.0001 6.17662 17.911 6.32511 17.7477L7.62435 16.2628C7.77284 16.0921 7.83966 15.8545 7.79511 15.6318C7.75056 15.4016 7.60208 15.2086 7.40162 15.1121Z"/>
  </svg>
)

export default Thunderstorm
