import { BrowserRouter, Route, Routes } from 'react-router'
import Home from '@/app/(default-layout)/Home'
import { useCookies } from 'react-cookie'
import { CookiesEnum } from '@ed/types'
import appConfig from '@/config/config'
import CopyPage from '@/app/copy/[id]/CopyPage'
import './globals.css'
import CreateCopyPage from '@/app/evaluation/[id]/copies/create/CreateCopyPage'
import ExercisePage from '@/app/exercise/ExercisePage'
import NotFound from '@/components/NotFound'
import { withSentryReactRouterV6Routing } from '@sentry/react'
import Developer from '@/app/(default-layout)/Developer'
import { useTranslation } from 'react-i18next'
import { useEffect } from 'react'
import NewEvaluation from '@/app/evaluation/NewEvaluation'
import EvaluationEditWrapper from '@/app/evaluation/[id]/edit/EvaluationEditWrapper'
import ViewEvaluation from '@/app/evaluation/[id]/ViewEvaluation'

const SentryRoutes = withSentryReactRouterV6Routing(Routes)

const App = () => {
  const [cookies] = useCookies([CookiesEnum.AUTH, 'useNewViewer'])
  const { t } = useTranslation('shared')

  useEffect(() => {
    document.title = t('metadata.title')

    const keywords: HTMLMetaElement = document.querySelector('meta[name=\'keywords\']')!
    keywords.content = Object.values(t('metadata.keywords', { returnObjects: true })).join(', ')

    const description: HTMLMetaElement = document.querySelector('meta[name=\'description\']')!
    description.content = t('metadata.description', { defaultValue: '' })
  }, [t])

  if (!cookies[CookiesEnum.AUTH]) {
    console.log('No auth cookie, redirect to sso')
    window.location.href = `${appConfig.sso_url}?origin=${window.location.origin}`
  }

  return (
    <BrowserRouter>
      <SentryRoutes>
        <Route index element={<Home/>}/>

        <Route path="evaluation/create" element={<NewEvaluation/>}/>
        <Route path="evaluation/:evaluationId" element={<ViewEvaluation />}/>
        <Route path="evaluation/:evaluationId/edit" element={<EvaluationEditWrapper/>}/>
        <Route path="evaluation/:evaluationId/copies/create" element={<CreateCopyPage/>}/>

        <Route path="copy/:copyId" element={<CopyPage/>}/>

        <Route path="exercise/:contentId" element={<ExercisePage/>}/>

        {appConfig.dev && <Route path="dev" element={<Developer/>}/>}

        <Route path="*" element={<NotFound/>}/>
      </SentryRoutes>
    </BrowserRouter>
  )
}

export default App
