import type { ReactNode } from 'react'

export enum ButtonType {
  NEGATIVE = 'negative',
  POSITIVE = 'positive',
}

export type ModalButton<T = unknown> = {
  label?: string
  onClick?: () => Promise<T> | T
}

type WithTitle = {
  /** Modal title, can be either text or a component */
  title: ReactNode
}
type WithMessage = {
  /** Modal message, can be either text or a component */
  message: ReactNode
}
export type Modal<T = unknown> = (WithTitle | WithMessage | WithTitle & WithMessage) &
  {
    /** if more components should be displayed below the message,
     * including a controlled component (text, progress bar) */
    body?: ReactNode
    /** Positive button - if not provided, an OK button will be automatically used */
    positive?: ModalButton<T>
    /** Negative button – use `null` to disable,
     * otherwise a Cancel button that dismisses the modal will be used */
    negative?: ModalButton<T> | null
    /** unless false, the user can click outside the modal to dismiss */
    dismissible?: boolean
  }

export type ModalInternal = Modal &
  {
    visible: boolean
    onDismiss?: () => void
    resolve?: (value: { button: ButtonType }) => void
  }

export type ModalResponse = {
  button?: ButtonType
}

export type ModalContext = {
  showModal: (
    modal: Modal,
  ) => Promise<ModalResponse> | void
}
