import CopyHeader from './CopyHeader'
import { useLocation, useParams } from 'react-router'
import type { CopyType } from '@ed/types'
import { FRAGMENT_COPY } from '@ed/types'
import type { TypedDocumentNode } from '@apollo/client'
import { gql, useQuery, useMutation } from '@apollo/client'
import CopyContent from './CopyContent'
import CopyNavigationBar from './ExerciseNavigationBar'
import { useCallback } from 'react'


const gqlQuery: TypedDocumentNode<{ client: { copies: { hits: CopyType[] } } }> = gql`
query ($id:Int!) {
  client {
    copies (ids: [$id]) {
      hits {
        ${FRAGMENT_COPY}
        transcribedDocument
      }
      total
    }
  }
}
`

const gqlUpdateCopy: TypedDocumentNode<{ client: { updateCopy: Pick<CopyType, 'id' | 'studentName' | 'autoGradingOutput'> } }> = gql`
mutation ($copy: CopyInput!) {
  client {
    updateCopy (copy: $copy) {
      studentName
      autoGradingOutput
    }
  }
}
`

export default function CopyPage() {
  const { copyId } = useParams<{ copyId: string }>()
  const location = useLocation()
  const state = location.state as { copy?: CopyType }

  const { data, refetch } = useQuery(gqlQuery, {
    variables: { id: parseInt(copyId ?? '0') },
    skip: !copyId
  })
  const [updateCopyMutation] = useMutation(gqlUpdateCopy)

  const copy = data?.client.copies.hits[0] ?? state?.copy
  const updateCopy = useCallback(async (copy: Pick<CopyType, 'autoGradingOutput'>) => {
    await updateCopyMutation({ variables: { copy: { id: parseInt(copyId ?? '0'), autoGradingOutput: copy.autoGradingOutput } satisfies CopyType } })
    await refetch()
  }, [copyId, updateCopyMutation, refetch])

  return (
    <main className="h-lvh v-lvh">
      {copy && <div className="overflow-auto h-full">
        <div className='relative flex flex-col contentColoredBackground min-h-full'>
          <CopyNavigationBar className='my-2' copy={copy} />
          <div className="flex flex-col">
            <CopyHeader className='mx-6' copy={copy} evaluation={copy.evaluation!} updateCopy={updateCopy} />
            <CopyContent className='mx-12 my-6' evaluation={copy.evaluation!} copy={copy} />
          </div>
        </div>
      </div>}
    </main>
  )
}
