import { type ReactNode, type DetailedHTMLProps, type InputHTMLAttributes, type LabelHTMLAttributes } from 'react'
import uniqueId from 'lodash/fp/uniqueId.js'
import clsx from 'clsx'

type RadioGroupProps = {
  /** An array of objects representing each radio input, including attributes for both the input and label elements. */
  inputs: (DetailedHTMLProps<InputHTMLAttributes<HTMLInputElement>, HTMLInputElement> & {
    label: ReactNode;
    labelProps?: DetailedHTMLProps<LabelHTMLAttributes<HTMLLabelElement>, HTMLLabelElement>;
    checked?: boolean;
  })[]
  className?: string
}

/**
 * A customizable `RadioGroup` component allowing users to select an option from a group of radio buttons.
 */
const RadioGroup = ({ inputs, className }: RadioGroupProps) => (
  <ul className={clsx('flex', className)}>
    {inputs.map(({ label, labelProps = {}, ...input }) => (
      <li
        key={input.id || uniqueId('radio-group-item')}
        className={'flex flex-1 bg-light-grey has-[:checked]:bg-[#604AFF1A] has-[:disabled]:opacity-60 text-inherit has-[:checked]:text-[#604AFF] text-sm border border-solid border-[#E5E0EB] has-[:checked]:border-[#604AFF] first-of-type:rounded-l-[32px] last-of-type:rounded-r-[32px] has-[:focus-visible]:outline outline-primary outline-2 items-center'}>
        <input type="radio" {...input} className={clsx('visually-hidden peer', input.className)} />
        <label
          htmlFor={input.id}
          {...labelProps}
          className={clsx('block text-center px-6 py-3 cursor-pointer peer-disabled:cursor-not-allowed w-full', labelProps.className)}
        >
          {label}
        </label>
      </li>
    ))}
  </ul>
)

export default RadioGroup
