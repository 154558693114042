import config from '@/config/config'

interface PathParamsType {
  evaluationId?: number
  copyId?: number
  contentId?: number
}

export enum URLS {
  HOME = 'home',
  EVALUATIONS = 'evaluations',
  EVALUATION = 'evaluation',
  EVALUATION_CREATE = 'evaluation_create',
  EVALUATION_EDIT = 'evaluation_edit',
  COPY_CREATE = 'copy_create',
  COPY = 'copy',
  DEV = 'dev',
  NEW_EVALUATION = 'new_evaluation',
  EXERCISE = 'exercise',
}

// TODO: fix loose typing
export const getPath = (name: string, params: PathParamsType = {}) => {
  switch (true) {
    case name === URLS.HOME:
      return '/'
    case name === URLS.EVALUATIONS:
      return '/'
    case name === URLS.EVALUATION:
      return `/evaluation/${params.evaluationId}`
    case name === URLS.EVALUATION_CREATE:
      return '/evaluation/create'
    case name === URLS.EVALUATION_EDIT:
      return `/evaluation/${params.evaluationId}/edit`
    case name === URLS.COPY_CREATE:
      return `/evaluation/${params.evaluationId}/copies/create`
    case name === URLS.COPY:
      return `/copy/${params.copyId}`
    case name === URLS.DEV:
      return '/dev'
    case name === URLS.NEW_EVALUATION:
      return '/evaluation/create'
    case name === URLS.EXERCISE:
      return `/exercise/${params.contentId}`

    default:
      return '/'
  }
}

export const getBackendUrl = (path: string, token?: string, queryParameters: Record<string, string | number | boolean> = {}) => {
  if (path.startsWith('/')) path = path.slice(1)
  const queryParams = new URLSearchParams()
  for (const [key, value] of Object.entries(queryParameters))
    queryParams.set(key, value.toString())
  if (token)
    queryParams.set('access_token', token)
  return `${config.api_url}/${path}?${queryParams.toString()}`
}
