import AsideHeader from '@/components/AsideHeader'
import EvaluationCopyCreate from '@/components/evaluation/EvaluationCopyCreate'
import EvaluationsStatsCopyList from '@/components/evaluation/EvaluationsStatsCopyList'
import EvaluationsStatsCreateRemediation from '@/components/evaluation/EvaluationsStatsCreateRemediation'
import EvaluationCopy from '@/components/evaluation/EvaluationCopy'
import useSelection from '@/hooks/useSelection'
import { EvaluationPanelActionEnum } from '@/utils/evaluation'
import EvaluationDocumentsView from './EvaluationDocumentsView'
import EvaluationScoreDetailsView from '../EvaluationScoreDetailsView'

const EvaluationAsideContent = () => {
  const { selection, close } = useSelection()
  return <>
    {selection.type === EvaluationPanelActionEnum.COPY && <EvaluationCopy />}
    {selection.type === EvaluationPanelActionEnum.CREATE_REMEDIATION && <EvaluationsStatsCreateRemediation data={selection.data} onDone={selection.onDone}/>}
    {selection.type === EvaluationPanelActionEnum.COPY_LIST && <EvaluationsStatsCopyList data={selection.data}/>}
    {selection.type === EvaluationPanelActionEnum.EDIT_RUBRIC && <AsideHeader title="Edit rubric" onClose={close}/>}
    {selection.type === EvaluationPanelActionEnum.ADD_COPY && <EvaluationCopyCreate />}
    {selection.type === EvaluationPanelActionEnum.SCORE_DETAILS && <EvaluationScoreDetailsView data={selection.data} />}
    {selection.type === EvaluationPanelActionEnum.VIEW_EVALUATION_DOCUMENTS && <EvaluationDocumentsView data={selection.data} />}
  </>
}

export default EvaluationAsideContent