import { Icon } from '..'
import type { FC, PropsWithChildren } from 'react'
import type { UserType } from '@ed/types'
import UserMenu from './UserMenu'

const Header: FC<PropsWithChildren<{ user?: UserType & { avatar?: string } }>> = ({ children, user }) => {
  return (
    <div className='bg-white'>
      <div className='w-full p-4 bg-sandbox-banner flex items-center justify-center color-[#291846] text-sm italic'>  <strong className='pr-1'>🚀 Bienvenue sur la Beta de Ed !</strong>  Découvrez, testez, <a href={`https://share.hsforms.com/1TZZ50zikSM-mrGTEPRG80gsrvwc?email=${user?.email}`} target='_blank' className='underline cursor-pointer pl-1 pr-1'> partagez </a> vos retours !</div>
      <div className="p-4 w-full flex items-center">
        <Icon.EdRound className="ml-6 mx-2"/>
        <div className="font-figtree text-3-5xl font-bold ml-0.5 mr-4">Ed</div>
        <div className="flex-grow"/>
        <div className="mx-8 gap-8 flex flew-row">{children}</div>
        {!!user && <UserMenu user={user}/>}
      </div>
    </div>
  )
}

export default Header
