const NotReadySvg = () => <svg width="140" height="178" viewBox="0 0 140 178" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M94.0745 73.4566C84.2373 73.4566 74.2237 73.0569 64.8564 70.448C55.6656 67.8976 47.227 62.9496 39.7049 57.2258C34.7805 53.5 30.3026 50.5383 23.909 50.9848C17.6487 51.3276 11.6651 53.6765 6.84354 57.6841C-1.28949 64.736 -0.0672575 77.9345 3.18842 87.2076C8.0776 101.17 22.9569 110.843 35.6033 117.166C50.2123 124.465 66.267 128.696 82.3568 131.14C96.4604 133.279 114.584 134.842 126.807 125.628C138.031 117.166 141.11 97.8439 138.36 84.7981C137.693 80.9406 135.641 77.4592 132.589 75.0079C124.703 69.2489 112.938 73.0922 104.076 73.2919C100.785 73.3626 97.4358 73.4448 94.0745 73.4566Z" fill="#F2F2F2"/>
  <path d="M70.0033 154C93.8313 154 113.149 152.795 113.149 151.309C113.149 149.822 93.8313 148.617 70.0033 148.617C46.1747 148.617 26.8579 149.822 26.8579 151.309C26.8579 152.795 46.1747 154 70.0033 154Z" fill="#F2F2F2"/>
  <path d="M114.089 59.0945V64.16" stroke="#BABABA" strokeWidth="4.9724" strokeLinecap="round" strokeLinejoin="round"/>
  <path d="M111.563 61.6331H116.617" stroke="#BABABA" strokeWidth="4.9724" strokeLinecap="round" strokeLinejoin="round"/>
  <path d="M29.2085 101.664V106.718" stroke="#BABABA" strokeWidth="4.9724" strokeLinecap="round" strokeLinejoin="round"/>
  <path d="M26.6694 104.191H31.7349" stroke="#BABABA" strokeWidth="4.9724" strokeLinecap="round" strokeLinejoin="round"/>
  <path d="M22.123 38.0332V43.0869" stroke="#BABABA" strokeWidth="4.9724" strokeLinecap="round" strokeLinejoin="round"/>
  <path d="M19.584 40.5598H24.6495" stroke="#BABABA" strokeWidth="4.9724" strokeLinecap="round" strokeLinejoin="round"/>
  <path d="M77.1972 26.3036C77.8333 26.3036 78.349 25.7879 78.349 25.1518C78.349 24.5157 77.8333 24 77.1972 24C76.5612 24 76.0454 24.5157 76.0454 25.1518C76.0454 25.7879 76.5612 26.3036 77.1972 26.3036Z" fill="#CFCFCF"/>
  <path d="M74.4457 138.662C75.0818 138.662 75.5975 138.146 75.5975 137.51C75.5975 136.875 75.0818 136.359 74.4457 136.359C73.8097 136.359 73.2939 136.875 73.2939 137.51C73.2939 138.146 73.8097 138.662 74.4457 138.662Z" fill="#CFCFCF"/>
  <path d="M99.1823 31.8001L32.1517 38.7626C30.6215 38.9215 29.5099 40.2907 29.669 41.8209L38.8366 130.082C38.9954 131.612 40.3649 132.723 41.8949 132.564L108.925 125.601C110.456 125.443 111.568 124.073 111.409 122.543L102.242 34.2828C102.082 32.7526 100.713 31.641 99.1823 31.8001Z" fill="#D2D2D2"/>
  <path d="M96.2967 35.874L35.8006 42.1575C34.2704 42.3166 33.1588 43.6859 33.3179 45.2158L41.6088 125.036C41.7676 126.566 43.1369 127.678 44.6671 127.519L105.163 121.235C106.693 121.076 107.805 119.707 107.647 118.177L99.355 38.3566C99.1962 36.8266 97.8269 35.715 96.2967 35.874Z" fill="white" stroke="#BABABA" strokeWidth="4.9724" strokeLinecap="round" strokeLinejoin="round"/>
  <path d="M85.8583 39.7843L84.918 30.7344C84.871 30.3588 84.6783 30.0165 84.3817 29.7814C84.0848 29.5462 83.7078 29.437 83.3315 29.4767L72.1425 30.6519C72.0515 29.749 71.7835 28.8728 71.3538 28.0734C70.9241 27.274 70.3412 26.5673 69.6385 25.9932C68.9356 25.419 68.1265 24.9891 67.2576 24.7277C66.3885 24.4662 65.4766 24.3786 64.5737 24.4698C63.6708 24.5608 62.7946 24.8288 61.9955 25.2585C61.1961 25.6882 60.4894 26.2711 59.9152 26.9738C58.7559 28.3934 58.208 30.2151 58.3919 32.0386L47.1677 33.2138C46.9783 33.233 46.7949 33.2892 46.6276 33.3793C46.46 33.4693 46.312 33.5918 46.1922 33.739C46.0721 33.8865 45.9821 34.056 45.9277 34.2382C45.8733 34.4205 45.8554 34.6114 45.8747 34.8005L46.815 43.8504C46.8341 44.0395 46.8904 44.2232 46.9805 44.3905C47.0705 44.5581 47.193 44.7061 47.3402 44.8259C47.4877 44.946 47.6572 45.036 47.8394 45.0904C48.0216 45.1448 48.2126 45.1627 48.4017 45.1433L84.5774 41.3823C84.7672 41.3634 84.9513 41.3067 85.1189 41.2157C85.2867 41.1247 85.4345 41.0013 85.5536 40.8524C85.673 40.7035 85.7613 40.5324 85.8136 40.3489C85.8658 40.1658 85.881 39.9736 85.8583 39.7843Z" fill="white" stroke="#BABABA" strokeWidth="4.9724" strokeLinecap="round" strokeLinejoin="round"/>
  <path d="M56.7695 94.9529C57.1543 91.4653 58.7219 88.2146 61.2108 85.7413C63.6999 83.2682 66.9606 81.7217 70.4505 81.3591C73.9406 80.9965 77.4495 81.8396 80.3938 83.7483C83.3382 85.6569 85.5402 88.516 86.6341 91.8499" stroke="#BABABA" strokeWidth="4.9724" strokeLinecap="round" strokeLinejoin="round"/>
  <path d="M58.4857 68.0386L52.856 74.9728" stroke="#BABABA" strokeWidth="4.9724" strokeLinecap="round" strokeLinejoin="round"/>
  <path d="M52.2095 68.6965L59.1321 74.3144" stroke="#BABABA" strokeWidth="4.9724" strokeLinecap="round" strokeLinejoin="round"/>
  <path d="M85.7059 65.2178L80.0762 72.1404" stroke="#BABABA" strokeWidth="4.9724" strokeLinecap="round" strokeLinejoin="round"/>
  <path d="M79.4297 65.8643L86.3523 71.494" stroke="#BABABA" strokeWidth="4.9724" strokeLinecap="round" strokeLinejoin="round"/>
</svg>


const EvaluationCopyNotReady = () => {
  return (
    <div className="w-[400px] flex flex-col justify-center items-center">
      <NotReadySvg />
      <div className="text-[#83879B] text-sm font-medium text-center">Les résultats de cette copie ne sont pas encore disponibles.</div>
    </div>
  )
}

export default EvaluationCopyNotReady