import { formatDate } from '@/utils/evaluation'
import { getPath, URLS } from '@/utils/url'
import { generatePdfUrl } from '@/utils/userContent'
import type { UserContentType } from '@ed/types'
import { Table, type ColumnType, MoreActionsMenu, Icon, type MoreActionsMenuItem } from '@ed/ui'
import type { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router'

const EvaluationsStatsRemediation: FC<{ data: UserContentType[] }> = ({ data }) => {
  const { t } = useTranslation(['evaluation-stats-remediation', 'shared'])
  const navigate = useNavigate()
  const columns: ColumnType<UserContentType>[] = [
    {
      id: 'title',
      header: <p className='text-sm'>{t('title')}</p>,
      accessor: (activity) => (
        <div className='flex items-center'>
          <div className="w-10 h-10 flex items-center justify-center rounded-full bg-[#F6F8FB] flex-shrink-0">
            <Icon.Exercise width={20} height={20}/>
          </div>
          <div className='ml-2'>
            <div className='font-semibold text-[#291846] text-sm items-center line-clamp-1 overflow-ellipsis'>{activity.title}</div>
            <div className='text-xs text-[#6D717F]'>{formatDate(activity.createdAt)}</div>
          </div>
        </div>
      )
    },
    // {
    //   id: 'gaps',
    //   header: <p className='text-sm'>Axe(s) d'amélioration= traité(s)</p>,
    //   accessor: (activity) => (
    //     <ul className='list-disc pl-5 marker:text-[#604AFF] text-[#291846] text-sm'>
    //       {activity.treatedGaps.map(gap => (
    //         <li>{gap.title}</li>
    //       ))}
    //     </ul>
    //   )
    // },
    {
      id: 'actions',
      header: '',
      accessor: (activity) => {
        const menuItems: MoreActionsMenuItem[] = [
          {
            onClick: () => navigate(getPath(URLS.EXERCISE, { contentId: activity.id })),
            label: t('view-activity'),
            icon: <Icon.Eye className="size-4" />
          },
          {
            onClick: () => window.open(generatePdfUrl(activity), '_blank'),
            label: t('download'),
            icon: <Icon.Download className="size-4" />
          },
          {
            label: t('edit'),
            icon: <Icon.Edit className="size-4" />
          },
          {
            label: t('delete'),
            icon: <Icon.Trash className="size-4" />,
            dividerBefore: true,
          }
        ]
        return <MoreActionsMenu className='block' items={menuItems} />
      }
    }
  ]

  return (
    <div className="p-4">
      <Table
        columns={columns}
        data={data}
        getRowKey={(row) => row.id}
        minimal={false}
        emptyComponent={<div className="w-full text-sm p-2 text-center">{t('empty')}</div>}
        columnStructure='1fr 50px'
      />
    </div>
  )
}

export default EvaluationsStatsRemediation
