const screens = {
  xs: '390px',
  sm: '640px',
  md: '768px',
  lg: '1024px',
  '2lg': '1080px',
  xl: '1280px',
  '2xl': '1536px'
}

export default screens
