import { StatusEnum } from '@ed/types'
import { Icon } from '@ed/ui'

const STATUS_WORDING = {
  [StatusEnum.WAITING]: 'waiting',
  [StatusEnum.PROCESSING]: 'processing',
  [StatusEnum.PROCESSED]: 'processed'
} as const

const STATUS_ICON: Record<StatusEnum, React.FC> = {
  [StatusEnum.WAITING]: Icon.ClockForward,
  [StatusEnum.PROCESSING]: Icon.ClockForward,
  [StatusEnum.PROCESSED]: Icon.CheckCircle2
} as Record<StatusEnum, React.FC>

const STATUS_ICON_COLOR: Record<StatusEnum, string> = {
  [StatusEnum.DRAFT]: '',
  [StatusEnum.WAITING]: 'text-[#6D717F]',
  [StatusEnum.PROCESSING]: 'text-[#6D717F]',
  [StatusEnum.PROCESSED]: 'text-[#291846]',
  [StatusEnum.ERROR]: 'text-[#E0245E]',
  [StatusEnum.ERROR_OCR]: 'text-[#E0245E]',
} as Record<StatusEnum, string>

const STATUS_BACKGROUND_COLOR: Record<StatusEnum, string> = {
  [StatusEnum.DRAFT]: 'bg-[#F3F4F8]',
  [StatusEnum.WAITING]: 'bg-[#6D717F0D]',
  [StatusEnum.PROCESSING]: 'bg-[#6D717F0D]',
  [StatusEnum.PROCESSED]: 'bg-[#6D717F0D]',
  [StatusEnum.ERROR]: 'bg-[#E0245E0C]',
  [StatusEnum.ERROR_OCR]: 'bg-[#E0245E0C]',
} as Record<StatusEnum, string>

export const getStatusWordingKey = (status?: StatusEnum) => (status !== undefined && status in STATUS_WORDING)
  ? STATUS_WORDING[status as keyof typeof STATUS_WORDING]
  : 'error'

export const getStatusIcon = (status?: StatusEnum) => status !== undefined && status in STATUS_ICON
  ? STATUS_ICON[status]
  : Icon.CloseCircle

export const getStatusIconColor = (status?: StatusEnum) => status !== undefined && status in STATUS_ICON_COLOR
  ? STATUS_ICON_COLOR[status]
  : 'text-[#E0245E]'

export const getStatusBackgroundColor = (status?: StatusEnum) => status !== undefined && status in STATUS_BACKGROUND_COLOR
  ? STATUS_BACKGROUND_COLOR[status]
  : 'bg-[#E0245E1A]'