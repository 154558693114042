import { getPath, URLS } from '@/utils/url'
import { useTranslation } from 'react-i18next'
import { FiArrowLeft } from 'react-icons/fi'
import { NavLink, useParams } from 'react-router'
import CreateCopyForm from './CreateCopyForm1'

export default function CreateCopyPage() {
  const { evaluationId: evaluationIdS } = useParams<{ evaluationId: string }>()
  const evaluationId = parseInt(evaluationIdS ?? '0', 10)
  const { t } = useTranslation('create-copy-page')

  return (
    <main className="flex min-h-screen flex-col items-center p-12 bg-gray-100">
      <section className="w-full max-w-4xl bg-white p-6 rounded-lg shadow-md mt-12">
        <NavLink to={getPath(URLS.EVALUATION, { evaluationId })} className="flex items-center text-blue-600 hover:text-blue-800 mb-3">
          <FiArrowLeft className="mr-2" />
          {t('back-to-home')}
        </NavLink>
        <h1 className="text-2xl font-bold mb-4 text-black">{t('title')}</h1>
        <hr className="h-px my-8 bg-gray-500 border-0"/>
        <CreateCopyForm evaluationId={evaluationId} />
      </section>
    </main>
  )
}
