import clsx from 'clsx'
import { Icon, IconButton } from '@ed/ui'

type ExerciseToolbarProps = {
  isMenuShown: boolean;
  toggleMenu: () => void;
}

const ExerciseToolbar = ({ isMenuShown, toggleMenu }: ExerciseToolbarProps) => (
  <div className='flex gap-2 print:hidden' contentEditable={false}>
    <IconButton theme='tertiary' className='w-8 h-8'><Icon.Refresh className='w-3 h-3' /></IconButton>
    <IconButton theme='tertiary' className='w-8 h-8'><Icon.Dice className='w-3 h-3' /></IconButton>
    <IconButton theme='tertiary' className='w-8 h-8'><Icon.Trash className='w-3 h-3' /></IconButton>
    <IconButton
      theme='tertiary'
      className={clsx('w-8 h-8', isMenuShown && 'border-[#1121304D] bg-[#1121300D]')}
      onClick={toggleMenu}
    >
      {isMenuShown ? <Icon.Close className='w-3 h-3' /> : <Icon.Plus className='w-3 h-3' />}
    </IconButton>
  </div>
)

export default ExerciseToolbar
