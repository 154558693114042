import type { EvaluationStatsType } from '@/components/evaluation/EvaluationStats'
import type { FC } from 'react'
import { useMemo } from 'react'
import { Bar } from 'react-chartjs-2'
import { BarElement, CategoryScale, Chart as ChartJS, Legend, LinearScale, Title, Tooltip } from 'chart.js'
import { Icon } from '@ed/ui'
import TitleAndButton from '@/components/TitleAndButton.tsx'
import { useTranslation } from 'react-i18next'

// Initialisation des plugins Chart.js
ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend)

type Props = {
  stats: EvaluationStatsType
  onDetailClick?: () => void
}

const EvaluationStatsHeader: FC<Props> = ({ stats, onDetailClick }) => {
  // Prépare les données pour le chart
  const chartData = useMemo(() => {
    const labels = stats.distribution.map((item) => item.scoreRange)
    const data = stats.distribution.map((item) => item.count)

    return {
      labels,
      datasets: [
        {
          label: 'Nombre de copies',
          data,
          backgroundColor: '#604AFF'
        }
      ]
    }
  }, [stats.distribution])

  // Options du chart
  const chartOptions = useMemo(() => {
    return {
      responsive: true,
      maintainAspectRatio: false as const,
      scales: {
        y: {
          beginAtZero: true,
          ticks: {
            stepSize: 1
          }
        }
      },
      plugins: {
        legend: {
          display: false // on masque la légende pour un rendu épuré
        },
        title: {
          display: false
        }
      }
    }
  }, [])

  const { t } = useTranslation('evaluation-stats-header')

  return (
    <div className="w-full px-8 py-6">
      <TitleAndButton onClick={onDetailClick} title={t('title')} buttonLabel={t('see-details')}/>

      {/* Main content in 2 columns: stats on left, graph on right */}
      <div className="grid grid-cols-1 md:grid-cols-4 gap-4">
        {/* Left colum: stats */}
        <div className="grid grid-rows-2 gap-4">
          <div className="bg-[#604AFF] border border-[#604AFF] text-white rounded-xl p-4">
            <div className="w-8 h-8 flex items-center justify-center rounded-full bg-white/10 mb-2">
              <Icon.CheckCircle2/>
            </div>
            <div className="mb-2">
              <span className='text-4xl font-bold'>{t('numeric-score', { score: stats.average })}</span><span
                className='text-xl text-white text-opacity-80'> /20</span>
            </div>
            <div className="text-sm mb-1">
              {t('class-average')}
            </div>
          </div>
          <div className="border border-[#E7E4E4] rounded-xl p-4 text-[#291846]">
            <div className="w-8 h-8 flex items-center justify-center rounded-full bg-[#F9FAFC] mb-2">
              <Icon.Exercise width={20} height={20} />
            </div>
            <div className="text-4xl font-bold text-[#2B3674]">
              {stats.correctedCopies}
            </div>
            <div className="text-sm mb-1">
              {t('graded-copies-num')}
            </div>
          </div>
        </div>

        {/* Right column (occupying 2/3): chart */}
        <div className="col-span-1 md:col-span-3 border border-[#E7E4E4] rounded-xl p-4">
          <div className="flex items-center justify-between mb-4">
            <h2 className="font-semibold text-sm text-[#291846]">
              {t('result-distribution')}
            </h2>
            {/* You can add other stats here, e.g., min grade / max grade */}
            <div className="text-xs text-[#291846]">
              <div className='inline-block bg-[#F9FAFC] p-2'>{t('min-score', { score: stats.min, max: stats.evaluationMax })}</div>
              <div className='inline-block bg-[#F9FAFC] p-2'>{t('max-score', { score: stats.max, max: stats.evaluationMax })}</div>
            </div>
          </div>
          <div className="h-64 bg-[#F9FAFC] p-4 rounded-xl">
            <Bar data={chartData} options={chartOptions}/>
          </div>
        </div>
      </div>
    </div>
  )
}

export default EvaluationStatsHeader
