// Create context with just active tab ID and setter
import { createContext, type Dispatch, type SetStateAction, useContext } from 'react'

export type ContextType<Ids = string> = {
  activeTabId: Ids,
  setActiveTabId: Dispatch<SetStateAction<Ids>>
}

export const TabContext = createContext<ContextType>(null!)

// Custom hook for using the tab context
export function useTabContext<Ids>() {
  const tabContext = useContext(TabContext)

  if (tabContext === null) {
    throw new Error('Only use within TabProvider')
  }

  return tabContext as unknown as ContextType<Ids>
}
