import type { TypedDocumentNode } from '@apollo/client'
import { gql, useQuery } from '@apollo/client'
import ExerciseBody from './ExerciseBody'
import type { UserContentType } from '@ed/types'
import { FRAGMENT_USER_CONTENT_BASE } from '@ed/types'
import { useParams } from 'react-router'

const gqlQuery: TypedDocumentNode<{ client: { userContents: { hits: UserContentType[] } } }> = gql`
query ($id: Int!) {
  client {
    userContents (ids: [$id]) {
      hits {
        ${FRAGMENT_USER_CONTENT_BASE}
      }
    }
  }
}
`

const ExercisePage = () => {
  const { contentId } = useParams<{ contentId: string }>()
  const { data, loading } = useQuery(gqlQuery, { variables: { id: parseInt(contentId ?? '0', 10) } })
  const content = data?.client?.userContents?.hits?.[0]

  if (loading) {
    return <div>Loading...</div>
  }

  if (!content) {
    return <div>User content not found</div>
  }

  return (
    <ExerciseBody content={content} />
  )
}

export default ExercisePage
