import type { Action } from '@/contexts/SelectionProvider'
import { createContext, useContext, type Dispatch } from 'react'

export const SelectionContext = createContext<{
  selection: Action
  dispatch: Dispatch<Action>
  close: () => void
} | undefined>(undefined)

const useSelection = () => {
  const selection = useContext(SelectionContext)

  if (selection === undefined) {
    throw new Error('Use in SelectionProvider')
  }

  return selection
}

export default useSelection
