import AssetImage from '@/components/AssetImage'
import TitleAndButton from '@/components/TitleAndButton'
import type { TypedDocumentNode } from '@apollo/client'
import { gql, useQuery } from '@apollo/client'
import type {
  AssetType,
  AutoGradingEvaluationQuestionOutput
} from '@ed/types'
import {
  type AutoGradingEvaluationOutput
} from '@ed/types'
import type { ColumnType } from '@ed/ui'
import { Icon, Table } from '@ed/ui'
import clsx from 'clsx'
import type { FC } from 'react'
import { useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { sumBy } from 'remeda'
import useEvaluationId from '@/hooks/useEvaluationId'
import useSelection from '@/hooks/useSelection'
import { EvaluationPanelActionEnum } from '@/utils/evaluation'

const gqlQuery: TypedDocumentNode<{
  client: {
    evaluations: { hits: Array<{ assets: Array<AssetType>, autoGradingOutput: AutoGradingEvaluationOutput }> }
  }
}> = gql`
  query getEvaluationAssets($id: Int!) {
    client {
      evaluations (ids: [$id]) {
        hits {
          assets {
            id
            pageCount
            filename
            mimeType
          }
          autoGradingOutput
        }
      }
    }
  }
`

interface EvaluationDocumentPropTypes {
  evaluationId: number,
  asset: AssetType,
  onClick: () => void
}

const EvaluationDocument: FC<EvaluationDocumentPropTypes> = ({ asset, evaluationId, onClick }) => {
  return (
    <div onClick={onClick} className='w-[297px] h-[365px] rounded-2xl border border-[#E5E0EB] hover:border-[#604AFF] cursor-pointer transition duration-200 p-2'>
      <AssetImage base={`evaluations/${evaluationId}`} assetId={asset.id} alt={asset.filename} className='w-[281px] h-[281px] rounded-2xl border border-[#E5E0EB] bg-cover bg-top bg-no-repeat' />
      <div className='p-2 flex overflow-hidden'>
        <div className='p-3 bg-[#CFCBDB] rounded-sm'>
          {asset.mimeType?.includes('pdf') ? <Icon.Pdf className='fill-white'/> : <Icon.Image className='fill-white'/>}
        </div>
        <div className='ml-4'>
          <p className='text-[#112130] text-sm font-medium line-clamp-2 overflow-ellipsis'>{asset.filename}</p>
          {/* TODO asset.status doesn't exist yet */}
          <p className='text-[#6D717F] text-sm'>Traité</p>
        </div>
      </div>
    </div>
  )
}

const EvaluationDocuments = () => {
  const { t } = useTranslation('evaluation-documents')
  const evaluationId = useEvaluationId()
  // const { dispatch } = useSelection()

  const { data } = useQuery(gqlQuery, { variables: { id: evaluationId.current }, skip: evaluationId.current == null })
  const assets = data?.client?.evaluations?.hits[0]?.assets as AssetType[]
  const autoGrading = data?.client?.evaluations?.hits[0]?.autoGradingOutput

  const questions: Array<AutoGradingEvaluationQuestionOutput & { isTotal?: boolean }> = useMemo(() =>
    autoGrading?.questions?.flatMap((e: AutoGradingEvaluationQuestionOutput) =>
      [
        ...(e.children?.map(c => ({ ...c,
          number: `${e.number}. ${c.number}`
        })) ?? []),
        { ...e,
          number: t('total', { name: e.number }),
          isTotal: true,
          score: {
            details: t('total-score', { max: e.children?.length ? sumBy(e.children, q => q.score?.max ?? 0) : e.score?.max ?? 0 }),
            max: 0
          }
        }
      ]) ?? []
  , [autoGrading?.questions, t])

  // console.log('EvaluationDocuments', data, questions)

  const columns = useMemo(() => {
    const result: ColumnType<AutoGradingEvaluationQuestionOutput & { isTotal?: boolean }>[] = []
    result.push({
      id: 'name',
      header: <p className='text-sm font-semibold'>{t('rubric-details')}</p>,
      accessor: (scaleItem) => (
        <p className={clsx('text-[#291846] text-sm', scaleItem.isTotal ? 'font-semibold' : undefined)}>{scaleItem.number}</p>
      )
    })
    if (autoGrading?.global?.isScoreBased) {
      result.push({
        id: 'score',
        header: <p className='text-sm font-semibold'>{t('total-score', { max: autoGrading.global.score!.max })}</p>,
        accessor: (scaleItem) => (
          <p
            contentEditable={true}
            suppressContentEditableWarning={true}
            className={clsx('text-[#291846] text-sm cursor-text', scaleItem.isTotal ? 'font-semibold' : undefined)}>
            {scaleItem.score?.details}
          </p>
        )
      })
    }
    return result
  }, [autoGrading, t])

  const { dispatch } = useSelection()
  const onClickDocument = useCallback(() => {
    dispatch({ type: EvaluationPanelActionEnum.VIEW_EVALUATION_DOCUMENTS, data: {
      evaluationId: evaluationId.current ?? 0,
      assets: assets
    } })
  }, [evaluationId, assets, dispatch])

  // const onEditRubric = useCallback(() => { dispatch({ type: 'editRubric' }) }, [dispatch])

  return (
    <div className="p-6">
      <TitleAndButton title={<>{t('title')} <span className='text-[#6D717F] text-regular'>({assets?.length})</span></>} />

      <div className='flex gap-3 mb-4 overflow-x-scroll'>
        {assets?.map(asset => <EvaluationDocument onClick={onClickDocument} key={asset.id} asset={asset} evaluationId={evaluationId.current ?? 0} />)}
      </div>

      <TitleAndButton
        title={t('rubric')}
        // TODO: restore icon and remove contentEditable
        // buttonLabel={t('edit-rubric')}
        // buttonIcon={<Icon.Edit className="size-5"/>}
        // onClick={onEditRubric}
      />

      <Table
        columns={columns}
        data={questions ?? []}
        getRowKey={(row) => row.number}
        minimal={false}
        columnStructure='1fr 3fr'
      />
    </div>
  )
}

export default EvaluationDocuments