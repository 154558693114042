import { type IconProps } from '../../types/icon'

const MagicWand = (props: IconProps) => (
  <svg
    width={24}
    height={24}
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    {...props} >
    <path
      d="M20.9998 10.3704L17.3621 10.3237L15.2616 13.2944L14.5574 11.0288L14.1818 9.81997L12.9732 9.44425L10.708 8.73994L13.6781 6.63896L13.6314 3.00052L16.5471 5.17656L19.9925 4.00806L18.8242 7.45418L20.9998 10.3704Z"
      fill="currentColor"
      stroke="white"
      strokeWidth="1.2"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"/>
    <path
      d="M14.5528 11.0284L4.91128 20.6719C4.47385 21.1094 3.76457 21.1094 3.3271 20.6719C2.88967 20.2343 2.88967 19.5249 3.3271 19.0873L12.9686 9.44385L14.1772 9.81957L14.5528 11.0284Z"
      fill="currentColor"
      stroke="white"
      strokeWidth="1.2"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"/>
  </svg>
)

export default MagicWand



