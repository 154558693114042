import EvaluationCardItem from '@/components/evaluation/EvaluationCardItem'
import EvaluationListItem from '@/components/evaluation/EvaluationListItem'
import LogoutButton from '@/components/LogoutButton'
import { getPath, URLS } from '@/utils/url'
import type { TypedDocumentNode } from '@apollo/client'
import { gql, useQuery } from '@apollo/client'
import type { EvaluationType } from '@ed/types'
import { OrderByFieldEnum } from '@ed/types'
import { Button, Header, Icon, IconButton, Select } from '@ed/ui'
import clsx from 'clsx'
import { parseInt } from 'lodash'
import { useMemo, useState } from 'react'
import { useCookies } from 'react-cookie'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router'
import { prop, sortBy } from 'remeda'

type EvaluationsAndGrades = {
  client:
  {
    evaluations: { hits: Array<
        EvaluationType & { copyIds: number[], grade: { name: string } }
    >, total: number },
    grades: { hits: Array<{ id: number, name: string }> }
  }
}
const gqlQuery: TypedDocumentNode<EvaluationsAndGrades> = gql`
query getNonDraftEvaluationsAndGrades($gradeIds: [Int], $query: String, $orderBy: OrderBy) {
  client {
    evaluations (excludeStatuses: [DRAFT], query: $query, gradeIds: $gradeIds, orderBy: [$orderBy]) {
      hits {
        createdAt
        updatedAt
        id
        title
        status
        copyIds
        grade {
          name
        }
        tags {
          name
          grades {
            name
            id
          }
        }
      }
      total
    }
    grades {
      hits {
        id
        name
      }
    }
  }
}
`

const Title = () => {
  const { t } = useTranslation('evaluations-page')
  const navigate = useNavigate()
  const [cookies] = useCookies(['enableHidden'])

  return (
    <div
      className="flex mx-4 my-8 py-16 rounded-3xl flex-col justify-start items-center gap-2.5 overflow-hidden coloredBackground px-8">
      <div className="w-full max-w-6xl h-12 inline-flex justify-between items-center gap-4">
        <span className="text-[#0f172a] text-4xl font-semibold">Mes
          <span className="text-[#4f46e5] ml-2">évaluations</span>
        </span>
        <Button
          onClick={() => navigate(getPath(URLS.NEW_EVALUATION))}
          className="text-sm italic"
          disabled={!cookies.enableHidden} // @TEMP
          leftIcon={<Icon.Plus/>}>{t('create-evaluation')}</Button>
      </div>
    </div>
  )
}

const OrdersBy = [
  OrderByFieldEnum.CREATED_AT,
  OrderByFieldEnum.UPDATED_AT,
  OrderByFieldEnum.TITLE,
] as const

const Home = () => {
  const { t } = useTranslation('evaluations-page')
  const [gradeId, setGradeId] = useState<number>(0)
  const [orderBy, setOrderBy] = useState<OrderByFieldEnum>(OrderByFieldEnum.CREATED_AT)

  const { data } = useQuery(
    gqlQuery,
    { variables: {
      gradeIds: gradeId === 0 ? undefined : [gradeId],
      orderBy: orderBy === undefined ? undefined : { field: orderBy.toUpperCase(), direction: orderBy === OrderByFieldEnum.TITLE ? 'ASC' : 'DESC' } }
    })
  const evaluations = data?.client?.evaluations?.hits
  const grades = useMemo(() =>
    data?.client.grades.hits == null ? [] : sortBy(data?.client.grades.hits, prop('id')),
  [data])

  const [mode, setMode] = useState<'list' | 'grid'>('grid')

  const ItemComponent = useMemo(() => mode === 'list' ? EvaluationListItem : EvaluationCardItem, [mode])

  return (
    <div className="w-full overflow-x-clip">
      <Header><div className="mt-2 mr-2"><LogoutButton/></div></Header>
      <Title />
      <div className="flex mx-4 px-8 my-8 flex-col justify-start items-center gap-6">
        <div className="flex w-full flex-row max-w-6xl justify-between gap-4 items-center">
          <div className="flex flex-row gap-4 items-center">
            <Select value={gradeId} name="grade" onChange={e => setGradeId(parseInt(e.target.value, 10))}>
              <option value="0">{t('all-grades')}</option>
              {grades?.map(grade => <option key={grade.id} value={grade.id}>{grade.name}</option>)}
            </Select>
            {/*<div>Rechercher</div>*/}
          </div>

          <div className="flex flex-row gap-2 items-center">
            <Select value={orderBy} name="order" onChange={e => setOrderBy(e.target.value as OrderByFieldEnum)}>
              {OrdersBy?.map(order => <option key={order} value={order}>
                {t(`order-by.${order}`)}
              </option>)}
            </Select>
            <IconButton theme="secondary" onClick={() => setMode('list')} className={mode == 'list' ? '!bg-[#F5F3F7]' : 'bg-transparent'}><Icon.Column/></IconButton>
            <IconButton theme="secondary" onClick={() => setMode('grid')} className={mode == 'grid' ? '!bg-[#F5F3F7]' : 'bg-transparent'}><Icon.Grid/></IconButton>
          </div>
        </div>

        <div className="flex w-full flex-col max-w-screen-xl items-center">
          <div className={clsx('flex gap-4', mode == 'list' ? 'flex-col' : 'flex-wrap justify-center w-full ')}>
            {evaluations?.map(evaluation => <ItemComponent orderBy={orderBy} key={evaluation.id} evaluation={evaluation} />)}
          </div>
        </div>
      </div>
    </div>
  )
}

export default Home
