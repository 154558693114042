import { Button, Icon } from '@ed/ui'
import type { FC, ReactNode } from 'react'

type Props = {
  title: ReactNode
  onClick?: () => void
  buttonLabel?: ReactNode
  buttonIcon?: ReactNode
  buttonDisabled?: boolean
}

const TitleAndButton: FC<Props> = ({ onClick, title, buttonLabel, buttonIcon, buttonDisabled }) => (<>
  <div className="flex items-center justify-between mb-4">
    <h1 className="text-xl font-medium text-[#291846]">
      {title}
    </h1>
    {!!onClick && !!buttonLabel && <Button
      onClick={onClick}
      theme="primary-invert"
      disabled={!!buttonDisabled}
      className="flex items-center gap-2 text-s italic">
      {buttonIcon}
      {buttonLabel}
      {!buttonIcon && <Icon.ChevronRight className="size-5"/>}
    </Button>}
  </div>
</>)

export default TitleAndButton
