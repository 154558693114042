import fr from '@/../messages/fr.json'
import en from '@/../messages/en.json'
import type { InitOptions } from 'i18next'

const resources = {
  en,
  fr
} as const

export const DEFAULT_LANGUAGES = 'fr'
export const DEFAULT_NS = 'shared'
export const AVAILABLE_LANGUAGES = Object.keys(resources) as TransLanguages[]
export const TRANS_NS = Object.keys(fr) as TransNSNames[]
export type DefaultNs = typeof DEFAULT_NS
export type Trans = typeof fr
export type TransNSNames = keyof typeof fr
export type TransLanguages = keyof typeof resources

export const I18N_OPTIONS = {
  debug: false,
  resources,
  fallbackLng: DEFAULT_LANGUAGES,
  compatibilityJSON: 'v4',
  interpolation: {
    escapeValue: false,
  },
  react: {
    useSuspense: false,
  },
  cache: {
    enabled: true,
  },
  defaultNS: DEFAULT_NS,
  fallbackNS: DEFAULT_NS,
  ns: TRANS_NS,
  cleanCode: true,
  supportedLngs: AVAILABLE_LANGUAGES,
} as const satisfies InitOptions
