import { formatDate } from '@/utils/evaluation'
import { getStatusWordingKey } from '@/utils/status'
import { getPath, URLS } from '@/utils/url'
import { type EvaluationType, OrderByFieldEnum } from '@ed/types'
import { Icon, LinkBox, LinkOverlay } from '@ed/ui'
import type { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { NavLink } from 'react-router'

const EvaluationListItem: FC<{
  evaluation: EvaluationType & { copyIds: number[], grade: { name: string } }
  orderBy: string
}> = ({ evaluation, orderBy }) => {
  const { t } = useTranslation('utils')

  return (
    <LinkBox as="li" className="flex gap-4 py-3 border-[#E5E0EB] border-b last-of-type:border-none group">
      <div
        className="flex items-center justify-center w-11 h-11 bg-[#604AFF24] group-hover:bg-[#604aff4a] rounded-lg transition-colors">
        <Icon.Evaluation className="text-[#604AFF] group-hover:scale-110 transition-transform"/>
      </div>
      <div className="flex flex-col gap">
        <LinkOverlay
          as={NavLink}
          to={getPath(URLS.EVALUATION, { evaluationId: evaluation.id })}
          className="text-base font-medium group-hover:text-primary-darker-20 transition-colors"
        >
          {evaluation.title}
        </LinkOverlay>
        <div className="text-[13px] leading-[20px]">
          {[formatDate(orderBy === OrderByFieldEnum.UPDATED_AT ? evaluation.updatedAt : evaluation.createdAt),
            evaluation.grade.name,
            ...(evaluation.tags?.map(t => t.name) ?? [])
          ]
            .filter(text => !!text)
            .join(' • ')
          }
        </div>
        <div className="text-[13px] leading-[18px] text-medium-grey">
          {t(`status.${getStatusWordingKey(evaluation.status)}`)}
        </div>
      </div>
    </LinkBox>
  )
}

export default EvaluationListItem
