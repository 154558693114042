import clsx from 'clsx'

type ExerciseNumberProps = {
  number: string,
  size?: 'small' | 'medium',
  variant?: 'default' | 'outline'
}

/**
 * Display the number of an exercise on the copy image view.
 */
const ExerciseNumber = ({ number, size = 'medium', variant = 'default' }: ExerciseNumberProps) => {
  return <div
    className={clsx(
      'rounded flex items-center justify-center',
      'font-bold overflow-clip text-nowrap',  // We don't want any wrapping or overflow
      'border border-[#4f46e5]',            // Border is always visible to avoid blinking on transition
      'motion-safe:duration-500',             // Transition duration should match transitions in exerciseBoundary.tsx
      size === 'small' ? 'w-6 h-6 text-sm' : 'w-8 h-8 text-base',                              // Size impacts size and font size
      variant === 'outline' ? 'bg-white text-[#4f46e5]' : 'bg-[#4f46e5] text-white'  // Variant impacts background and text color
    )}>
    {number}
  </div>
}

export default ExerciseNumber
