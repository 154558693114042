import type { ModalContext } from '@/types/modal'
import { createContext, useContext } from 'react'

export const modalContext = createContext<ModalContext>(undefined as unknown as ModalContext)

export const useModal = () => {
  const context = useContext(modalContext)

  if (context === undefined) {
    throw new Error('Use in ModalProvider')
  }

  return context
}