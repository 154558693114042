import { useTabContext } from '@/components/tabs/TabContext'
import type { FC, ReactElement } from 'react'

export type Tab<Ids = string> = {
  id: Ids
  title: string
  icon: ReactElement
  count?: number
}

const TabBar: FC<{ tabs: Array<Tab> }> = ({ tabs }) => {
  const { activeTabId, setActiveTabId } = useTabContext()

  return (
    <div className="flex w-full bg-white rounded-full py-1.5 px-6 gap-4">
      {tabs.map((tab) => {
        const isActive = tab.id === activeTabId

        return (
          <div
            key={tab.id}
            onClick={() => setActiveTabId(tab.id)}
            className={`flex items-center gap-2 py-2 px-4 h-11 rounded-full ${
              isActive
                ? 'bg-[#4f46e5] text-white'
                : 'text-[#1e1b4b] hover:bg-gray-100'
            } cursor-pointer transition-colors duration-200 flex-1 justify-center`}
          >
            {tab.icon}
            <span className="overflow-ellipsis text-sm line-clamp-1">{tab.title}</span>
            {tab.count !== undefined && (
              <span className={`-ml-1 text-sm ${isActive ? 'text-white' : 'text-gray-500'}`}>
                ({tab.count})
              </span>
            )}
          </div>
        )
      })}
    </div>
  )
}

export default TabBar
